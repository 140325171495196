import Footer from "../../components/Footer/Footer";
import SEARCH from "../../assets/icons/search-pink.svg";
import "./style.css";
import Button from "../../components/Button/Button";
import React, {Fragment, useEffect, useState} from "react";
import SecondButton from "../../components/SecondButton/SecondButton";
import LOCATION from "../../assets/icons/location-white.svg";
import TIME from "../../assets/icons/time-white.svg";
import Img from "../../components/Img";
import ARROW from "../../assets/icons/arrow-down-white.svg";
import {useWindowSize} from "../../hooks/useWindowSize";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getCarers} from "../../redux/actions/newActions/newActions";
import Cookies from "js-cookie";
import Spinner from "../../components/Spinner/Spinner";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import {useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet";

const Career = () => {
    const theme = useSelector(state => state.newReducer.themeChange)
    const lang = Cookies.get("i18next") || "en";
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get('page')) || 1;
    useEffect(() => {
        dispatch(getCarers({page}))
    }, [page])
    const career_categories = useSelector(state => state.newReducer.career_categories)
    const career_mainData = useSelector(state => state.newReducer.career_mainData)
    const career_main = useSelector(state => state.newReducer.career_main)
    const status = useSelector(state => state.newReducer.status)
    const [showMenu, setShowMenu] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [selectedItem, setSelectedItem] = useState('')
    const [search, setSearch] = useState('')
    const [query, setQuery] = useState('')
    const handleDropdownClick = () => {
        setShowMenu(!showMenu);
    };
    const handleSetQuery = () => {
        setQuery(search)
    }
    const handleMenuItemClick = item => {
        setSelectedId(item.id)
        setShowMenu(false);
    };
    const handleIncrement = () => {
        const newCount = page + 1;
        setSearchParams((prevSearchParams) => {
            return new URLSearchParams({...Object.fromEntries(prevSearchParams), page: newCount});
        });
    };

    const {width} = useWindowSize();
    useEffect(() => {
        if (career_categories.length) {
            setSelectedId(career_categories[0].id)
            setSelectedItem(career_categories[0][`title_${lang}`])
        }
    }, [career_categories])
    if (status === 'pending') {
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        );
    }
    return (
        <div>
            <Helmet>
                <title>Career</title>
            </Helmet>
            <div className='careerContainer'>
                <div className='careerTitleContainer'>
                    <p style={{
                        color: theme === 'ligth' ? '#000' : ''
                    }} className='careerTitle'>{career_main[`heading_${lang}`]}</p>
                    <p style={{
                        color: theme === 'ligth' ? '#000' : ''
                    }} className='careerDescription'>
                        {removeHtmlTags(career_main[`description_${lang}`])}
                    </p>
                </div>
                <div className='positionsContainer'>
                    <div className='positionsFilteringContainer'>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} className='positionsFilteringTitle'>
                            {t('Open Positions')}
                            <span style={{
                                color: theme === 'ligth' ? '#000' : ''
                            }}>{career_mainData.filter((e) => e.category_id === selectedId && e[`title_${lang}`].includes(query)).length <= 1 ? career_mainData.filter((e) => e.category_id === selectedId && e[`title_${lang}`].includes(query)).length + ' ' + t('result') : career_mainData.filter((e) => e.category_id === selectedId && e[`title_${lang}`].includes(query)).length + ' ' + t('results')}</span>
                        </p>
                    </div>
                    <div className='positionsFilertingBtns'>
                        {width > 1100 ? (
                            <div className='positionsBtns'>
                                {career_categories.map((btn, i) => (
                                    <Fragment key={btn.id}>
                                        <Button
                                            onClick={() => handleMenuItemClick(btn)}
                                            // btnType='submit'
                                            link={false}
                                            text={btn[`title_${lang}`]}
                                            style={{
                                                backgroundColor:
                                                    selectedId !== btn.id
                                                        ? "rgba(255, 255, 255, 0.05)"
                                                        : "var(--main-color-pink)",
                                                border: "none",
                                                //   fontFamily: "Poppins-600",
                                                //   borderRadius: "var(--main-border-radius)",
                                                color: "var(--secondary-color-white)",
                                                width: "fit-content",
                                            }}
                                            className='filterBtn'
                                        />
                                    </Fragment>
                                ))}
                            </div>
                        ) : (
                            <div style={{
                                border:theme === 'ligth' ? '1px solid black' : ''
                            }} className='filterDropHolder' onClick={handleDropdownClick}>
                                <div className='filterDropdown'>
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.75 0.75L6 5.25L11.25 0.75" stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <ul style={{
                                    background:theme === 'ligth' ? 'rgb(248, 248, 248)' : '',
                                    border:theme === 'ligth' ? '1px solid black' : ''
                                }} className={`${showMenu && "showMenu"} filterMenu`}>
                                    {career_categories.map(st => (
                                        <li
                                            style={{
                                                color:theme === 'ligth' ? '#000' : ''
                                            }}
                                            key={st.id}
                                            onClick={() => handleMenuItemClick(st.id)}>
                                            {st[`title_${lang}`]}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className='search'>
                            <div style={{cursor: 'pointer'}} onClick={handleSetQuery}>
                                <Img src={SEARCH} alt='Search'/>
                            </div>
                            <input
                                style={{
                                    color: theme === 'ligth' ? '#000' : '',
                                    background: theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                }}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={t("placeholder.search")}
                            />
                        </div>
                    </div>
                    <div>
                        <div style={{
                            background: theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                        }} className='vacanciesContainer'>
                            {career_mainData.filter((e) => e.category_id === selectedId && e[`title_${lang}`].includes(query)).map((vac, i) => (
                                <div className='vacancy' key={vac.id}>
                                    <div className='vacancyDetails'>
                                        <div className='vacancyDetailsTitleContainer'>
                                            <p style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }} className='vacancyDetailsTitle'>{vac[`title_${lang}`]}</p>
                                            <button className='vacancyDetailsTitleBtn'>{selectedItem}</button>
                                        </div>
                                        <p style={{
                                            color: theme === 'ligth' ? '#000' : ''
                                        }} dangerouslySetInnerHTML={{__html: vac[`description_${lang}`]}}/>
                                        <div className='vacancyInfo'>
                                            <div style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }}>
                                                <svg width="18" height="22" viewBox="0 0 18 22" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1 9.14329C1 4.64588 4.58172 1 9 1C13.4183 1 17 4.64588 17 9.14329C17 13.6055 14.4467 18.8124 10.4629 20.6744C9.53426 21.1085 8.46574 21.1085 7.53707 20.6744C3.55332 18.8124 1 13.6055 1 9.14329Z"
                                                        stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5"/>
                                                    <circle cx="9" cy="9" r="3" stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5"/>
                                                </svg>

                                                {vac[`location_${lang}`]}
                                            </div>
                                            <div style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }}>
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="11" cy="11" r="10" stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5"/>
                                                    <path d="M11 7V11L13.5 13.5" stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>

                                                {vac.type}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='vacancyBtn'>
                                        <SecondButton
                                            className='moreBtnLarge vacancyBtnLink'
                                            to={`/career/${vac.id}`}
                                            // target='_blank'
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        {career_mainData.length > +page ? <Button
                            className='seeMoreBtn'
                            text={t('See More')}
                            link={false}
                            onClick={handleIncrement}
                            style={{
                                background: "rgba(255, 255, 255, 0.05)",
                                color: "rgba(255, 255, 255, 0.60)",
                                // marginTop: "40px",
                                width: "100%",
                            }}
                        /> : null}

                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Career;
