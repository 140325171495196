import AccountLayout from "../AccountLayout";
import PERSON from "../../../assets/person-images/1.svg";
import "./style.css";
import {ErrorMessage, Field, Form, Formik} from "formik";
import ValidationSchema from "../../../utils/ValidationSchema";
import Button from "../../../components/Button/Button";
import {useTranslation} from "react-i18next";
import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {postContactUs, postUserUpdate} from "../../../redux/actions/newActions/newActions";
import {STORAGE_URL} from "../../../services/apiService";
import {toast} from "react-toastify";
import Cookies from "js-cookie";

const Settings = () => {
    const trans = useSelector(state => state.newReducer.trans)
    const theme = useSelector(state => state.newReducer.themeChange)
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [status, setStatus] = useState('')
    const user = JSON.parse(localStorage.getItem('user'))
    const [showPassword, setShowPassword] = useState(false);
    const token = localStorage.getItem('token')
    const [error, setError] = useState('')
    const {accountManagementSchema} = ValidationSchema();
    const [fileSrc, setFileNameSrc] = useState('');
    const [file, setFile] = useState({});
    const [showPasswords, setShowPasswords] = useState({
        password1: false,
        password2: false,
        password3: false,
    });
    const toggleEye = fieldName => {
        setShowPasswords(prevState => ({
            ...prevState,
            [fieldName]: !prevState[fieldName],
        }));
    };
    const lang = Cookies.get("i18next") || "en";
    const handleFileSelect = useCallback((ev) => {
        if (ev.target.files[0]) {
            // if (ev.target.files[0].size > 2048) {
            //     setError('Image size max 2048')
            // }
            const newFile = URL.createObjectURL(ev.target.files[0]);
            setFileNameSrc(newFile)
            setFile(ev.target.files[0])
        }
    }, [file, fileSrc]);
    const handleSendForm = useCallback(async (form) => {
        const {payload} = await dispatch(postUserUpdate({...form}))
        if (payload?.status === 'success') {
            setStatus('ok')
            toast.success(t('You have changed your details'))
        }
    }, [])
    return (
        <AccountLayout>
            <div className='accountSettingsContainer'>
                <div className='accountPageTitleContainer'>
                    <p style={{
                        color: theme === 'ligth' ? '#000' : ''
                    }} className='accountPageTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Personal_info')[0][`value_${lang}`]: t("personal_info")}</p>
                </div>
                <div className='accountManagement'>
                    <div className='accountManagementAvatar'>
                        <div className='accountManagementAvatarImg'>
                            {user?.image ? <img
                                    src={user?.image?.startsWith('https:') ? user.image : `${STORAGE_URL}${user?.image}`}
                                    alt='Avatar'/> :
                                <img src={fileSrc || PERSON} alt='Avatar'/>}
                        </div>
                        {error && <p style={{color: 'red'}}>{error}</p>}
                        <div className='accountAvatarUploadBtn'>
                            <input onChange={handleFileSelect} accept=".jpeg, .jpg, .svg, .gif, .png" type='file'
                                   id='upload' hidden/>
                            <label style={{
                                background: theme === 'ligth' ? 'rgb(239, 239, 239)' : ''
                            }} htmlFor='upload'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Upload_image')[0][`value_${lang}`]: t("upload_image")}</label>
                        </div>
                    </div>
                    <div className='accountManagementForm'>
                        <Formik
                            initialValues={{
                                name: user.name || '',
                                surname: user.surname || "",
                                email: user.email || "",
                                phone: user.phone || "",
                                current_password: "",
                                new_password: "",
                                password_confirmation: "",
                            }}
                            onSubmit={async (values, actions) => {
                                actions.setSubmitting(false);
                                handleSendForm({...values, file, id: user.id, token})
                                if (status === 'ok') {
                                    actions.resetForm()
                                }
                                setStatus('')
                            }}
                            validationSchema={accountManagementSchema}>
                            {() => (
                                <Form id='accountManagementForm'>
                                    <div className='accountManagementPersonalData'>
                                        <div className='accountMangementFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }}
                                                htmlFor='name'>
                                                {trans.length > 0 ? trans.filter((e)=>e.key === 'Name')[0][`value_${lang}`]:t("name")} <span>*</span>
                                            </label>
                                            <div className='accountMangementFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        background: theme === 'ligth' ? '#F8F8F8' : ''
                                                    }}
                                                    type='text'
                                                    name='name'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_name')[0][`value_${lang}`]:t("placeholder.enter_your_name")}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name='name'
                                                component='p'
                                                className='accountMangementError'
                                            />
                                        </div>
                                        <div className='accountMangementFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }} htmlFor='surname'>
                                                {trans.length > 0 ? trans.filter((e)=>e.key === 'Surname')[0][`value_${lang}`]:t("surname")} <span>*</span>
                                            </label>
                                            <div className='accountMangementFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        background: theme === 'ligth' ? '#F8F8F8' : ''
                                                    }}
                                                    type='text'
                                                    name='surname'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_surname')[0][`value_${lang}`]:t("placeholder.enter_your_surname")}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name='surname'
                                                component='p'
                                                className='accountMangementError'
                                            />
                                        </div>
                                        <div className='accountMangementFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : '',
                                                }} htmlFor='email'>
                                                {trans.length > 0 ? trans.filter((e)=>e.key === 'Email')[0][`value_${lang}`]:t("email")} <span>*</span>
                                            </label>
                                            <div className='accountMangementFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        background:theme==='ligth' ? '#F8F8F8' : ''
                                                    }}
                                                    type='text'
                                                    name='email'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_email_address')[0][`value_${lang}`]:t("placeholder.enter_your_email")}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name='email'
                                                component='p'
                                                className='accountMangementError'
                                            />
                                        </div>
                                        <div className='accountMangementFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }} htmlFor='phone'>
                                                {trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_Number')[0][`value_${lang}`]:t("phone_number")} <span>*</span>
                                            </label>
                                            <div className='accountMangementFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        background:theme==='ligth' ? '#F8F8F8' : ''
                                                    }}
                                                    type='text'
                                                    name='phone'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_phone_number')[0][`value_${lang}`]:t("placeholder.enter_your_phone_number")}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name='phone'
                                                component='p'
                                                className='accountMangementError'
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        background: theme === 'ligth' ? '#000' : ''
                                    }} className='accountManagementDivider'></div>
                                    <div className='passwordModification'>
                                        <p
                                            style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }} className='passwordModificationTitle'>
                                            {trans.length > 0 ? trans.filter((e)=>e.key === 'Password_Information')[0][`value_${lang}`]:t("password_information")}
                                        </p>
                                        <div className='accountMangementFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }} htmlFor='current_password'>
                                                {trans.length > 0 ? trans.filter((e)=>e.key === 'Current_password')[0][`value_${lang}`]:t("current_password")} <span>*</span>
                                            </label>
                                            <div style={{
                                                position: "relative"
                                            }} className='accountMangementFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        background:theme==='ligth' ? '#F8F8F8' : ''
                                                    }}
                                                    type={showPasswords.password1 ? 'text' : "password"}
                                                    name='current_password'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_current_password')[0][`value_${lang}`]:t(
                                                        "placeholder.enter_your_current_password"
                                                    )}
                                                />
                                                <div
                                                    onClick={() => toggleEye('password1')}
                                                    className={`eyeContainer ${showPasswords.password1 ? 'eyeOpen' : "eyeClose"} `}
                                                >
                            <span className='icon-eye middle-right'>
                        <svg
                            viewBox='-20 -200 320 400'
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'>
                          <g id='eye' strokeWidth='30' fill='none'>
                            <g id='eye-lashes' stroke='currentColor'>
                              <line x1='140' x2='140' y1='90' y2='180'/>
                              <line x1='70' x2='10' y1='60' y2='140'/>
                              <line x1='210' x2='270' y1='60' y2='140'/>
                            </g>
                            <path
                                id='eye-bottom'
                                d='m0,0q140,190 280,0'
                                stroke='currentColor'
                            />
                            <path
                                id='eye-top'
                                d='m0,0q140,190 280,0'
                                stroke='currentColor'
                            />
                            <circle
                                id='eye-pupil'
                                cx='140'
                                cy='0'
                                r='50'
                                fill='currentColor'
                                stroke='none'
                            />
                          </g>
                        </svg>
                      </span>
                                                </div>
                                            </div>
                                            <ErrorMessage
                                                name='current_password'
                                                component='p'
                                                className='accountMangementError'
                                            />
                                        </div>
                                        <div className='accountMangementFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : '',
                                                }} htmlFor='new_password'>
                                                {trans.length > 0 ? trans.filter((e)=>e.key === 'New_password')[0][`value_${lang}`]:t("new_password")} <span>*</span>
                                            </label>
                                            <div style={{
                                                position: "relative"
                                            }} className='accountMangementFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        background:theme==='ligth' ? '#F8F8F8' : ''
                                                    }}
                                                    type={showPasswords.password2 ? 'text' : "password"}
                                                    name='new_password'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_new_password')[0][`value_${lang}`]:t("placeholder.enter_your_new_password")}
                                                />
                                                <div
                                                    onClick={() => toggleEye('password2')}
                                                    className={`eyeContainer ${showPasswords.password2 ? 'eyeOpen' : "eyeClose"} `}
                                                >
                            <span className='icon-eye middle-right'>
                        <svg
                            viewBox='-20 -200 320 400'
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'>
                          <g id='eye' strokeWidth='30' fill='none'>
                            <g id='eye-lashes' stroke='currentColor'>
                              <line x1='140' x2='140' y1='90' y2='180'/>
                              <line x1='70' x2='10' y1='60' y2='140'/>
                              <line x1='210' x2='270' y1='60' y2='140'/>
                            </g>
                            <path
                                id='eye-bottom'
                                d='m0,0q140,190 280,0'
                                stroke='currentColor'
                            />
                            <path
                                id='eye-top'
                                d='m0,0q140,190 280,0'
                                stroke='currentColor'
                            />
                            <circle
                                id='eye-pupil'
                                cx='140'
                                cy='0'
                                r='50'
                                fill='currentColor'
                                stroke='none'
                            />
                          </g>
                        </svg>
                      </span>
                                                </div>
                                            </div>
                                            <ErrorMessage
                                                name='new_password'
                                                component='p'
                                                className='accountMangementError'
                                            />
                                        </div>
                                        <div className='accountMangementFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }} htmlFor='password_confirmation'>
                                                {trans.length > 0 ? trans.filter((e)=>e.key === 'Password_Confirmation')[0][`value_${lang}`]:t("password_confirmation")} <span>*</span>
                                            </label>
                                            <div style={{
                                                position: 'relative'
                                            }} className='accountMangementFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        background:theme==='ligth' ? '#F8F8F8' : ''
                                                    }}
                                                    type={showPasswords.password3 ? 'text' : "password"}
                                                    name='password_confirmation'
                                                    placeholder={t("placeholder.enter_your_new_password")}
                                                />
                                                <div
                                                    onClick={() => toggleEye('password3')}
                                                    className={`eyeContainer ${showPasswords.password3 ? 'eyeOpen' : "eyeClose"} `}
                                                >
                            <span className='icon-eye middle-right'>
                        <svg
                            viewBox='-20 -200 320 400'
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'>
                          <g id='eye' strokeWidth='30' fill='none'>
                            <g id='eye-lashes' stroke='currentColor'>
                              <line x1='140' x2='140' y1='90' y2='180'/>
                              <line x1='70' x2='10' y1='60' y2='140'/>
                              <line x1='210' x2='270' y1='60' y2='140'/>
                            </g>
                            <path
                                id='eye-bottom'
                                d='m0,0q140,190 280,0'
                                stroke='currentColor'
                            />
                            <path
                                id='eye-top'
                                d='m0,0q140,190 280,0'
                                stroke='currentColor'
                            />
                            <circle
                                id='eye-pupil'
                                cx='140'
                                cy='0'
                                r='50'
                                fill='currentColor'
                                stroke='none'
                            />
                          </g>
                        </svg>
                      </span>
                                                </div>
                                            </div>
                                            <ErrorMessage
                                                name='password_confirmation'
                                                component='p'
                                                className='accountMangementError'
                                            />
                                        </div>
                                    </div>
                                    <Button
                                        text={trans.length > 0 ? trans.filter((e)=>e.key === 'Save_Changes')[0][`value_${lang}`]:t("save_changes")}
                                        className='accountMangementSaveBtn'
                                        style={{
                                            background: "var(--main-color-pink)",
                                            color: "var(--secondary-color-white)",
                                            border: "none",
                                            fontFamily: "Poppins-600, sans-serif",
                                            fontWeight: "600",
                                        }}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </AccountLayout>
    );
};

export default Settings;
