import {useEffect, useRef, useState} from "react";
import {GoogleMap, useJsApiLoader, Marker} from "@react-google-maps/api";
import markerIcon from '../assets/icons/map-marker.svg'
import Img from "./Img";
import ARROW from "../assets/icons/arrow-left-pink.svg";
import {useTranslation} from "react-i18next";

const Map = ({coords, addressTitle,isFullScreen,setIsFullScreen}) => {
    const {t} = useTranslation();
    const [showTitle,setShowTitle] = useState(true)
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBDCHA5_Tfs71oSV6IJAQDkKZIMZpfHnuc"
    })
    const ref = useRef(null)
    useEffect(()=>{
        try {
            if (ref.current && isFullScreen){
                ref.current.requestFullscreen()
            }
        }catch (e) {
            console.error(e)
        }
    },[isFullScreen])

    const mapOptions = {
        fullscreenControl: false,
        mapTypeControl: false,
        // streetViewControl: false,
    }
    useEffect(() => {
        const handleExitFullscreen = () => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false)
            }
        };
        document.addEventListener('fullscreenchange', handleExitFullscreen);
        return () => {
            document.removeEventListener('fullscreenchange', handleExitFullscreen);
        };
    }, []);
    const mapRef = useRef(null)
    const handleChangeZoom = ()=>{
        if (mapRef.current){
            const newZoom = mapRef.current.getZoom()
            if (newZoom >= 19){
                setShowTitle(false)
            }else {
                setShowTitle(true)
            }
        }
    }
    return (isLoaded &&
        (
            <>
                <div ref={ref} className='contactMap'>
                    {isFullScreen && <div style={{
                        position: 'absolute',
                        zIndex: 99999999999,
                        top: 30,
                        left: 30
                    }} className='articleBackBtnContainer'>
                        <div onClick={() => {
                            document.exitFullscreen()
                            setIsFullScreen(false)
                        }} style={{cursor: "pointer", color: "black"}} className='articleBackBtn'>
                            <div>
                                <Img src={ARROW} alt='Arrow'/>
                            </div>
                            {t("back")}
                        </div>
                    </div>}
                    <GoogleMap
                        onZoomChanged={handleChangeZoom}
                        onLoad={(map)=> {
                            mapRef.current = map
                        }}
                        options={mapOptions}
                        mapContainerStyle={{
                            width: '100%',
                            height: '100%',
                            borderRadius: 'inherit',
                        }}
                        center={{
                            lat: coords[0],
                            lng: coords[1]
                        }}
                        zoom={17}>
                        {showTitle && <Marker
                            label={{
                                text: "WoW Effect",
                                className: `google-map-marker-label ${!showTitle ? 'close' : ''}`,
                            }}
                            title={addressTitle}
                            icon={markerIcon}
                            position={{
                                lat: coords[0],
                                lng: coords[1]
                            }}
                        />}
                    </GoogleMap>
                </div>
            </>
        )
    );
};

export default Map;
