import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useSelector} from "react-redux";
import Cookies from "js-cookie";

const ValidationSchema = () => {
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const trans = useSelector(state => state.newReducer.trans)
    const orderSchema = yup.object().shape({
        first_name: yup
            .string()
            .matches(/^[A-Za-z\s]+$/, t("validations.valid_name"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Name_is_a_required_field')[0][`value_${lang}`] : t("validations.name_required")),
        email: yup
            .string()
            .email()
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Email_is_required_field')[0][`value_${lang}`] : t("validations.email_required")),
        phone: yup
            .string()
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_is_a_required_field')[0][`value_${lang}`] :t("validations.invalid_phone_r"))
            .matches(/^[0-9]+$/,trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_number_should_contain_only_numbers')[0][`value_${lang}`] :  t("validations.invalid_phone")),
        surname: yup
            .string()
            .matches(/^[A-Za-z]+$/, t("validations.valid_surname"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Name_is_a_required_field')[0][`value_${lang}`] : t("validations.name_required")),
        message: yup.string()
    })
    const signUpSchema = yup.object().shape({
        email: yup
            .string()
            .email(trans.length > 0 ? trans.filter((e)=>e.key === 'Invalid_email')[0][`value_${lang}`] :t("validations.invalid_email"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Email_is_required_field')[0][`value_${lang}`] : t("validations.email_required")),
        password: yup
            .string()
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Password_is_a_required_field')[0][`value_${lang}`] : t("validations.password_required"))
            .min(8,trans.length > 0 ? trans.filter((e)=>e.key === 'Password_must_be_at_least_8_characters')[0][`value_${lang}`] : t("validations.invalid_pass")),
        // Password_must_be_at_least_8_characters
        password_confirmation: yup
            // Password_confirmation_is_a_required_field
            .string()
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Password_confirmation_is_a_required_field')[0][`value_${lang}`] : t("validations.password_confirmation_required"))
            .oneOf([yup.ref("password")], t("validations.passwords_must_match")),
    });
    const signInSchema = yup.object().shape({
        email: yup
            .string()
            .email(trans.length > 0 ? trans.filter((e)=>e.key === 'Invalid_email')[0][`value_${lang}`] :t("validations.invalid_email"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Email_is_required_field')[0][`value_${lang}`] : t("validations.email_required")),
        password: yup
            .string()
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Password_is_a_required_field')[0][`value_${lang}`] :t("validations.password_required"))
            .min(8,trans.length > 0 ? trans.filter((e)=>e.key === 'Password_must_be_at_least_8_characters')[0][`value_${lang}`] : t("validations.invalid_pass")),
    });

    const passwordRecoverySchema = yup.object().shape({
        email: yup
            .string()
            .email(trans.length > 0 ? trans.filter((e)=>e.key === 'Invalid_email')[0][`value_${lang}`] :t("validations.invalid_email"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Email_is_required_field')[0][`value_${lang}`] : t("validations.email_required")),
    });
    const phoneNumberSchema = yup.object().shape({
        phone: yup
            .string()
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_is_a_required_field')[0][`value_${lang}`] :t("validations.invalid_phone_r"))
            .matches(/^[0-9]+$/,trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_number_should_contain_only_numbers')[0][`value_${lang}`] :  t("validations.invalid_phone")),
    });

    const resetPassSchema = yup.object().shape({
        password: yup
            .string()
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Password_is_a_required_field')[0][`value_${lang}`] :t("validations.password_required"))
            .min(8,trans.length > 0 ? trans.filter((e)=>e.key === 'Password_must_be_at_least_8_characters')[0][`value_${lang}`] : t("validations.invalid_pass")),
        password_confirmation: yup
            .string()
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Password_confirmation_is_a_required_field')[0][`value_${lang}`] :t("validations.password_confirmation_required"))
            .oneOf([yup.ref("password")], t("validations.passwords_must_match")),
    });

    const contactSchema = yup.object().shape({
        full_name: yup
            .string()
            .matches(/^[\p{L}\s]+$/u, t("validations.valid_name"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Name_is_a_required_field')[0][`value_${lang}`] : t("validations.name_required")),
        email: yup
            .string()
            .email(trans.length > 0 ? trans.filter((e)=>e.key === 'Invalid_email')[0][`value_${lang}`] :t("validations.invalid_email"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Email_is_required_field')[0][`value_${lang}`] : t("validations.email_required")),
        phone: yup
            .string()
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_is_a_required_field')[0][`value_${lang}`] :t("validations.this_is_required"))
            .matches(/^[0-9]+$/,trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_number_should_contain_only_numbers')[0][`value_${lang}`] : t("validations.invalid_phone")),
        message: yup.string().required(trans.length > 0 ? trans.filter((e)=>e.key === 'Message_is_required')[0][`value_${lang}`] :t("validations.this_is_required")),
    });
    const accountManagementSchema = yup.object().shape({
        name: yup
            .string()
            .matches(/^[A-Za-z ]+$/, t("validations.valid_name"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Name_is_a_required_field')[0][`value_${lang}`] : t("validations.name_required")),
        surname: yup
            .string()
            .matches(/^[A-Za-z ]+$/, t("validations.valid_surname"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Surname_is_a_required_field')[0][`value_${lang}`] :t("validations.surname_required")),
        email: yup
            .string()
            .email(trans.length > 0 ? trans.filter((e)=>e.key === 'Invalid_email')[0][`value_${lang}`] :t("validations.invalid_email"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Email_is_required_field')[0][`value_${lang}`] : t("validations.email_required")),
        phone: yup
            .string()
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_is_a_required_field')[0][`value_${lang}`] : t("validations.this_is_required"))
            .matches(/^[0-9]+$/,trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_number_should_contain_only_numbers')[0][`value_${lang}`] : t("validations.invalid_phone")),
        current_password: yup
            .string()
            // .required(t("validations.password_required"))
            .min(8,trans.length > 0 ? trans.filter((e)=>e.key === 'Password_must_be_at_least_8_characters')[0][`value_${lang}`] : t("validations.invalid_pass")),
        new_password: yup
            .string()
            // .required(t("validations.password_required"))
            .min(8,trans.length > 0 ? trans.filter((e)=>e.key === 'Password_must_be_at_least_8_characters')[0][`value_${lang}`] : t("validations.invalid_pass")),
        password_confirmation: yup
            .string()
            // .required(t("validations.password_confirmation_required"))
            .oneOf([yup.ref("new_password")], t("validations.passwords_must_match")),
    });

    const newAddressSchema = yup.object().shape({
        country: yup
            .string()
            .matches(/^[A-Za-z]+$/, t("validations.valid_name"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Country_is_required')[0][`value_${lang}`] :t("validations.country_required")),
        city: yup
            .string()
            // .matches(/^[A-Za-z]+$/, t("validations.valid_name"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'City_is_required')[0][`value_${lang}`] :t("validations.city_required")),
        address: yup
            .string()
            // .matches(/^[A-Za-z]+$/, t("validations.valid_name"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Address is required')[0][`value_${lang}`] : t("validations.address_required")),
        zip_code: yup
            .string()
            .matches(/^[0-9]+$/,trans.length > 0 ? trans.filter((e)=>e.key === 'Zip code should only contain numbers')[0][`value_${lang}`] : t("validations.invalid_zip_code"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Zip code is required')[0][`value_${lang}`] :t("validations.zip_code_required")),
        title: yup
            .string()
            // .matches()
            .required(t("validations.this_is_required"))
    });
    const storeRequirements = yup.object().shape({
        email: yup
            .string()
            .email(trans.length > 0 ? trans.filter((e)=>e.key === 'Invalid_email')[0][`value_${lang}`] :t("validations.invalid_email")),
        name: yup
            .string()
            .matches(/^[A-Za-z\s]+$/, t("validations.valid_name")),
        surname: yup
            .string()
            .matches(/^[A-Za-z]+$/, t("validations.valid_surname")),
        phone: yup
            .string()
            .matches(/^[0-9]+$/,trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_number_should_contain_only_numbers')[0][`value_${lang}`] : t("validations.invalid_phone"))
    })
    const emailSchema = yup.object().shape({
        email: yup
            // Invalid_email
            .string()
            .email(trans.length > 0 ? trans.filter((e)=>e.key === 'Invalid_email')[0][`value_${lang}`] : t("validations.invalid_email"))
            .required(trans.length > 0 ? trans.filter((e)=>e.key === 'Email_is_required_field')[0][`value_${lang}`] : t("validations.email_required")),

    })
    const orderAddresSchema = yup.object().shape({
        country: yup
            .string()
            .matches(/^[A-Za-z]+$/, t("validations.valid_name"))
            .required( trans.length > 0 ? trans.filter((e)=>e.key === 'Country_is_required')[0][`value_${lang}`] :t("validations.country_required")),
        city: yup
            .string()
            // .matches(/^[A-Za-z]+$/, t("validations.valid_name"))
            .required( trans.length > 0 ? trans.filter((e)=>e.key === 'City_is_required')[0][`value_${lang}`] :t("validations.city_required")),
        address: yup
            .string()
            .matches(/^[A-Za-z]+$/, t("validations.valid_name"))
            .required( trans.length > 0 ? trans.filter((e)=>e.key === 'Address is required')[0][`value_${lang}`] :t("validations.address_required")),
        region: yup
            .string()
            // .matches(/^[A-Za-z]+$/, t("validations.valid_name"))
            .required( trans.length > 0 ? trans.filter((e)=>e.key === 'Region is required')[0][`value_${lang}`] :t("validations.region_required")),
        locality: yup
            .string()
            // .matches(/^[A-Za-z]+$/, t("validations.valid_name"))
            // .required(t("validations.locality_required")),
    });

    return {
        signUpSchema,
        signInSchema,
        passwordRecoverySchema,
        resetPassSchema,
        contactSchema,
        accountManagementSchema,
        newAddressSchema,
        storeRequirements,
        emailSchema,
        orderAddresSchema,
        orderSchema,
        phoneNumberSchema
    };
};

export default ValidationSchema;
