import {NavLink, useNavigate, useParams} from "react-router-dom";
import ARROW from "../../../assets/icons/arrow-right-white.svg";
import Img from "../../../components/Img";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {cheCkError} from "../../../redux/actions/newActions/newActions";

const CheckoutStage = () => {
  const theme = useSelector(state => state.newReducer.themeChange)
  const {t} = useTranslation()
  const lang = Cookies.get("i18next") || "en";
  const stages = [
    {
      id: 1,
      title: t('Cart'),
      to: `/checkout/order`,
    },
    {
      id: 2,
      title: t('Place Order'),
      to: `/checkout/place-order`,
    },
    {
      id: 3,
      title: t('Order Complete'),
      to: "/",
    },
  ];
  const basketSelected = useSelector(state => state.newReducer.basketSelected)
  const [selectError ,setSelectError] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleGoNext = (link)=>{
    if (link !== '/'){
      if (basketSelected && basketSelected.length > 0){
        navigate(`/${lang}${link}`)
      }else {
        dispatch(cheCkError(t('Select product')))
      }
    }
  }
  const remainingPath = '/' + window.location.pathname.split('/').slice(2).join('/');
  return (
    <div className='checkoutStageContainer'>
      {stages.map((stage, i) => (
        <div className='checkoutStageContainer' key={i + stage.title}>
          <button
              style={{background:'transparent' , border:'none' , color:theme === 'ligth' ? '#000' : ''}}
            className={`checkoutStageLink ${remainingPath === stage.to ? 'active' : '' }`}
            onClick={()=>handleGoNext(stage.to)}
          >
            {stage.title}
          </button>
          {
            i < 2 ? (
                <div className='checkoutStageArrow'>
                  <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.0013 1L7.66797 8.77778L1.0013 16.5556" stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
            ) : null
          }
        </div>
      ))}
    </div>
  );
};

export default CheckoutStage;
