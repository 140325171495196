import FullTitle from "../FullTitle/FullTitle";
import ARROW from "../../assets/icons/read-more-right.svg";
import "./style.css";
import {NavLink} from "react-router-dom";
import {STORAGE_URL} from "../../services/apiService";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchBlogMain} from "../../redux/actions/blogMainActions";
import Cookies from "js-cookie";
import Slider from "../Slider/Slider";
import {useWindowSize} from "../../hooks/useWindowSize";
import Carousel from "nuka-carousel";
import {useTranslation} from "react-i18next";

const Blog = () => {
    const lang = Cookies.get("i18next") || "en";
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(fetchBlogMain());
    }, [dispatch]);
    const {width} = useWindowSize()
    const blogs = useSelector(state => state.blogMain);
    const theme = useSelector(state => state.newReducer.themeChange)
    const trans = useSelector(state => state.newReducer.trans)
    return (
        <div style={{
            background: theme === 'ligth' ? '#F8F8F8' : ''
        }} className='blogContainer container'>
            {blogs.blogMain && (
                <>
                    <FullTitle
                        title={blogs.blogMain.blog_main[`heading_${lang}`]}
                        desc={blogs.blogMain.blog_main[`description_${lang}`]}
                        withoutBtn
                    />
                    {width < 800 ? <Carousel
                        renderBottomCenterControls={() => null}
                        renderCenterLeftControls={() => null}
                        renderCenterRightControls={() => null}
                        slidesToShow={2}
                        // className='blogsWrapper'
                    >
                        {blogs.blogMain.blog_main.posts.slice(0, 4).map(blog => (
                            <div className='blog' key={blog.id}>
                                <div
                                    className='blogImg'
                                    style={{
                                        backgroundImage: `url(${STORAGE_URL}/${blog.image})`,
                                    }}></div>
                                <div className='blogInfo'>
                                    <p style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} className='blogTitle'>{blog[`title_${lang}`]}</p>
                                    {width > 800 ? < p style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} className='blogDesc'>
                                        {removeHtmlTags(blog[`description_${lang}`])}
                                    </p> : null}
                                    <NavLink
                                        className='blogReadMore'
                                        to={`/${lang}/blog/${blog.id}`}>
                                        {trans.filter((e)=>e.key === 'Read_More').map((e)=> (
                                            <p style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }}>{e[`value_${lang}`]}</p>
                                        ))}
                                        <svg width="36" height="11" viewBox="0 0 36 11" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g id="SVG">
                                                <g id="Group">
                                                    <path id="Vector" d="M30 5H0"
                                                          stroke={theme === 'ligth' ? 'black' : 'white'}
                                                          stroke-width="1.3"/>
                                                    <path id="Vector_2"
                                                          d="M35.0703 5.0002L26.3203 10.0002V0.200195L35.0703 5.0002Z"
                                                          fill={theme === 'ligth' ? 'black' : 'white'}/>
                                                </g>
                                            </g>
                                        </svg>
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </Carousel> : (<Slider className='blogsWrapper'>
                        {blogs.blogMain.blog_main.posts.slice(0, 4).map(blog => (
                            <div className='blog' key={blog.id}>
                                <div
                                    className='blogImg'
                                    style={{
                                        backgroundImage: `url(${STORAGE_URL}/${blog.image})`,
                                    }}></div>
                                <div className='blogInfo'>
                                    <p style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} className='blogTitle'>{blog[`title_${lang}`]}</p>
                                    <p style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} className='blogDesc'>
                                        {removeHtmlTags(blog[`description_${lang}`])}
                                    </p>
                                    <NavLink

                                        className='blogReadMore'
                                        to={`/${lang}/blog/${blog.id}`}>
                                        <p style={{
                                            color: theme === 'ligth' ? '#000' : ''
                                        }}>{t('read-more')}</p>
                                        <svg width="36" height="11" viewBox="0 0 36 11" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g id="SVG">
                                                <g id="Group">
                                                    <path id="Vector" d="M30 5H0"
                                                          stroke={theme === 'ligth' ? 'black' : 'white'}
                                                          stroke-width="1.3"/>
                                                    <path id="Vector_2"
                                                          d="M35.0703 5.0002L26.3203 10.0002V0.200195L35.0703 5.0002Z"
                                                          fill={theme === 'ligth' ? 'black' : 'white'}/>
                                                </g>
                                            </g>
                                        </svg>
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </Slider>)}
                </>
            )}
        </div>
    );
};

export default Blog;
