import { Link, useParams } from "react-router-dom";
import "./singlePortfolio.css";
import { motion } from "framer-motion";
import Footer from "../../components/Footer/Footer";
import { animate, initial } from "../../utils/transition";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWork } from "../../redux/actions/workActions";
import Spinner from "../../components/Spinner/Spinner";
import { STORAGE_URL } from "../../services/apiService";
import { removeHtmlTags } from "../../Helpers/removeHtmlTags";
import Img from "../../components/Img";
import Cookies from "js-cookie";
import {getTranslations} from "../../redux/actions/newActions/newActions";

const SinglePortfolioPage = () => {
  const lang = Cookies.get("i18next") || "en";
  const { portfolioName } = useParams();
  const { width } = useWindowSize();
  const [showArrowBtns, setShowArrowBtns] = useState(false);
  const trans = useSelector(state => state.newReducer.trans)
  useEffect(() => {
    const sliderContainer = document.querySelector(".slider ");
    const items = document.querySelectorAll(".portfolioCard");
    const gap = 32;
    let totalWidth = 0;
    if (sliderContainer && items) {
      items.forEach(img => {
        totalWidth += img.offsetWidth + gap;
      });
      setShowArrowBtns(totalWidth > sliderContainer.offsetWidth);
    }
  }, [width]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTranslations())
    dispatch(fetchWork(portfolioName));
  }, [dispatch]);
  const work = useSelector(state => state.work);
  if ((work.work === null && work.loading) || trans.length < 1 )
    return (
      <div className='spinnerContainer'>
        <Spinner />
      </div>
    );

  return (
    <motion.div initial={initial} animate={animate}>
      {work.work && (
        <>
          <div
            className='portfolioOverviewContainer'
            style={{
              backgroundImage: `url(${STORAGE_URL}/${work.work.work.image})`,
            }}>
            <div className='portfolioOverviewBackdrop'></div>
            <div className='portfolioOverview container'>
              <div className='portfolioSummary'>
                <p className='portfolioSummaryTitle'>
                  {work.work.work[`title_${lang}`]}
                </p>
                <p className='portfolioSummaryDescription'>
                  {removeHtmlTags(work.work.work[`description_${lang}`])}
                </p>
              </div>
              <div className='portfolioSpecs'>
                {/*<div className='portolioSpecsClient portfolioSpecsItem'>*/}
                {/*  <p className='portfolioSpecsTitle'>Client</p>*/}
                {/*  <p className='portfolioSpecsDescription'>*/}
                {/*    {work.work.work[`client_${lang}`]}*/}
                {/*  </p>*/}
                {/*</div>*/}
                <div className='portolioSpecsDate portfolioSpecsItem'>
                  <p className='portfolioSpecsTitle'>{trans[7] ?  trans[7][`value_${lang}`] : null}</p>
                  <p className='portfolioSpecsDescription'>
                    {work.work.work.date}
                  </p>
                </div>
                {/*<div className='portolioSpecsRole portfolioSpecsItem'>*/}
                {/*  <p className='portfolioSpecsTitle'>Role</p>*/}
                {/*  <p className='portfolioSpecsDescription'>*/}
                {/*    {work.work.work[`role_${lang}`]}*/}
                {/*  </p>*/}
                {/*</div>*/}
                {/*<div className='portolioSpecsWebsite portfolioSpecsItem'>*/}
                {/*  <p className='portfolioSpecsTitle'>Website</p>*/}
                {/*  <p className='portfolioSpecsDescription'>*/}
                {/*    <Link to='/websiteLink'>{work.work.work.website}</Link>*/}
                {/*  </p>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className='portfolioAchievements'>
            {work.work.work.work_parts.map((achievement, i) => (
              <div
                className='portfolioAchievementItem'
                key={i}
                style={{
                  flexDirection: i % 2 === 0 ? "row" : "row-reverse",
                }}>
                <div className='portfolioAchievementItemInfo'>
                  <p className='portfolioAchievementItemTitle'>
                    {achievement[`title_${lang}`]}
                  </p>
                  <div
                    className='portfolioAchievementItemDescription'
                    dangerouslySetInnerHTML={{
                      __html: achievement[`description_${lang}`],
                    }}
                  />
                </div>
                <div className='portfolioAchievementImages'>
                  <Img
                    src={`${STORAGE_URL}/${achievement.image_1}`}
                    alt='Portfolio'
                  />
                  <Img
                    src={`${STORAGE_URL}/${achievement.image_2}`}
                    alt='Portfolio'
                  />
                  <Img
                    src={`${STORAGE_URL}/${achievement.image_3}`}
                    alt='Portfolio'
                  />
                </div>
              </div>
            ))}
          </div>
          {/*<div className='portfolioSliderContainer container'>*/}
          {/*  <Title title='Related Projects' />*/}
          {/*  <Slider className='portfolioSlider' showArrowBtns={showArrowBtns}>*/}
          {/*    {work.work.related_projects.map(prt => (*/}
          {/*      <div*/}
          {/*        className='portfolioCard singlePortfolioCard'*/}
          {/*        style={{*/}
          {/*          backgroundImage: `url(${STORAGE_URL}/${prt.image})`,*/}
          {/*        }}*/}
          {/*        key={prt.id}>*/}
          {/*        <div className='portfolioCardInfo'>*/}
          {/*          <div className='portfolioDetails'>*/}
          {/*            <p className='portfolioDetailsTitle'>*/}
          {/*              {prt[`title_${lang}`]}*/}
          {/*            </p>*/}
          {/*            <p className='portfolioDetailsDescription'>*/}
          {/*              {removeHtmlTags(prt[`description_${lang}`])}*/}
          {/*            </p>*/}
          {/*          </div>*/}
          {/*          <SecondButton*/}
          {/*            className='moreBtnLarge portfolioWorkMoreBtn'*/}
          {/*            to={`/portfolio/${prt.id}`}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </Slider>*/}
          {/*</div>*/}
          <Footer />
        </>
      )}
    </motion.div>
  );
};

export default SinglePortfolioPage;
