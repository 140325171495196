import {Fragment, useEffect, useState} from "react";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import "./style.css";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getPolicy} from "../../redux/actions/newActions/newActions";
import Cookies from "js-cookie";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import Spinner from "../../components/Spinner/Spinner";
import {useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet";

const PrivacyPolicy = () => {
    const theme = useSelector(state => state.newReducer.themeChange)
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams({})
    const tab = searchParams.get('tab')
    useEffect(() => {
        dispatch(getPolicy())
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [tab]);
    const policeData = useSelector(state => state.newReducer.policePage)
    const [id, setId] = useState(0);
    useEffect(() => {
        if ((policeData.length > 0 && tab === 'policy') || !tab) {
            setId(policeData[0]?.id)
        } else if (policeData.length > 1 && tab === 'terms') {
            setId(policeData[1]?.id)
        }
    }, [policeData, tab])
    if (policeData.length < 1) {
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        );
    }
    return (
        <div>
            <Helmet>
                <title>Policy</title>
            </Helmet>
            <div className='privacyPolicyContainer'>
                {/*<div className='privacyPolicyTitleContainer'>*/}
                {/*    <p className='privacyPolicyTitle'>{t("privacy_policy")}</p>*/}
                {/*    <p className='privacyPolicyDescription'>*/}
                {/*        Effective date: April 3, 2023*/}
                {/*    </p>*/}
                {/*</div>*/}
                <div className='privacyPolicyDetails'>
                    <div className='privacyPolicyTableContentsContainer'>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} className='privacyPolicyTableContentsTitle'>{t('table-contents')}</p>
                        <div className='privacyPolicyTableContents'>
                            {policeData.map((content, i) => (
                                <Fragment key={i}>
                                    <Button
                                        link={true}
                                        onClick={e => {
                                            e.preventDefault();
                                            setId(content.id);
                                        }}
                                        to='sign-up'
                                        text={content[`heading_${lang}`]}
                                        style={{
                                            backgroundColor: id === content.id ? "var(--main-color-pink)" : "transparent",
                                            border: "none",
                                            fontFamily: "Poppins-600, sans-serif",
                                            fontWeight: "600",
                                            borderRadius: "var(--main-border-radius)",
                                            color: theme === 'ligth' && id !== content.id ? '#000' : "var(--secondary-color-white)",
                                            display: "block",
                                            textAlign: "left",
                                            transition: "0.3s",
                                        }}
                                        className='privacyPolicyHeaderBtn'
                                    />
                                </Fragment>
                            ))}
                        </div>
                    </div>
                    <div className='privacyPolicyDetailsDescription'>
                        <div className='articleInfo'>
                            {policeData.filter((e) => e.id === id).map((e) => (
                                <p style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} key={e.id}>{removeHtmlTags(e[`description_${lang}`])}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default PrivacyPolicy;
