import "./style.css";
import Button from "../Button/Button";
import SaveButton from "../SaveButton/SaveButton";
import currency from "../../Helpers/currency";
import {useSelector} from "react-redux";
import {localAddFavorites} from "../../redux/actions/newActions/newActions";
import {useWindowSize} from "../../hooks/useWindowSize";

const Product = ({
                     isSingle = false,
                     name,
                     price,
                     pending,
                     onBtnClick,
                     btnText,
                     img,
                     heartit,
                     id,
                     goSingle,
                 }) => {
    const checkPending = () => {
        let result = "";
        if (pending === "in_progress") return (result = "In Progress");
        if (pending === "ordered") return (result = "Ordered");

        return result;
    };
    const {width} = useWindowSize()
    const theme = useSelector(state => state.newReducer.themeChange)

    const favoriteProjects = useSelector(state => state.newReducer.favorites)
    const localAddFavorites = useSelector(state => state.newReducer.localFavorites)
    return (
        <div style={{
            background:theme === 'ligth' ? '#EFEFEF' : ''
        }} className={`productWrapper ${isSingle ? 'singleShopSugestionProd' : ''}`}>
            <div>
                <img style={{
                    borderTopRightRadius:20 ,
                    borderTopLeftRadius:20,
                    width:'100%',
                    cursor:'pointer'
                }} src={img} onClick={goSingle} className='productImg'/>
                <SaveButton isNew={true} onClick={heartit}
                            isSaved={(favoriteProjects?.some(project => project.product?.id === id)) || (localAddFavorites?.some(e => e.id === id))}/>
            </div>

            <div className='productDetails'>
                {pending && (
                    <div className={`${pending} productPending`}>
                        <p className='productPendingTitle'>{checkPending()}</p>
                    </div>
                )}
                <div className='productInfo'>
                    <p style={{
                        color:theme === 'ligth' ? '#000' : ''
                    }} onClick={goSingle} className='productGuideTitle'>{name}</p>
                    <div className='productGuide'>
                        <p style={{
                            visibility: (price == `0 ${currency}` || price == '0') ? 'hidden' : '',
                            color:theme === 'ligth' ? '#000' : ''
                        }}
                           className='productGuidePrice'>{price} {currency}</p>
                        <Button
                            text={btnText}
                            onClick={onBtnClick}
                            className='productRegulatorBtn'
                            style={{
                                position: 'absolute',
                                right:width > 1170 ? 10 :  0,
                                bottom: width > 1170 ? 7 :  0,
                                background: "var(--main-color-pink)",
                                boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.04)",
                                border: "none",
                                color: "var(--secondary-color-white)",
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
