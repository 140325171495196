import Img from "../Img";
import ARROW from "../../assets/icons/arrow-right-white.svg";
import "./style.css";
import Button from "../Button/Button";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import arrow_black from '../../assets/icons/arrow-right-black.svg'
import {useSelector} from "react-redux";
const SingleBlogBox = ({
                           isTest=false,
                           style,
                           size,
                           className,
                           name,
                           description,
                           btnText,
                           img,
                           to,
                           top,
                           tags
                       }) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const lang = Cookies.get("i18next") || "en";
    const trans =useSelector(state => state.newReducer.trans)
    return (
        <div
            className={`singleBlogBoxContainer_${size} ${className} singleBlogBoxContainer`}
            style={style}>
            <div style={{cursor: 'pointer'}} onClick={() => navigate(to)} className={`singleBlogBoxImg ${top}`}>
                <Img src={img} alt={name || 'Blog'} className={top}/>
            </div>
            <div className='singleBlogBoxInfoContainer'>
                <div className='singleBlogBoxBtn'>
                    {tags?.length > 0 ?
                        tags.map((e) => (
                            <Button key={e.id} text={e[`title_${lang}`]}/>
                        ))
                        : null}
                    {/*{btnText && <Button text={btnText}/>}*/}
                </div>
                <div className='singleBlogBoxTitleContainer'>
                    <p style={{cursor: 'pointer' , color: isTest ? '#000' : ''}} onClick={() => navigate(to)}
                       className='singleBlogBoxTitle'>{name}</p>
                    <p style={{color: isTest ? '#000' : ''}} className='singleBlogBoxDescription'>{description}</p>
                </div>
                <div className='singleBlogBoxLink'>
                    <Link style={{
                        color: isTest ? '#000' : ''
                    }} to={`${to}`}>
                        {trans.length > 0 ? trans.filter((e)=>e.key === 'Read_More')[0][`value_${lang}`] : t("read-more")}
                        <Img src={isTest ? arrow_black : ARROW} alt='Arrow'/>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SingleBlogBox;
