import {Fragment} from "react";
// import IMG_1 from "../../assets/ui-fake-images/portfolio-work-1.svg";
// import IMG_2 from "../../assets/ui-fake-images/portfolio-work-2.svg";
// import IMG_3 from "../../assets/ui-fake-images/portfolio-work-3.svg";
// import IMG_4 from "../../assets/ui-fake-images/portfolio-work-4.svg";
// import IMG_5 from "../../assets/ui-fake-images/portfolio-work-5.svg";
import {useWindowSize} from "../../hooks/useWindowSize";
import Button from "../Button/Button";
import FullTitle from "../FullTitle/FullTitle";
import PortfolioBox from "./PortfolioBox";
import "./style.css";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import {STORAGE_URL} from "../../services/apiService";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import Carousel from "nuka-carousel";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Portfolio = ({portfolio_main}) => {
    const {t} = useTranslation();
    const works = portfolio_main.works;
    const {width} = useWindowSize();
    const lang = Cookies.get("i18next") || "en";
    const slideStyle = {
        textAlign: 'center',
        borderRadius: '4px',
        marginRight:20
    };
    const theme = useSelector(state => state.newReducer.themeChange)
    const navigate = useNavigate()
    return (
        <div style={{
            background:theme === 'ligth' ? '#fff' : ''
        }} className='portfolioContainer container'>
            <FullTitle
                title={portfolio_main[`heading_${lang}`]}
                desc={removeHtmlTags(portfolio_main[`description_${lang}`])}
                btnLink={true}
                to={`/${lang}/${portfolio_main.link}`}
                btnStyle={ theme === 'dark' ?{
                    border: "1px solid rgba(255, 255, 255, 0.40)",
                    borderRadius: "8px",
                    color: "var(--secondary-color-white)",
                    background: "var(--main-bg-color-black)",
                    textDecoration: "none",
                    display: "block",
                    width: "fit-content",
                } : {
                    border: "1px solid rgba(000, 000, 000, 0.40)",
                    borderRadius: "8px",
                    color: "#000",
                    background: "#fff",
                    textDecoration: "none",
                    display: "block",
                    width: "fit-content",
                }}
            />
            <div className='portfolioWrapper'>
                {width < 700 ? (<Carousel
                        slidesToShow={1.1}
                        renderCenterRightControls={()=>null}
                        renderCenterLeftControls={()=>null}
                        renderBottomCenterControls={()=>null}
                        wrapAround={true}
                    >
                        {works.map((p, i) => (
                            <Fragment key={p.id}>
                                <div onClick={()=>{
                                    navigate(`/${lang}/portfolio/${p.id}`)
                                }} style={slideStyle}>
                                    <PortfolioBox
                                        className={`${i === 0 && "portfolioHeader1"} ${
                                            i === 1 && "portfolioContent1"
                                        } ${i === 2 && "portfolioSidebar"} ${
                                            i === 3 && "portfolioHeader"
                                        } ${i === 4 && "portfolioContent"}`}
                                        img={`${STORAGE_URL}/${p.image}`}
                                        name={p[`title_${lang}`]}
                                        description={removeHtmlTags(p[`description_${lang}`])}
                                        to={`/portfolio/${p.id}`}
                                    />
                                </div>
                            </Fragment>
                        ))}
                    </Carousel>)
                    : (
                        works.map((p, i) => (
                            <Fragment key={p.id}>
                                <PortfolioBox
                                    className={`${i === 0 && "portfolioHeader1"} ${
                                        i === 1 && "portfolioContent1"
                                    } ${i === 2 && "portfolioSidebar"} ${
                                        i === 3 && "portfolioHeader"
                                    } ${i === 4 && "portfolioContent"}`}
                                    img={`${STORAGE_URL}/${p.image}`}
                                    name={p[`title_${lang}`]}
                                    description={removeHtmlTags(p[`description_${lang}`])}
                                    to={`/portfolio/${p.id}`}
                                />
                            </Fragment>
                        ))
                    )}

            </div>
            {/*{width < 797 && (*/}
            {/*    <Button*/}
            {/*        to={`/${lang}/${portfolio_main.link}`}*/}
            {/*        text={t("view-more")}*/}
            {/*        className='fullTitleBtnMobile'*/}
            {/*        style={{*/}
            {/*            border: "1px solid rgba(255, 255, 255, 0.40)",*/}
            {/*            borderRadius: "8px",*/}
            {/*            color: "var(--secondary-color-white)",*/}
            {/*            background: "var(--main-bg-color-black)",*/}
            {/*            textDecoration: "none",*/}
            {/*            display: "block",*/}
            {/*            width: "100%",*/}
            {/*            marginTop: "30px",*/}
            {/*            textAlign: "center",*/}
            {/*        }}*/}
            {/*        link={true}*/}
            {/*    />*/}
            {/*)}*/}
        </div>
    );
};

export default Portfolio;
