const Img = ({src, alt, style, className, onLoad}) => {
    return (
        <img
            onLoad={onLoad}
            src={src}
            alt={alt}
            style={style}
            className={className}
            decoding='async'
            loading='lazy'
        />
    );
};

export default Img;
