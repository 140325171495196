import {ErrorMessage, Field, Form, Formik} from "formik";
import "./style.css";
import Img from "../../components/Img";
import Button from "../../components/Button/Button";
import UPLOAD from "../../assets/icons/upload-white.svg";
import ValidationSchema from "../../utils/ValidationSchema";
import Footer from "../../components/Footer/Footer";
import ARROW from "../../assets/icons/arrow-left-pink.svg";
import LOCATION from "../../assets/icons/location-white.svg";
import TIME from "../../assets/icons/time-white.svg";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSingleCarer, postContactUs} from "../../redux/actions/newActions/newActions";
import {useParams} from "react-router-dom";
import Cookies from "js-cookie";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import Spinner from "../../components/Spinner/Spinner";

const Position = () => {
    const theme = useSelector(state => state.newReducer.themeChange)
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const {contactSchema} = ValidationSchema();
    const {position} = useParams()
    const [message, setMessage] = useState('')
    const lang = Cookies.get("i18next") || "en";

    const [file, setFile] = useState({})
    const [fileName, setFileName] = useState('')
    const [reqLoading, setReqLoading] = useState(false)
    const shortenFileName = (fileName) => {
        const prefix = fileName.slice(0, 5);
        const extension = fileName.slice(fileName.lastIndexOf('.'));
        return `${prefix}...${extension}`;
    };
    const handleFileSelect = useCallback((ev) => {
        if (ev.target.files[0]) {
            setFileName(ev.target.files[0].name)
            setFile(ev.target.files[0])
        }
    }, [file, fileName]);
    const handleSendForm = useCallback(async (form) => {
        const {payload} = await dispatch(postContactUs({...form}))
        if (payload.status === 'success') {
            setMessage(payload?.message)
        }
    }, [])
    const singleCarer = useSelector(state => state.newReducer.singleCarer)
    useEffect(() => {
        dispatch(getSingleCarer({id: position}))
    }, [])
    return (
        <div>
            <div className='positionContainer container'>
                <div className='position1'>
                    <div className='articleBackBtnContainer'>
                        <button style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} onClick={() => {
                            window.history.back()
                        }} className='articleBackBtn'>
                            <div>
                                <Img src={ARROW} alt='Arrow'/>
                            </div>
                            {t("back")}
                        </button>
                    </div>
                    <div className='positionDescriptionTitle'>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }}>{singleCarer[`title_${lang}`]}</p>
                    </div>
                    <div className='vacancyInfo'>
                        <div style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }}>
                            <svg width="18" height="22" viewBox="0 0 18 22" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 9.14329C1 4.64588 4.58172 1 9 1C13.4183 1 17 4.64588 17 9.14329C17 13.6055 14.4467 18.8124 10.4629 20.6744C9.53426 21.1085 8.46574 21.1085 7.53707 20.6744C3.55332 18.8124 1 13.6055 1 9.14329Z"
                                    stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5"/>
                                <circle cx="9" cy="9" r="3" stroke={theme === 'ligth' ? 'black' : 'white'}
                                        stroke-width="1.5"/>
                            </svg>

                            {singleCarer.location}
                        </div>
                        <div style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11" cy="11" r="10" stroke={theme === 'ligth' ? 'black' : 'white'}
                                        stroke-width="1.5"/>
                                <path d="M11 7V11L13.5 13.5" stroke={theme === 'ligth' ? 'black' : 'white'}
                                      stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>

                            {singleCarer.type}
                        </div>
                    </div>
                    <div className='articleInfo'>
                        <p className={theme === 'ligth' ? 'desc-ligth' : ''}
                           dangerouslySetInnerHTML={{__html: singleCarer[`description_${lang}`]}}/>
                    </div>
                </div>
                <div className='position2'>
                    <Formik
                        initialValues={{
                            full_name: "",
                            email: "",
                            phone: "",
                            message: "",
                        }}
                        onSubmit={async (values, actions) => {
                            actions.setSubmitting(false);
                            actions.resetForm()
                            setReqLoading(true)
                            await handleSendForm({...values, file})
                            setFileName('')
                            setFile('')
                            setReqLoading(false)
                        }}
                        validationSchema={contactSchema}>
                        {() => (
                            <Form style={{
                                background: theme === 'ligth' ? 'rgba(255, 255, 255, 0.05)' : '',
                                border: theme === 'ligth' ? '1px solid black' : '',
                            }} className='contactForm'>
                                <p style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} className='formTitle'>{t('Apply for position')}</p>
                                <div className='contactFormGroup'>
                                    <label style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='full_name'>{t('full_name')} <span
                                        style={{color: '#af4b85'}}>*</span></label>
                                    <div className='contactFormGroupInput'>
                                        <Field
                                            style={{
                                                color: theme === 'ligth' ? '#000' : '',
                                                borderBottom:theme === 'ligth' ? '1px solid #000' : ''
                                            }}
                                            type='text'
                                            name='full_name'
                                            placeholder={t('placeholder.enter_your_full_name')}
                                        />
                                    </div>
                                    <ErrorMessage
                                        name='full_name'
                                        component='p'
                                        className='authError'
                                    />
                                </div>
                                <div className='contactFormGroup2ndLayout'>
                                    <div className='contactFormGroup'>
                                        <label
                                            style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }} htmlFor='email'>{t('email_address')} <span
                                            style={{color: '#af4b85'}}>*</span></label>
                                        <div className='contactFormGroupInput'>
                                            <Field
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : '',
                                                    borderBottom:theme === 'ligth' ? '1px solid #000' : ''
                                                }}
                                                type='email'
                                                name='email'
                                                placeholder={t('placeholder.enter_your_email_address')}
                                            />
                                        </div>
                                        <ErrorMessage
                                            name='email'
                                            component='p'
                                            className='authError'
                                        />
                                    </div>
                                    <div className='contactFormGroup'>
                                        <label
                                            style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }} htmlFor='phone'>{t('phone_number')} <span
                                            style={{color: '#af4b85'}}>*</span></label>
                                        <div className='contactFormGroupInput'>
                                            <Field
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : '',
                                                    borderBottom:theme === 'ligth' ? '1px solid #000' : ''
                                                }}
                                                type='text'
                                                name='phone'
                                                placeholder={t('placeholder.enter_your_phone_number')}
                                            />
                                        </div>
                                        <ErrorMessage
                                            name='phone'
                                            component='p'
                                            className='authError'
                                        />
                                    </div>
                                </div>
                                <div className='contactFormGroup'>
                                    <label
                                        style={{
                                            color: theme === 'ligth' ? '#000' : ''
                                        }} htmlFor='message'>{t('message')} <span
                                        style={{color: '#af4b85'}}>*</span></label>
                                    <div className='contactFormGroupInput'>
                                        <Field
                                            style={{
                                                color: theme === 'ligth' ? '#000' : '',
                                                borderBottom:theme === 'ligth' ? '1px solid #000' : ''
                                            }}
                                            type='text'
                                            name='message'
                                            placeholder={t('placeholder.enter_your_your_message')}
                                        />
                                    </div>
                                    <ErrorMessage
                                        name='message'
                                        component='p'
                                        className='authError'
                                    />
                                </div>
                                <div className='upload-btn-wrapper contactFormGroup'>
                                    <label
                                        style={{
                                            color: theme === 'ligth' ? '#000' : ''
                                        }} htmlFor='myfile'>{t('attach-file')}</label>
                                    <button style={{marginTop: 20, color: theme === 'ligth' ? '#000' : '',borderColor:theme === 'ligth' ? '#000' : ''}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1 13C1 15.8284 1 17.2426 1.87868 18.1213C2.75736 19 4.17157 19 7 19H13C15.8284 19 17.2426 19 18.1213 18.1213C19 17.2426 19 15.8284 19 13"
                                                stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                            <path d="M10 14V1M10 1L14 5.375M10 1L6 5.375" stroke={theme === 'ligth' ? 'black' : 'white'}
                                                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        {fileName ? shortenFileName(fileName) : t('placeholder.upload')}
                                    </button>
                                    <input onChange={handleFileSelect} accept=".doc, .docx, .pdf"
                                           type='file' name='myfile'/>
                                </div>
                                <Button
                                    btnType='submit'
                                    link={false}
                                    text={!reqLoading ? t('btnApply') : (
                                        <Spinner color='#fff' size={18}/>
                                    )}
                                    style={{
                                        backgroundColor: "var(--main-color-pink)",
                                        border: "none",
                                        fontFamily: "Poppins-600, sans-serif",
                                        fontWeight: "600",
                                        borderRadius: "var(--main-border-radius)",
                                        color: "var(--secondary-color-white)",
                                        width: "fit-content",
                                    }}
                                    className='bottomHeaderRegisterBtn'
                                />
                                {message ? <p style={{fontSize: 14}} className='packagePrice'>{message}<span
                                    style={{fontSize: 20, marginLeft: 10}}>&#10003;</span></p> : null}
                            </Form>
                        )}
                    </Formik>
                    {/*<Formik*/}
                    {/*  initialValues={{*/}
                    {/*    full_name: "",*/}
                    {/*    email: "",*/}
                    {/*    phone: "",*/}
                    {/*    message: "",*/}
                    {/*  }}*/}
                    {/*  onSubmit={(values, actions) => {*/}
                    {/*    actions.setSubmitting(false);*/}
                    {/*  }}*/}
                    {/*  validationSchema={contactSchema}>*/}
                    {/*  {() => (*/}
                    {/*    <Form className='contactForm'>*/}
                    {/*      <p className='formTitle'>Apply for position</p>*/}
                    {/*      <div className='contactFormGroup'>*/}
                    {/*        <label htmlFor='full_name'>Full Name *</label>*/}
                    {/*        <div className='contactFormGroupInput'>*/}
                    {/*          <Field*/}
                    {/*            type='text'*/}
                    {/*            name='full_name'*/}
                    {/*            placeholder='Enter your full name'*/}
                    {/*          />*/}
                    {/*        </div>*/}
                    {/*        <ErrorMessage*/}
                    {/*          name='full_name'*/}
                    {/*          component='p'*/}
                    {/*          className='authError'*/}
                    {/*        />*/}
                    {/*      </div>*/}
                    {/*      <div className='contactFormGroup2ndLayout'>*/}
                    {/*        <div className='contactFormGroup'>*/}
                    {/*          <label htmlFor='email'>Email Address *</label>*/}
                    {/*          <div className='contactFormGroupInput'>*/}
                    {/*            <Field*/}
                    {/*              type='email'*/}
                    {/*              name='email'*/}
                    {/*              placeholder='Enter your email address'*/}
                    {/*            />*/}
                    {/*          </div>*/}
                    {/*          <ErrorMessage*/}
                    {/*            name='email'*/}
                    {/*            component='p'*/}
                    {/*            className='authError'*/}
                    {/*          />*/}
                    {/*        </div>*/}
                    {/*        <div className='contactFormGroup'>*/}
                    {/*          <label htmlFor='phone'>Phone Number *</label>*/}
                    {/*          <div className='contactFormGroupInput'>*/}
                    {/*            <Field*/}
                    {/*              type='text'*/}
                    {/*              name='phone'*/}
                    {/*              placeholder='Enter your phone number'*/}
                    {/*            />*/}
                    {/*          </div>*/}
                    {/*          <ErrorMessage*/}
                    {/*            name='phone'*/}
                    {/*            component='p'*/}
                    {/*            className='authError'*/}
                    {/*          />*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*      <div className='contactFormGroup'>*/}
                    {/*        <label htmlFor='message'>Message</label>*/}
                    {/*        <div className='contactFormGroupInput'>*/}
                    {/*          <Field*/}
                    {/*            type='text'*/}
                    {/*            name='message'*/}
                    {/*            placeholder='Enter your message'*/}
                    {/*          />*/}
                    {/*        </div>*/}
                    {/*        <ErrorMessage*/}
                    {/*          name='message'*/}
                    {/*          component='p'*/}
                    {/*          className='authError'*/}
                    {/*        />*/}
                    {/*      </div>*/}
                    {/*      <div className='upload-btn-wrapper'>*/}
                    {/*        <button>*/}
                    {/*          <Img src={UPLOAD} alt='Upload' />*/}
                    {/*          Upload*/}
                    {/*        </button>*/}
                    {/*        <input type='file' name='myfile' />*/}
                    {/*      </div>*/}
                    {/*      <Button*/}
                    {/*        btnType='submit'*/}
                    {/*        link={false}*/}
                    {/*        text='Apply'*/}
                    {/*        style={{*/}
                    {/*          backgroundColor: "var(--main-color-pink)",*/}
                    {/*          border: "none",*/}
                    {/*          fontFamily: "Poppins-600, sans-serif",*/}
                    {/*          fontWeight: "600",*/}
                    {/*          borderRadius: "var(--main-border-radius)",*/}
                    {/*          color: "var(--secondary-color-white)",*/}
                    {/*          width: "fit-content",*/}
                    {/*        }}*/}
                    {/*        className='bottomHeaderRegisterBtn'*/}
                    {/*      />*/}
                    {/*    </Form>*/}
                    {/*  )}*/}
                    {/*</Formik>*/}
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Position;
