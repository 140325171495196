import {Fragment, useEffect, useLayoutEffect, useState} from "react";
import SingleBlogBox from "../../components/SingleBlogBox/SingleBlogBox";
import "./style.css";
import Footer from "../../components/Footer/Footer";
import {animate, initial} from "../../utils/transition";
import {motion} from "framer-motion";
import Button from "../../components/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {fetchBlog} from "../../redux/actions/blogActions";
import Spinner from "../../components/Spinner/Spinner";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import {STORAGE_URL} from "../../services/apiService";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {useWindowSize} from "../../hooks/useWindowSize";
import blackHeart from '../../assets/icons/heart-without-black.svg'
import whiteHeart from '../../assets/icons/heart-without-bg.svg'
import blackBasket from '../../assets/icons/basket-without-black.svg'
import whiteBasket from '../../assets/icons/basket-without-bg.svg'
import {Helmet} from "react-helmet";
const BlogPage = () => {
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const dispatch = useDispatch();
    const {width} = useWindowSize()
    const [isTest ,setIsTest] = useState(false)
    const theme = useSelector(state => state.newReducer.themeChange)

    useEffect(() => {
        dispatch(fetchBlog());
    }, []);
    const blog = useSelector(state => state.blog);
    const trans = useSelector(state => state.newReducer.trans)
    if (blog.blog === null && blog.loading)
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        );
    return (
        <motion.div initial={initial} animate={animate}>
            <Helmet>
                <title>WOW Effects-Blog</title>
            </Helmet>
            {blog.blog && (
                <>
                    <div className='blogPageContainer'>
                        <div className='blogPageOverviewContainer'>
                            <div className='overviewLargeBlogContainer'>
                                <SingleBlogBox
                                    isTest={theme === 'ligth'}
                                    tags={blog?.blog?.posts_in_blog[0]?.tags}
                                    className='overviewLargeBlog'
                                    size='large'
                                    style={{
                                        flexDirection: "column",
                                        gap: "32px",
                                    }}
                                    name={blog?.blog?.posts_in_blog[0][`title_${lang}`]}
                                    description={removeHtmlTags(
                                        blog?.blog?.posts_in_blog[0][`description_${lang}`]
                                    )}
                                    img={`${STORAGE_URL}/${blog.blog.posts_in_blog[0].image}`}
                                    btnText={ blog.blog.posts_in_blog[0].tags[0][`title_${lang}`]}
                                    to={`/${lang}/blog/${blog.blog.posts_in_blog[0].id}`}
                                />
                            </div>
                            <div className='overviewSmallBlogs'>
                                {blog.blog.posts_in_blog.slice(0, 3).map((blog, i) => (
                                    <Fragment key={i}>
                                        <SingleBlogBox
                                            isTest={theme === 'ligth'}
                                            tags={blog.tags}
                                            top='top'
                                            className='overviewSmallBlog'
                                            size='small'
                                            style={{
                                                flexDirection: "row",
                                                gap: "16px",
                                            }}
                                            name={blog[`title_${lang}`]}
                                            description={removeHtmlTags(blog[`description_${lang}`])}
                                            img={`${STORAGE_URL}/${blog.image}`}
                                            btnText={blog.tags[0][`title_${lang}`]}
                                            to={`/${lang}/blog/${blog.id}`}
                                        />
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                        <div className='blogPageSections'>
                            <p style={{color:theme === 'ligth' ? '#000' : ''}} className='blogPageSectionTitle'>{trans.length>0 ? trans.filter((e)=>e.key ==='Latest_blog_posts')[0][`value_${lang}`]: t("latest_blog")}</p>
                            <div className='blogPageSectionBlogs'>
                                {blog.blog.latest_posts.map((blog, i) => (
                                    <Fragment key={i}>
                                        <SingleBlogBox
                                            isTest={theme === 'ligth'}
                                            tags={blog.tags}
                                            className='blogSectionBlog'
                                            size='small'
                                            style={{
                                                flexDirection: "column",
                                                gap: "32px",
                                                width:width < 700 ? '100%' : ''
                                            }}
                                            name={blog[`title_${lang}`]}
                                            description={removeHtmlTags(blog[`description_${lang}`])}
                                            img={`${STORAGE_URL}/${blog.image}`}
                                            btnText={blog.tags[0][`title_${lang}`]}
                                            to={`/${lang}/blog/${blog.id}`}
                                        />
                                    </Fragment>
                                ))}
                                {/* <Button
                  className='seeMoreBtn'
                  text='See More'
                  link={false}
                  style={{
                    background: "rgba(255, 255, 255, 0.05)",
                    color: "rgba(255, 255, 255, 0.60)",
                    marginTop: "48px",
                    width: "100%",
                  }}
                /> */}
                            </div>
                        </div>
                        <div style={{marginTop:"30px"}} className='blogPageSections'>
                            {blog?.blog?.post_categories_with_posts?.map((category, i) => (
                                category?.posts?.length > 0 ? (
                                    <Fragment key={i}>
                                        <div>
                                            <p className='blogPageSectionTitle'>{category[`title_${lang}`]}</p>
                                            <div style={{
                                                display:'flex',
                                                gap:32 ,
                                                flexDirection:width<700 ? 'column':"",
                                                width:width < 700 ? '100%' : ''
                                            }}>
                                                {category?.posts?.map((post, j) => (
                                                    <div key={j}>
                                                        <SingleBlogBox
                                                            isTest={theme === 'ligth'}
                                                            tags={post.tags}
                                                            className='blogSectionBlog'
                                                            size='small'
                                                            style={{
                                                                flexDirection: "column",
                                                                gap: "32px",
                                                            }}
                                                            name={post[`title_${lang}`]}
                                                            description={removeHtmlTags(post[`description_${lang}`])}
                                                            img={`${STORAGE_URL}/${post?.image}`}
                                                            // btnText={blog.tags[0][`title_${lang}`]}
                                                            to={`/${lang}/blog/${post.id}`}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Fragment>
                                ) : null
                            ))}
                        </div>

                    </div>
                    <Footer/>
                </>
            )}
        </motion.div>
    );
};

export default BlogPage;
