import Button from "../Button/Button";
import FullTitle from "../FullTitle/FullTitle";
import "./style.css";
import {useWindowSize} from "../../hooks/useWindowSize";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import {Fragment, useEffect, useState} from "react";
import Product from "../../components/Product/Product";
import {useDispatch, useSelector} from "react-redux";
import {addToBasket} from "../../redux/actions/basketActions";
import apiService, {STORAGE_URL} from "../../services/apiService";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Slider from "../Slider/Slider";
import currency from '../../Helpers/currency'
import {
    addBasket, addToFavoritesAction, localAddFavorites,
    localBasketAdd,
    postAddAddress,
    postStoreRequirements
} from "../../redux/actions/newActions/newActions";
import {ToastContainer, toast} from "react-toastify";
import Modal from "../Modal/Modal";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Checkbox from "../Checkbox/Checkbox";
import Spinner from "../Spinner/Spinner";
import ValidationSchema from "../../utils/ValidationSchema";

const FurnitureInfos = ({product_suggestions}) => {
    const {t} = useTranslation();
    const trans = useSelector(state => state.newReducer.trans)
    const {width} = useWindowSize();
    const dispatch = useDispatch();
    const lang = Cookies.get("i18next") || "en";
    const [favoriteProjects, setFavoriteProjects] = useState(
        JSON.parse(localStorage.getItem("favoriteProjects") || "[]")
    );
    const theme = useSelector(state => state.newReducer.themeChange)

    const {storeRequirements} = ValidationSchema();
    const {isAuthenticated} = useSelector(state => state.auth);
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    const navigate = useNavigate()
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState('')
    const [addToFavoritesLoading, setAddToFavoritesLoading] = useState(false);
    const [addToFavoritesError, setAddToFavoritesError] = useState(null);
    const [addToFavoritesResponseData, setAddToFavoritesResponseData] =
        useState(null);
    const [showForm, setShowForm] = useState(false)
    const handleGoSingle = (id) => {
        navigate(`/${lang}/shop/${id}`)
    }
    const addToCart = async values => {
        const {payload} = await dispatch(addBasket({product_id: values.id, product_count: 1, user_id: user.id, token}))
        if (payload.status === 'success') {
            toast(t('add-cart'))
        }
    };
    const addToCartLogOut = (product) => {
        dispatch(localBasketAdd({...product, quantity: 1}))
        toast(t('add-cart'))
    }
    // console.log(product_suggestions)
    const handleHeart = async (product_id, f) => {
        if (isAuthenticated) {
            const {payload} = await dispatch(addToFavoritesAction({product_id, user_id: user?.id, token}))
            if (payload?.status === 'success') {
                toast(t('add_favorites'))
            }
        } else {
            dispatch(localAddFavorites({...f}))
            toast(t('add_favorites'))
        }
    }
    const handleShowForm = (id) => {
        setShowForm(true)
        setSelectedProductId(id)
    }
    return (
        <>
            <Modal
                title={trans.length > 0 ? trans.filter((e) => e.key === 'Create an application')[0][`value_${lang}`] : t("Create_Requirement")}
                open={showForm}
                onClose={() => setShowForm(false)}>
                <Formik
                    initialValues={{
                        name: '',
                        surname: '',
                        phone: '',
                        email: ''
                    }}
                    onSubmit={async (values, actions) => {
                        setLoading(true)
                        const {payload} = await dispatch(postStoreRequirements({
                            ...values,
                            product_id: selectedProductId,
                            token
                        }))
                        if (payload?.status === "success") {
                            toast.success(payload?.message)
                        }
                        setLoading(false)
                        setShowForm(false)

                    }}
                    validationSchema={storeRequirements}>
                    {() => (
                        <Form>
                            <div className='newAddresFormGroup'>
                                <label style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} htmlFor='name'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key=== 'Name')[0][`value_${lang}`] : t("name")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='name'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key=== 'Enter_your_name')[0][`value_${lang}`]:t("placeholder.enter_your_name")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='name'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='surname'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key=== 'Surname')[0][`value_${lang}`] : t("surname")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='surname'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key=== 'Enter_your_surname')[0][`value_${lang}`]:t("placeholder.enter_your_surname")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='surname'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='email'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key=== 'Email')[0][`value_${lang}`]:t("email")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='email'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key=== 'Enter_your_email_address')[0][`value_${lang}`]:t("placeholder.enter_your_email")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='email'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='phone'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key=== 'Phone_Number')[0][`value_${lang}`]:t("phone_number")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='phone'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key=== 'Enter_your_phone_number')[0][`value_${lang}`]:t("placeholder.enter_your_phone_number")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='phone'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <Button
                                text={
                                    loading ? (
                                        <Spinner color='#fff' size={18}/>
                                    ) : (
                                        trans.length > 0 ? trans.filter((e) => e.key === 'Create an application')[0][`value_${lang}`] : t("Create_Requirement")
                                    )
                                }
                                btnType='submit'
                                className='addAddressBtn'
                                style={{
                                    background: "var(--main-color-pink)",
                                    color: "var(--secondary-color-white)",
                                    border: "none",
                                    fontFamily: "Poppins-600, sans-serif",
                                    fontWeight: "600",
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            </Modal>
            <div style={{
                background: theme === 'ligth' ? "#F8F8F8" : ''
            }} className='furnitureContainer container'>
                {product_suggestions.map((fur, i) => (
                    <div className='furnitureInfoContainer' key={fur.id}>
                        <FullTitle
                            title={fur[`heading_${lang}`]}
                            desc={removeHtmlTags(fur[`description_${lang}`])}
                            btnLink={true}
                            btnStyle={{
                                border: "none",
                                borderRadius: "8px",
                                color: "var(--secondary-color-white)",
                                background: "var(--main-color-pink)",
                                textDecoration: "none",
                                display: "block",
                                width: "fit-content",
                            }}
                            to={`/${lang}${fur.link}`}
                        />
                        {width < 797 && (
                            <Button
                                to={`/${lang}${fur.link}`}
                                link={true}
                                text={t("view-more")}
                                style={{
                                    border: "none",
                                    borderRadius: "8px",
                                    color: "var(--secondary-color-white)",
                                    background: "var(--main-color-pink)",
                                    textDecoration: "none",
                                    display: "block",
                                    width: "64%",
                                    padding: "16px 32px",
                                    textAlign: "center",
                                    fontSize: "18px",
                                    marginTop: "24px",
                                    margin: '0 auto'
                                }}
                            />
                        )}
                        <Slider size={product_suggestions[i]?.products?.length}
                                showArrowBtns={(product_suggestions[i]?.products?.length > 4 && width > 800)}
                                className='furnituresWrapper'>
                            {fur.products?.map((f, i) => (
                                <Fragment key={f.id}>
                                    <Product
                                        goSingle={() => handleGoSingle(f.id)}
                                        name={f[`title_${lang}`]}
                                        price={f.price}
                                        // pending={order.pending}
                                        // onBtnClick={() => setViewOrder(true)}
                                        onBtnClick={() => f.price === 0 ? handleShowForm(f.id) : (isAuthenticated ? addToCart({...f}) : addToCartLogOut(f))}
                                        // onBtnClick={()=>addToBasketLocal(f)}
                                        heartit={() => handleHeart(f.id, {...f})}
                                        btnText={f.price === 0 ? t('To Order') : t('add-to-cart')}
                                        img={`${STORAGE_URL}/${f.image}`}
                                        id={f.id}
                                    />
                                </Fragment>
                            ))}
                        </Slider>
                    </div>
                ))}
            </div>
        </>
    );
};

export default FurnitureInfos;
