import IMG_404 from "../../assets/not-found-404.svg";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import Img from "../../components/Img";
import {motion} from "framer-motion";
import {initial, animate} from "../../utils/transition";
import "./style.css";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";

const PageNotFound = () => {
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const theme = useSelector(state => state.newReducer.themeChange)

    return (
        <motion.div initial={initial} animate={animate}>
            <Helmet>
                <title>404</title>
            </Helmet>
            <div className='pageNotFoundContainer'>
                <motion.div
                    initial={{
                        y: -100,
                        opacity: 0,
                    }}
                    animate={{y: 0, opacity: 1}}
                    className='img404'>
                    <svg width="568" height="181" viewBox="0 0 568 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.24">
                            <path d="M6.38672 8.08789L567.998 8.07996" stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path d="M6.38672 180L567.998 179.992" stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M128.324 29.1114C128.324 40.3202 119.243 49.4063 108.041 49.4063C96.8392 49.4063 87.7578 40.3202 87.7578 29.1114C87.7578 17.9025 96.8392 8.81641 108.041 8.81641C119.243 8.81641 128.324 17.9025 128.324 29.1114Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M128.324 159.037C128.324 170.246 119.243 179.332 108.041 179.332C96.8392 179.332 87.7578 170.246 87.7578 159.037C87.7578 147.828 96.8392 138.742 108.041 138.742C119.243 138.742 128.324 147.828 128.324 159.037Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M170.09 117.369C170.09 128.578 161.009 137.664 149.807 137.664C138.605 137.664 129.523 128.578 129.523 117.369C129.523 106.16 138.605 97.0742 149.807 97.0742C161.009 97.0742 170.09 106.16 170.09 117.369Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M49.7658 117.377C49.7658 128.586 40.6844 137.672 29.4825 137.672C18.2806 137.672 9.19922 128.586 9.19922 117.377C9.19922 106.168 18.2806 97.082 29.4825 97.082C40.6844 97.082 49.7658 106.168 49.7658 117.377Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <rect x="87.75" y="8.8125" width="40.5666" height="170.443" rx="20.2833" stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <rect x="9.19922" y="137.672" width="40.5899" height="160.648" rx="20.295"
                                  transform="rotate(-90 9.19922 137.672)" stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <rect x="-0.038274" y="-0.706267" width="40.577" height="158.377" rx="20.2885"
                                  transform="matrix(-0.744148 -0.668015 0.6676 -0.74452 31.3462 145.176)"
                                  stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <mask id="path-10-inside-1_341_55" fill={theme === 'ligth' ? 'black' : 'white'}>
                                <path
                                    d="M369.644 93.8093C369.644 141.152 331.286 179.531 283.97 179.531C236.654 179.531 198.297 141.152 198.297 93.8093C198.297 46.4667 236.654 8.08789 283.97 8.08789C331.286 8.08789 369.644 46.4667 369.644 93.8093ZM239.926 93.8093C239.926 118.148 259.645 137.878 283.97 137.878C308.295 137.878 328.014 118.148 328.014 93.8093C328.014 69.4708 308.295 49.7405 283.97 49.7405C259.645 49.7405 239.926 69.4708 239.926 93.8093Z"/>
                            </mask>
                            <path
                                d="M369.644 93.8093C369.644 141.152 331.286 179.531 283.97 179.531C236.654 179.531 198.297 141.152 198.297 93.8093C198.297 46.4667 236.654 8.08789 283.97 8.08789C331.286 8.08789 369.644 46.4667 369.644 93.8093ZM239.926 93.8093C239.926 118.148 259.645 137.878 283.97 137.878C308.295 137.878 328.014 118.148 328.014 93.8093C328.014 69.4708 308.295 49.7405 283.97 49.7405C259.645 49.7405 239.926 69.4708 239.926 93.8093Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="2" mask="url(#path-10-inside-1_341_55)"/>
                            <path
                                d="M369.141 94.0411C369.141 105.25 360.059 114.336 348.858 114.336C337.656 114.336 328.574 105.25 328.574 94.0411C328.574 82.8322 337.656 73.7461 348.858 73.7461C360.059 73.7461 369.141 82.8322 369.141 94.0411Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M239.363 94.0411C239.363 105.25 230.282 114.336 219.08 114.336C207.878 114.336 198.797 105.25 198.797 94.0411C198.797 82.8322 207.878 73.7461 219.08 73.7461C230.282 73.7461 239.363 82.8322 239.363 94.0411Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M304.485 28.8829C304.485 40.0917 295.403 49.1778 284.201 49.1778C272.999 49.1778 263.918 40.0917 263.918 28.8829C263.918 17.674 272.999 8.58789 284.201 8.58789C295.403 8.58789 304.485 17.674 304.485 28.8829Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M304.485 158.736C304.485 169.945 295.403 179.031 284.201 179.031C272.999 179.031 263.918 169.945 263.918 158.736C263.918 147.528 272.999 138.441 284.201 138.441C295.403 138.441 304.485 147.528 304.485 158.736Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M525.692 29.1114C525.692 40.3202 516.61 49.4063 505.408 49.4063C494.206 49.4063 485.125 40.3202 485.125 29.1114C485.125 17.9025 494.206 8.81641 505.408 8.81641C516.61 8.81641 525.692 17.9025 525.692 29.1114Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M525.692 159.037C525.692 170.246 516.61 179.332 505.408 179.332C494.206 179.332 485.125 170.246 485.125 159.037C485.125 147.828 494.206 138.742 505.408 138.742C516.61 138.742 525.692 147.828 525.692 159.037Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M567.449 117.369C567.449 128.578 558.368 137.664 547.166 137.664C535.964 137.664 526.883 128.578 526.883 117.369C526.883 106.16 535.964 97.0742 547.166 97.0742C558.368 97.0742 567.449 106.16 567.449 117.369Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <path
                                d="M447.129 117.377C447.129 128.586 438.048 137.672 426.846 137.672C415.644 137.672 406.562 128.586 406.562 117.377C406.562 106.168 415.644 97.082 426.846 97.082C438.048 97.082 447.129 106.168 447.129 117.377Z"
                                stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <rect x="485.109" y="8.8125" width="40.5666" height="170.443" rx="20.2833" stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <rect x="406.562" y="137.672" width="40.5899" height="160.648" rx="20.295"
                                  transform="rotate(-90 406.562 137.672)" stroke={theme === 'ligth' ? 'black' : 'white'}/>
                            <rect x="-0.038274" y="-0.706267" width="40.577" height="158.377" rx="20.2885"
                                  transform="matrix(-0.744148 -0.668015 0.6676 -0.74452 428.709 145.176)"
                                  stroke={theme === 'ligth' ? 'black' : 'white'}/>
                        </g>
                    </svg>
                </motion.div>
                <div className='pageNotFoundTitleContainer'>
                    <motion.div initial={{opacity: 0}} animate={{opacity: 1}}>
                        <p  style={{
                            color:theme === 'ligth' ? '#000' : ''
                        }} className='pageNotFoundTitle'>{t("oops")}</p>
                    </motion.div>
                    <p style={{
                        color:theme === 'ligth' ? '#000' : ''
                    }} className='pageNotFoundDescription'>{t("page_not_found_desc")}</p>
                    <Button
                        text={t("go_home")}
                        className='pageNotFoundBtn'
                        style={{
                            background: "var(--main-color-pink)",
                            color: "var(--secondary-color-white)",
                            border: "none",
                            display: "block",
                            textAlign: "center",
                            textDecoration: "none",
                        }}
                        link={true}
                        to={`/${lang}/`}
                    />
                </div>
            </div>
            <Footer/>
        </motion.div>
    );
};

export default PageNotFound;
