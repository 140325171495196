import Img from "../Img";
import CLOSE from "../../assets/icons/close-white.svg";
import "./style.css";
import {Fragment, useEffect, useState} from "react";
import Checkbox from "../Checkbox/Checkbox";
import CardProduct from "../CardProduct/CardProduct";
import Button from "../Button/Button";
import {AnimatePresence, motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {animate, initial} from "../../utils/transition";
import {
    calculateBasketTotalPrice,
    closeBasketModal,
    removeFromBasket,
} from "../../redux/actions/basketActions";
import {STORAGE_URL} from "../../services/apiService";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import Cookies from "js-cookie";
import {getTotalPrice, selectedItemAll} from "../../redux/actions/newActions/newActions";
import currency from "../../Helpers/currency";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const Basket = () => {
    const lang = Cookies.get("i18next") || "en";
    const dispatch = useDispatch();
    const {isAuthenticated} = useSelector(state => state.auth);
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [selectError ,setSelectError] = useState('')
    const basketModal = useSelector(state => state.basket.basketModal);
    useEffect(() => {
        document.body.classList.toggle("no-scroll", basketModal);
        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [basketModal]);
    const theme = useSelector(state => state.newReducer.themeChange)
    const basketItems = useSelector(state => state.newReducer.modalShowBasket);
    const basketSelected = useSelector(state => state.newReducer.basketSelected)
    const basketTotalPrice = useSelector(state => state.newReducer.basketTotalPrice)
    const localBasket = useSelector(state => state.newReducer.localBasket)
    // const basketTotalPrice = useSelector(state => state.basket.totalItemsPrice);
    useEffect(() => {
        dispatch(getTotalPrice())
    }, [basketItems]);
    useEffect(() => {
        basketItems.length === 0 && dispatch(closeBasketModal());
    }, [basketItems, dispatch]);

    useEffect(() => {
        basketItems && dispatch(calculateBasketTotalPrice());
    }, [basketItems]);
    // useEffect(() => {
    //     if ((basketItems && basketItems.length > 0) && (!basketSelected || basketSelected?.length === 0) ){
    //         dispatch(selectedItemAll())
    //     }
    // }, [basketItems]);
    const trans = useSelector(state => state.newReducer.trans)
    const handleNormal = ()=>{
        if (!basketSelected || basketSelected.length === 0){
            setSelectError(t('Select product'))
        }else if (isAuthenticated){
            navigate(`/${lang}/checkout/order`)
            dispatch(closeBasketModal())
        }else {
            dispatch(closeBasketModal())
            navigate(`/${lang}/sign-in`)
        }
    }
    return (
        <AnimatePresence>
            {basketModal && (
                <motion.div
                    initial={initial}
                    animate={animate}
                    transition={{duration: 0.3}}
                    className='basketOverlay'
                    onClick={() => dispatch(closeBasketModal())}>
                    <motion.div
                        initial={{opacity: 0, x: "100vw"}}
                        animate={{opacity: 1, x: 0}}
                        exit={{opacity: 0, x: "100vw"}}
                        transition={{duration: 0.3}}
                        className='basketContainer'
                        style={{
                            background:theme === 'ligth' ? '#fff' : ''
                        }}
                        onClick={e => e.stopPropagation()}>
                        <div className='basketHeader'>
                            <p style={{
                                color:theme === 'ligth' ? '#000' : ''
                            }} className='basketHeaderTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Cart')[0][`value_${lang}`] : t('Cart')}</p>
                            <button
                                className='basketHeaderClose'
                                onClick={() => dispatch(closeBasketModal())}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 13L13 1M1 1L13 13" stroke={theme === 'ligth' ? "black" : 'white' } stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className='basketBody'>
                            <Checkbox
                                checked={basketItems.length === basketSelected.length}
                                text={trans.length > 0 ? trans.filter((e)=>e.key === 'Select_all')[0][`value_${lang}`] : t('Select all')}
                                onChange={()=> {
                                    dispatch(selectedItemAll())
                                    setSelectError('')
                                }}
                                uniqueId={`basket_selectAll`}/>
                            {selectError && <p className='authError'>{selectError}</p>}
                            <div className='basketProducts'>
                                {basketItems.map((item, i) => (
                                    <Fragment key={item.id}>
                                        <CardProduct
                                            setSelectError={setSelectError}
                                            product_id={item?.product?.id || item.id   }
                                            title={item[`title_${lang}`] || (item?.product ? item?.product[`title_${lang}`] : null)}
                                            description={item.product && removeHtmlTags(item.product[`description_${lang}`])}
                                            img={item?.image ? `${STORAGE_URL}/${item.image}` : `${STORAGE_URL}/${item?.main_image || item?.product?.main_image}`}
                                            id={item.id}
                                            onRemove={() => dispatch(removeFromBasket(item.id))}
                                            quantity={item.quantity ||  item.product_count}
                                            price={(item.newPrice || item.price ) || item.sumar_price}
                                        />
                                    </Fragment>
                                ))}
                            </div>
                            <div style={{
                                borderTop:theme === 'ligth' ? '1px solid #000' : '',
                            }} className='basketSubtotalPriceContainer'>
                                <p style={{
                                    color:theme === 'ligth' ? '#000' : ''
                                }} className='basketSubtotalPrice'>{currency} {basketTotalPrice}</p>
                            </div>
                        </div>
                        <div className='basketContinueCheckoutBtn'>
                            <Button
                                text={t('Continue to Checkout')}
                                style={{
                                    borderRadius: "4px",
                                    background: "var(--main-color-pink)",
                                    color: "var(--secondary-color-white)",
                                    border: "none",
                                    display: "block",
                                    textAlign: "center",
                                    textDecoration: "none",
                                }}
                                link={false}
                                onClick={handleNormal}
                                // to={`/${lang}/checkout/order`}
                            />
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Basket;
