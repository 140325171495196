import Sidebar from "./Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import { motion } from "framer-motion";
import "./style.css";
import { animate, initial } from "../../utils/transition";
import {useEffect} from "react";
import {getUserAddresses} from "../../redux/actions/newActions/newActions";
import {useDispatch} from "react-redux";
import {Helmet} from "react-helmet";

const AccountLayout = ({ children }) => {
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    useEffect(() => {
        dispatch(getUserAddresses({id: user.id, token}))
    }, []);
  return (
      <>
          <Helmet>
              <title>Account</title>
          </Helmet>
          <div className='accountLayout'>
              <div className='accountDashboardArea'>
                  <Sidebar  />
                  <motion.div
                      style={{width:'100%'}}
                      initial={initial}
                      animate={animate}
                      className='accountContent'>
                      {children}
                  </motion.div>
              </div>
              <Footer />
          </div>
      </>
  );
};

export default AccountLayout;
