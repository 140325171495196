import React, {useRef, useState} from 'react';
import './style.css';
import {useDispatch, useSelector} from "react-redux";
import {themeChange} from "../../redux/actions/newActions/newActions";
import {useWindowSize} from "../../hooks/useWindowSize";
import svg from '../../assets/icons/lamp_line.svg'
function ThemeSwitcher(props) {
    const theme = useSelector(state => state.newReducer.themeChange)
    const dispatch = useDispatch()
    const {width} = useWindowSize();

    const [hasClass, setHasClass] = useState(false);

    const handleClick = () => {
        setHasClass(true);
        setTimeout(() => {
            setHasClass(false);
        }, 500);
    };
    return (
        <div onClick={() => dispatch(themeChange(theme === 'ligth' ? 'dark' : 'ligth'))}
             style={{cursor: 'pointer', marginRight: width < 700 ? '-10px' : '', width: 40, height: 40}}>
            <svg height="40px" width="40px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink"
                 viewBox="0 0 512.002 512.002" xmlSpace="preserve">

                <polygon style={{fill: '#38C6D9'}} points="171.585,395.354 118.19,235.168 251.61,128.433 264.203,144.174 141.758,242.128
    190.71,388.98 "/>
                <polygon style={{
                    fill: '#FFC61B',
                    opacity: theme === 'dark' ? 0 : 1,
                    transition: 'all 0.4s'
                }} points="455.112,398.222 256.001,398.222 256.001,227.556 350.29,198.408 "/>
                <circle style={{fill: theme === 'ligth' ? '#af4b85' : '#fff'}} cx="287.324" cy="142.446" r="43.494"/>
                <polygon style={{fill: theme === 'ligth' ? '#af4b85' : '#fff'}}
                         points="374.088,207.956 248.724,244.072 245.54,154.477 329.117,130.4 "/>
                <g>
                    <circle style={{fill: '#386895'}} cx="133.845" cy="238.64" r="20.935"/>
                    <path style={{fill: '#386895'}} d="M228.917,398.222H126.572c-7.066,0-12.793-5.729-12.793-12.793l0,0
    c0-7.066,5.729-12.793,12.793-12.793h102.345c7.066,0,12.793,5.729,12.793,12.793l0,0
    C241.711,392.494,235.983,398.222,228.917,398.222z"/>
                </g>
            </svg>
            {/*<div className={hasClass ? 'some-class' : ''} onClick={handleClick}>*/}
            {/*    <img style={{zIndex:955959595959}} src={svg} alt=""/>*/}
            {/*</div>*/}
        </div>
    );
}

export default ThemeSwitcher;
