export default function () {
    const queryParams = new URLSearchParams(window.location.search);
    const decodedParams = {};

    for (const [key, value] of queryParams.entries()) {
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        decodedParams[decodedKey] = isNaN(decodedValue) ? decodedValue : +decodedValue;
    }
    return decodedParams
}
