import {StrictMode} from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./index.css";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./redux/store";
import i18next from "./i18n";
import {I18nextProvider} from "react-i18next";
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <StrictMode>
                <I18nextProvider i18n={i18next}>
                    <GoogleOAuthProvider clientId="1040876083016-25cr1onaj1hff3q99kk0hbh58cbmsfnr.apps.googleusercontent.com">
                        <App/>
                    </GoogleOAuthProvider>
                </I18nextProvider>
            </StrictMode>
        </BrowserRouter>
    </Provider>
);

reportWebVitals();
