import React, {useState, useEffect, useLayoutEffect} from 'react';
import CheckoutLayout from "../Shop/Checkout/CheckoutLayout";
import Cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {thanksMessage} from "../../redux/actions/newActions/newActions";
import Spinner from "../../components/Spinner/Spinner";
import PageNotFound from "../PageNotFound/PageNotFound";

function CheckoutComplete() {
    const lang = Cookies.get("i18next") || "en";
    const basketItems = useSelector(state => state.newReducer.modalShowBasket);
    const basketSelected = JSON.parse(localStorage.getItem('basketSelected'))
    const [searchParams] = useSearchParams({})
    const orderId = searchParams.get('orderId')
    const dispatch = useDispatch()
    const orderMessage = useSelector(state => state.newReducer.orderMessage)
    const [loading, setLoading] = useState(true);
    const myCart = JSON.parse(localStorage.getItem('myCart'))
    useLayoutEffect(() => {
        if (basketItems && basketItems.length > 0 && basketSelected && basketSelected.length > 0 && myCart && myCart?.length > 0) {
            const y = async () => {
                const x = async () => {
                    const updatedCartData = basketItems.filter((e) => basketSelected.includes(e.id)).map((e) => {
                        return {
                            product_id: e.product?.id,
                            product_count: e.product_count
                        };
                    });
                    const {payload} = await dispatch(thanksMessage({cart: updatedCartData, orderId, lang}))
                    setLoading(false);
                    if (payload.message === 'Payment successfully completed'){
                        localStorage.removeItem('myCart')
                    }
                }
                await x()
                setLoading(false)
            }
            y()
        } else if ((basketItems?.length < 1 && !basketSelected) || (!myCart)) {
            setLoading(false)
        }
    }, [basketItems, basketSelected]);

    if (loading) {
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        )
    }

    return (
        <>
            {!loading && !orderMessage ? <PageNotFound/> : <CheckoutLayout>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <p style={{
                        fontSize: 36,
                        fontFamily: 'Poppins-700, sans-serif',
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        color: '#af4b85'
                    }}>{orderMessage}</p>
                </div>
            </CheckoutLayout>}

        </>
    );
}

export default CheckoutComplete;
