import "./style.css";
import {useSelector} from "react-redux";

const Title = ({ title, className }) => {
  const theme = useSelector(state => state.newReducer.themeChange)
  return <p style={{
    color:theme === 'ligth' ? '#000' : ''
  }} className={`${className} containerTitle`}>{title}</p>;
};

export default Title;
