import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {accountWaypoints} from "./data";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {theme} from "antd";
import {useSelector} from "react-redux";


export default function MobileSideBar() {
    const theme = useSelector(state => state.newReducer.themeChange)
    const {t} = useTranslation();
    const navigate = useNavigate();
    const lang = Cookies.get("i18next") || "en";
    const options = accountWaypoints.map((e) => {
        return {value: e.value, label: t(e.label)}
    })
    const [selectedOption, setSelectedOption] = useState();
    const path = window.location.pathname
    useEffect(() => {
        const x = options.filter((e) => `/${lang}${e.value}` === path)
        if (x.length > 0){
            setSelectedOption(x[0])
        }
    }, [])
    useEffect(() => {
        if (selectedOption) {
            navigate(`/${lang}${selectedOption.value}`)
        }
    }, [selectedOption])
    return (
        <div className='mobile__select'>
            <Select
                className="mobile-select-container"
                classNamePrefix="mobile-select"
                value={selectedOption}
                onChange={setSelectedOption}
                options={options}
                isSearchable={false}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        background: 'transparent',
                        border: theme !== 'ligth' ?  '1px solid rgba(255, 255, 255, 0.20)' : '1px solid #000',
                    }),
                    indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: "none"
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: theme === 'ligth' ? 'rgb(239, 239, 239)' : '',
                        // border: 'none',
                        color: theme === 'ligth' ? '#000 !important' : '',
                    }),
                    singleValue: (provided, state) => ({
                        ...provided,
                        color: theme === 'ligth' ?  '#000000 !important' : '',
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        backgroundColor: theme === 'ligth' ? 'rgb(239, 239, 239) !important' : '#000', // Цвет фона меню
                    })
                }}
            />
        </div>
    );
}
