import Title from "../Title/Title";
import Button from "../Button/Button";
import "./style.css";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const FullTitle = ({
                       btnStyle,
                       className,
                       title,
                       desc,
                       btnLink,
                       withoutBtn,
                       to,
                   }) => {
    const {t} = useTranslation();
    const theme = useSelector(state => state.newReducer.themeChange)
    const trans = useSelector(state => state.newReducer.trans)
    return (
        <div
            className={`${className} ${
                withoutBtn && "fullTitleContainerWithoutBtn"
            } fullTitleContainer`}>
            <div className='fullTitle'>
                <Title title={title}/>
                <p
                    style={{
                        color: theme === 'ligth' ? '#000' : ''
                    }}
                    className='fullTitleDesc'
                    dangerouslySetInnerHTML={{__html: desc}}
                />
            </div>
            {!withoutBtn && (
                <div className='fullTitleBtn'>
                    <Button
                        text={t("view-more")}
                        style={btnStyle}
                        link={btnLink}
                        to={to}
                    />
                {/*                        {trans.filter((e)=>e.key === '').map((e)=>(
                        <Button
                            text={t("view-more")}
                            style={btnStyle}
                            link={btnLink}
                            to={to}
                        />
                    ))}*/}
                </div>
            )}
        </div>
    );
};

export default FullTitle;
