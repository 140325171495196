import React from "react";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useSelector} from "react-redux";

const ReasonBox = ({ icon, title, description  }) => {
    const {width} = useWindowSize()
    const theme = useSelector(state => state.newReducer.themeChange)

  return (
    <div style={{
        background:theme === 'ligth' ? '#EFEFEF' : ''
    }}  className='reasonBoxWrapper'>
      <img src={icon} className='reasonBoxIcon' alt='Reason' />
      <p style={{
          color:theme === 'ligth' ? 'black' : ''
      }} className='reasonBoxTitle'>{title}</p>
      <p style={{
          color:theme === 'ligth' ? 'black' : ''
      }} className='reasonBoxDescription'>{description}</p>
    </div>
  );
};

export default ReasonBox;
