import {useState} from "react";
import "./style.css";
import {useSelector} from "react-redux";

const Filter = ({title, children, prefix = '', showTooltip, setShowTooltip, isSlider = false}) => {
    const [openFilter, setOpenFilter] = useState(false);
    const theme = useSelector(state => state.newReducer.themeChange)

    return (
        <div
            className={`${
                openFilter && "filterOptionsContainerActive"
            } filterOptionsContainer`}>
            <div
                className='filteringOptionsTitleContainer'
                onClick={() => {
                    if (setShowTooltip) {
                        if (isSlider) {
                            setShowTooltip(!showTooltip)
                        }
                    }
                    setOpenFilter(!openFilter)
                }}>
                <p style={{
                    color: theme === 'ligth' ? '#000' : ''
                }} className='filterOptionsTitle'>{title}</p>
                <p style={{
                    color: theme === 'ligth' ? '#000' : ''
                }} className='filteringOptionsMinusPlus'>
                    <span style={{
                            background:theme==='ligth' ? '#000' : ''
                        }}></span>
                    <span style={{
                            background:theme==='ligth' ? '#000' : ''
                        }}></span>
                </p>
            </div>
            <div className={`filteringOptions ${prefix}`}>{children}</div>
        </div>
    );
};

export default Filter;
