import {motion} from "framer-motion";
import "./style.css";
import PagesTitle from "../../components/Title/PagesTitle";
import {animate, initial} from "../../utils/transition";
import Img from "../../components/Img";
import ABOUT_US_PAGE_1 from "../../assets/ui-fake-images/about-us-page-1.jpg";
import Footer from "../../components/Footer/Footer";
import Portfolio from "../../components/Portfolio/Portfolio";
import {useCallback, useEffect, useRef, useState} from "react";
import {fetchHome} from "../../redux/actions/homeActions";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import COMPASS from "../../assets/icons/map-locations-compass-white.svg";
import Button from "../../components/Button/Button";
import {ErrorMessage, Field, Form, Formik} from "formik";
import ValidationSchema from "../../utils/ValidationSchema";
import UPLOAD from "../../assets/icons/upload-white.svg";
import {getCustomPageData, getTranslations, postContactUs} from "../../redux/actions/newActions/newActions";
import Cookies from "js-cookie";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import {STORAGE_URL} from "../../services/apiService";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {toast} from "react-toastify";

const InteriorDesign = () => {
    const theme = useSelector(state => state.newReducer.themeChange)
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const translations = useSelector(state => state.newReducer.trans)
    const [fileName, setFileName] = useState('')
    const [file, setFile] = useState({})
    const [message, setMessage] = useState('')
    const custom_production_main = useSelector(state => state.newReducer.custom_production_main)
    const timetables = useSelector(state => state.newReducer.timetables)
    const workingProcesses = useSelector(state => state.newReducer.workingProcesses)
    const shortenFileName = (fileName) => {
        const prefix = fileName.slice(0, 5);
        const extension = fileName.slice(fileName.lastIndexOf('.'));
        return `${prefix}...${extension}`;
    };
    const [reqLoading, setReqLoading] = useState(false)
    useEffect(() => {
        dispatch(fetchHome());
        dispatch(getTranslations())
        dispatch(getCustomPageData())
    }, []);
    const handleFileSelect = useCallback((ev) => {
        if (ev.target.files[0]) {
            setFileName(ev.target.files[0].name)
            setFile(ev.target.files[0])
        }
    }, [file, fileName]);
    const home = useSelector(state => state.home);

    const {contactSchema} = ValidationSchema();
    const formRef = useRef(null)
    const trans = useSelector(state => state.newReducer.trans)
    const handleSendForm = useCallback(async (form) => {
        const {payload} = await dispatch(postContactUs({...form}))
        if (payload.status === 'success') {
            setMessage(payload?.message[`message_${lang}`])
            toast(payload?.message[`message_${lang}`])
        }
    }, [])
    if (home.loading && home.home === null || translations.length < 1)
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        );
    return (
        <motion.div
            initial={initial}
            animate={animate}
            className='aboutUsContainer'>
            {home.home && (
                <>
                    <Helmet>
                        <title>Custom Craft</title>
                    </Helmet>
                    <PagesTitle
                        title={custom_production_main[`heading_${lang}`]}
                        description={removeHtmlTags(custom_production_main[`description_${lang}`])}
                    />
                    <Img
                        src={`${STORAGE_URL}/${custom_production_main.image}`}
                        alt='Drawing'
                        style={{
                            width: "100%",
                            height: "450px",
                            objectFit: "cover",
                        }}
                    />
                    <div className='interiorDesignWorkingProcessContainer'>
                        <p className='interiorDesignWorkingTitle1'>
                            {custom_production_main[`sub_title_${lang}`]}
                        </p>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : '#fff'
                        }} className='interiorDesignWorkingTitle2'>
                            {custom_production_main[`title_${lang}`]}
                        </p>
                        <div className='interiorDesignWorkingProcessMapContainer'>
                            <div className='workingProcessLine'></div>
                            {workingProcesses.map((cmp, i) => (
                                <div className='interiorDesignWorkingProcessMap' key={i}>
                                    <div className='compassImgWrapper'>
                                        <Img src={`${STORAGE_URL}/${cmp.icon}`} alt='Compass'/>
                                    </div>
                                    <p style={{
                                        color: theme === 'ligth' ? '#000' : '#fff'
                                    }} className='workingProcessMapTitle'>{removeHtmlTags(cmp[`heading_${lang}`])}</p>
                                    <p style={{
                                        color: theme === 'ligth' ? '#000' : '#fff'
                                    }}
                                       className='workingProcessMapDesc'>{removeHtmlTags(cmp[`description_${lang}`])}</p>
                                </div>
                            ))}
                        </div>
                        <div style={{
                            marginBottom: 0
                        }} className='packages'>
                            {timetables.map((pckg, i) => (
                                <div style={{
                                    background:theme === 'ligth' ? '#F8F8F8' : ''
                                }} className='package' key={i}>
                                    <p style={{
                                         color:theme === 'ligth' ? '#000' : ''
                                    }} className='packageTitle'>{pckg[`heading_${lang}`]}</p>
                                    <p  className='packagePrice'>{pckg[`days`]} <span
                                        className='packagePrice'>{translations[1][`value_${lang}`]}</span></p>
                                    <p style={{
                                        color:theme === 'ligth' ? '#000' : ''
                                    }} className='packageDesc'>{removeHtmlTags(pckg[`description_${lang}`])}</p>
                                    <Button
                                        text={trans.length > 0 ? trans.filter((e)=>e.key === 'Contact_Us')[0][`value_${lang}`] : t("bottomHeader.contact-us")}
                                        className='packageBtn'
                                        style={{
                                            borderRadius: "16px",
                                            color: "#fff",
                                            background: "#AF4B85",
                                            textDecoration: "none",
                                            display: "block",
                                            width: "100%",
                                            marginTop: "auto",
                                            textAlign: "center",
                                        }}
                                        link={false}
                                        onClick={() => {
                                            formRef.current.scrollIntoView({behavior: 'smooth'})
                                        }}
                                    />{" "}
                                </div>
                            ))}
                        </div>
                        {/*<p  style={{visibility:'hidden', height:120,}}></p>*/}
                        <div ref={formRef} style={{
                            paddingTop: 120
                        }} className='interiorDesignContactUsContainer'>
                            <div className='interorDesignContactUsTitleContainer'>
                                <p style={{
                                    color:theme === 'ligth' ? '#000' : ''
                                }} className='interorDesignContactUsTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Contact_Us')[0][`value_${lang}`] : t("bottomHeader.contact-us")}</p>
                                <p style={{
                                    color:theme === 'ligth' ? '#000' : ''
                                }} className='interorDesignContactUsDescription'>
                                    {custom_production_main[`sub_title_${lang}`]}
                                </p>
                            </div>
                            <Formik
                                initialValues={{
                                    full_name: "",
                                    email: "",
                                    phone: "",
                                    message: "",
                                }}
                                onSubmit={async (values, actions) => {
                                    actions.setSubmitting(false);
                                    actions.resetForm()
                                    setReqLoading(true)
                                    await handleSendForm({...values, file})
                                    setFile('')
                                    setFileName('')
                                    setReqLoading(false)

                                }}
                                validationSchema={contactSchema}>
                                {() => (
                                    <Form style={{
                                        background:theme === 'ligth' ? '#fff' : '',
                                        border:theme === 'ligth' ? '1px solid #000' : ''
                                    }} className='contactForm'>
                                        <div className='contactFormGroup'>
                                            <label style={{
                                                color:theme === 'ligth' ? '#000' : ''
                                            }} htmlFor='full_name'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Full_Name')[0][`value_${lang}`] : t('full_name')} <span
                                                style={{color: '#af4b85'}}>*</span></label>
                                            <div className='contactFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color:theme === 'ligth' ? '#000' : '',
                                                        borderBottom:theme === 'ligth' ? '1px solid #000' : ''
                                                    }}
                                                    type='text'
                                                    name='full_name'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_full_name')[0][`value_${lang}`] : t('placeholder.enter_your_full_name')}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name='full_name'
                                                component='p'
                                                className='authError'
                                            />
                                        </div>
                                        <div className='contactFormGroup2ndLayout'>
                                            <div className='contactFormGroup'>
                                                <label style={{
                                                color:theme === 'ligth' ? '#000' : ''
                                            }} htmlFor='email'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Email_Address')[0][`value_${lang}`] : t('email_address')} <span
                                                    style={{color: '#af4b85'}}>*</span></label>
                                                <div className='contactFormGroupInput'>
                                                    <Field
                                                        style={{
                                                            color:theme === 'ligth' ? '#000' : '',
                                                            borderBottom:theme === 'ligth' ? '1px solid #000' : ''
                                                        }}
                                                        type='email'
                                                        name='email'
                                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_email_address')[0][`value_${lang}`] :t('placeholder.enter_your_email_address')}
                                                    />
                                                </div>
                                                <ErrorMessage
                                                    name='email'
                                                    component='p'
                                                    className='authError'
                                                />
                                            </div>
                                            <div className='contactFormGroup'>
                                                <label style={{
                                                color:theme === 'ligth' ? '#000' : ''
                                            }} htmlFor='phone'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_Number')[0][`value_${lang}`] :t('phone_number')} <span
                                                    style={{color: '#af4b85'}}>*</span></label>
                                                <div className='contactFormGroupInput'>
                                                    <Field
                                                        style={{
                                                            color:theme === 'ligth' ? '#000' : '',
                                                            borderBottom:theme === 'ligth' ? '1px solid #000' : ''
                                                        }}
                                                        type='text'
                                                        name='phone'
                                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_phone_number')[0][`value_${lang}`] :t('placeholder.enter_your_phone_number')}
                                                    />
                                                </div>
                                                <ErrorMessage
                                                    name='phone'
                                                    component='p'
                                                    className='authError'
                                                />
                                            </div>
                                        </div>
                                        <div className='contactFormGroup'>
                                            <label style={{
                                                color:theme === 'ligth' ? '#000' : ''
                                            }} htmlFor='message'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Message')[0][`value_${lang}`] :t('message')} <span
                                                style={{color: '#af4b85'}}>*</span></label>
                                            <div className='contactFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color:theme === 'ligth' ? '#000' : '',
                                                        borderBottom:theme === 'ligth' ? '1px solid #000' : ''
                                                    }}
                                                    type='text'
                                                    name='message'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_message')[0][`value_${lang}`] :t('placeholder.enter_your_your_message')}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name='message'
                                                component='p'
                                                className='authError'
                                            />
                                        </div>
                                        <div className='upload-btn-wrapper contactFormGroup'>
                                            <label style={{
                                                color:theme === 'ligth' ? '#000' : ''
                                            }} htmlFor='myfile'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Attach_file')[0][`value_${lang}`] :t('attach-file')}</label>
                                            <button style={{
                                                marginTop: 20,
                                                border:theme === 'ligth' ? '1px solid black' : '',
                                                color:theme === 'ligth' ? '#000' : ''
                                            }}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1 13C1 15.8284 1 17.2426 1.87868 18.1213C2.75736 19 4.17157 19 7 19H13C15.8284 19 17.2426 19 18.1213 18.1213C19 17.2426 19 15.8284 19 13"
                                                        stroke={theme === 'ligth' ? "black" : 'white'} stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                    <path d="M10 14V1M10 1L14 5.375M10 1L6 5.375" stroke={theme === 'ligth' ? "black" : 'white'}
                                                          stroke-width="1.5" stroke-linecap="round"
                                                          stroke-linejoin="round"/>
                                                </svg>
                                                {fileName ? shortenFileName(fileName) : t('placeholder.upload')}
                                            </button>
                                            <input  onChange={handleFileSelect} accept=".doc, .docx, .pdf"
                                                   type='file' name='myfile'/>
                                        </div>
                                        <Button
                                            btnType='submit'
                                            link={false}
                                            text={!reqLoading && trans.length > 0 ? t('btnApply') : (
                                                <Spinner color='#fff' size={18}/>
                                            )}
                                            style={{
                                                backgroundColor: "var(--main-color-pink)",
                                                border: "none",
                                                fontFamily: "Poppins-600, sans-serif",
                                                fontWeight: "600",
                                                borderRadius: "var(--main-border-radius)",
                                                color: "var(--secondary-color-white)",
                                                width: "fit-content",
                                            }}
                                            className='bottomHeaderRegisterBtn'
                                        />
                                        {message ? <p style={{fontSize: 14}} className='packagePrice'>{message}<span
                                            style={{fontSize: 20, marginLeft: 10}}>&#10003;</span></p> : null}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <Portfolio portfolio_main={home.home.portfolio_main}/>
                    <Footer slider={false}/>
                </>
            )}
        </motion.div>
    );
};

export default InteriorDesign;
