import Footer from "../../components/Footer/Footer";
import {motion} from "framer-motion";
import PagesTitle from "../../components/Title/PagesTitle";
import Button from "../../components/Button/Button";
import {Fragment, useEffect, useState} from "react";
import ARROW from "../../assets/icons/arrow-down-white.svg";
import Img from "../../components/Img";
import "./style.css";
import SecondButton from "../../components/SecondButton/SecondButton";
import {animate, initial} from "../../utils/transition";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchPortfolio,
    fetchWorkCategories,
    fetchWorkTypes,
} from "../../redux/actions/portfolioActions";
import Spinner from "../../components/Spinner/Spinner";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import {STORAGE_URL} from "../../services/apiService";
import Cookies from "js-cookie";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
const PortfolioPage = () => {
    const lang = Cookies.get("i18next") || "en";
    const [selectedCategory, setSelectedCategory] = useState('')
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(fetchPortfolio());
        dispatch(fetchWorkCategories());
        dispatch(fetchWorkTypes());
    }, []);
    const theme = useSelector(state => state.newReducer.themeChange)

    const portfolio = useSelector(state => state.portfolio);
    const workCategories = useSelector(state => state.portfolio.workCategories);
    const workTypes = useSelector(state => state.portfolio.workTypes);
    const [showMenu, setShowMenu] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    useEffect(() => {
        if (workCategories && workCategories?.work_categories) {
            setSelectedCategory(workCategories?.work_categories[0]?.id)
            setSelectedItemSecond(workCategories?.work_categories[0])
        }
    }, [workCategories])
    useEffect(() => {
        workTypes && setSelectedItem(workTypes.work_types[0]);
    }, [workTypes]);
    const [secondShowMenu, setSecondShowMenu] = useState(false)
    const [selectedItemSecond, setSelectedItemSecond] = useState(null)
    const handleDropdownClick = () => {
        setShowMenu(!showMenu);
    };
    const handleDropdownClickSecond = () => {
        setSecondShowMenu(!secondShowMenu)
    }
    const handleMenuItemClick = item => {
        setSelectedItem(item);
        setShowMenu(false);
    };
    const handleMenuItemClickSecond = item => {
        setSelectedItemSecond(item);
        setSecondShowMenu(false);
    };

    const {width} = useWindowSize()
    if (
        portfolio.portfolio === null &&
        workCategories === null &&
        workTypes === null &&
        selectedItem === null
    )
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        );
    return (
        <motion.div
            initial={initial}
            animate={animate}
            className='portfolioPageContainer'>
            {portfolio.portfolio && workCategories && workTypes && selectedItem && (
                <>
                    <Helmet>
                        <title>Portfolio</title>
                    </Helmet>
                    <PagesTitle
                        title={portfolio.portfolio.portfolio_main[`heading_${lang}`]}
                        description={removeHtmlTags(
                            portfolio.portfolio.portfolio_main[`description_${lang}`]
                        )}
                        style={{
                            flexDirection: "column",
                            gap: "12px",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    />
                    <div className='filterPanel container'>
                        <div className='filterBtns'>
                            {width > 700 ? (workCategories.work_categories.map(btn => (
                                <Fragment key={btn.id}>
                                    <Button
                                        className='filterBtn'
                                        text={btn[`title_${lang}`]}
                                        link={true}
                                        style={{
                                            background: +selectedItemSecond?.id === +btn.id ? "var(--main-color-pink)" : (theme === 'ligth') ? "white" :  "rgba(255, 255, 255, 0.05)",
                                            color:+selectedItemSecond?.id === +btn.id ? "white" : (theme === 'ligth') ? "black" :  "rgba(255, 255, 255, 0.05)",
                                            border:theme === 'ligth' &&  +selectedItemSecond?.id !== +btn.id  ? '1px solid black' : ''
                                        }}
                                        onClick={() => setSelectedItemSecond(btn)}
                                    />
                                </Fragment>
                            ))) : <div style={{
                                border:theme==='ligth' ? '1px solid black' : ''
                            }} className='filterDropHolder' onClick={handleDropdownClickSecond}>
                                <div
                                    className='filterDropdown'>
                                    {selectedItemSecond && <p style={{
                                        color: theme === 'ligth' ? 'black' : ''
                                    }}>{selectedItemSecond[`title_${lang}`]}</p>}
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.75 0.75L6 5.25L11.25 0.75" stroke={theme==='ligth' ? 'black' : "white"} stroke-width="1.5"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <ul
                                    style={{
                                        background:theme==='ligth' ? '#F8F8F8' : '',
                                        border:theme==='ligth' ? '1px solid black' : ''
                                    }}
                                    className={`${secondShowMenu && "showMenu"} filterMenu`}>
                                    {workCategories.work_categories.map((st,index)=> (
                                        <li style={{
                                            color: theme === 'ligth' ? 'black' : '',
                                            borderBottom: theme === 'ligth' && index !==1 ? '1px solid black' : ''
                                        }} key={st.id} onClick={() => handleMenuItemClickSecond(st)}>
                                            {st[`title_${lang}`]}
                                        </li>
                                    ))}
                                </ul>
                            </div>}
                        </div>
                        <div
                            style={{
                                border:theme==='ligth' ? '1px solid black' : ''
                            }}
                            className='filterDropHolder' onClick={handleDropdownClick}>
                            <div className='filterDropdown'>
                                <p style={{color: theme === 'ligth' ? 'black' : ''}}>{selectedItem[`title_${lang}`]}</p>
                                <svg width="12" height="6" viewBox="0 0 12 6" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.75 0.75L6 5.25L11.25 0.75"
                                          stroke={theme === 'ligth' ? 'black' : "white"} stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <ul
                                style={{
                                    background: theme === 'ligth' ? '#F8F8F8' : '',
                                    border: theme === 'ligth' ? '1px solid black' : ''
                                }}
                                className={`${showMenu && "showMenu"} filterMenu`}>
                                {workTypes.work_types.map((st , index)=> (
                                    <li style={{
                                        color: theme === 'ligth' ? 'black' : '',
                                        borderBottom: theme === 'ligth' && index !==2 ? '1px solid black' : ''
                                    }} key={st.id} onClick={() => handleMenuItemClick(st)}>
                                        {st[`title_${lang}`]}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='container' style={{marginBottom: "100px"}}>
                        <div className='portfolioHolderContainer'>
                            {portfolio?.portfolio?.portfolio_main?.works?.filter((e) =>
                                (e.type_id === selectedItem?.id && +e.category_id === +selectedItemSecond?.id) ||
                                (selectedItem?.id === 1 && +e.category_id === +selectedItemSecond?.id)
                            )
                                .map(p => (
                                <div
                                    className='portfolioCard'
                                    style={{
                                        backgroundImage: `url(${STORAGE_URL}/${p.image})`,
                                    }}
                                    key={p.id}>
                                    <div className='portfolioCardInfo'>
                                        <div className='portfolioDetails'>
                                            <p className='portfolioDetailsTitle'>
                                                {p[`title_${lang}`]}
                                            </p>
                                            <p className='portfolioDetailsDescription'>
                                                {removeHtmlTags(p[`description_${lang}`])}
                                            </p>
                                        </div>
                                        <SecondButton
                                            className='moreBtnLarge portfolioWorkMoreBtn'
                                            to={`/portfolio/${p.id}`}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        {width < 797 && (
                            <Button
                                to={`/${lang}/${'as'}`}
                                text={t("view-more")}
                                className='fullTitleBtnMobile'
                                style={{
                                    border: "1px solid rgba(255, 255, 255, 0.40)",
                                    borderRadius: "8px",
                                    color: "var(--secondary-color-white)",
                                    background: "var(--main-bg-color-black)",
                                    textDecoration: "none",
                                    display: "block",
                                    width: "100%",
                                    marginTop: "30px",
                                    textAlign: "center",
                                }}
                                link={true}
                            />
                        )}
                        {/* <Button
              className='seeMoreBtn'
              text='See More'
              link={false}
              style={{
                background: "rgba(255, 255, 255, 0.05)",
                color: "rgba(255, 255, 255, 0.60)",
                marginTop: "48px",
                width: "100%",
              }}
            /> */}
                    </div>
                    <Footer/>
                </>
            )}
        </motion.div>
    );
};

export default PortfolioPage;
