import CardProduct from "../../../components/CardProduct/CardProduct";
import Checkbox from "../../../components/Checkbox/Checkbox";
import CheckoutLayout from "./CheckoutLayout";
import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {Fragment, useEffect, useLayoutEffect, useState} from "react";
import {
    calculateBasketTotalPrice,
    removeFromBasket,
} from "../../../redux/actions/basketActions";
import Slider from "../../../components/Slider/Slider";
import Title from "../../../components/Title/Title";
import Button from "../../../components/Button/Button";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {STORAGE_URL} from "../../../services/apiService";
import {removeHtmlTags} from "../../../Helpers/removeHtmlTags";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {selectedItemAll, selectedItemsAdd} from "../../../redux/actions/newActions/newActions";
import currency from "../../../Helpers/currency";
import {useNavigate} from "react-router-dom";
import {theme} from "antd";

const CheckoutOrder = () => {
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const basketItems = useSelector(state => state.newReducer.modalShowBasket);
    const basketSelected = useSelector(state => state.newReducer.basketSelected)
    const dispatch = useDispatch();
    const {width} = useWindowSize();
    const [showArrowBtns, setShowArrowBtns] = useState(false);
    const [x, setX] = useState(false)
    const [selectError, setSelectError] = useState('')
    const navigate = useNavigate()
    const checkErrorText = useSelector(state => state.newReducer.checkErrorText)
    useLayoutEffect(() => {
        setX(true)
    }, []);
    useEffect(() => {
        const sliderContainer = document.querySelector(".slider ");
        const items = document.querySelectorAll(".productSliderItem");
        const gap = 32;
        let totalWidth = 0;
        if (sliderContainer && items) {
            items.forEach(img => {
                //@ts-ignore
                totalWidth += img.offsetWidth + gap;
            });
            //@ts-ignore
            setShowArrowBtns(totalWidth > sliderContainer.offsetWidth);
        }
    }, [width]);
    const basketTotalPrice = useSelector(state => state.newReducer.basketTotalPrice);
    const delivery = 0;
    const handleGoNext = () => {
        if (basketSelected && basketSelected.length > 0) {
            navigate(`/${lang}/checkout/place-order`)
        } else {
            setSelectError(t('Select product'))
        }
    }
    const theme = useSelector(state => state.newReducer.themeChange)
    const trans = useSelector(state => state.newReducer.trans)
    return (
        <CheckoutLayout>
            <div className='checkoutCardContainer container'>
                <div style={{width: "60%"}}>
                    <div className='checkoutCardTitleContainer'>
                        <p style={{
                            color:theme === 'ligth' ? '#000' : ''
                        }} className='checkoutCardTitle'>{trans.length > 0 ? trans.filter((e)=>e.key ==='Cart')[0][`value_${lang}`] :t('Cart')}</p>
                    </div>
                    <div className='checkoutCard'>
                        <div className='checkoutCardCheckboxContainer'>
                            <Checkbox
                                checked={basketItems.length === basketSelected.length}
                                text={trans.length > 0 ? trans.filter((e)=>e.key ==='Select_all')[0][`value_${lang}`] : t('Select all')}
                                onChange={() => dispatch(selectedItemAll())}
                                uniqueId={`basket_selectAll`}/>
                            {selectError && <p className='authError'>{selectError}</p>}
                            {checkErrorText && <p className='authError'> {checkErrorText}</p>}
                        </div>
                        <div className='checkoutCards'>
                            {!x ? basketItems.filter((e) => basketSelected.includes(e?.id)).map((item, i) => (
                                <Fragment key={i}>
                                    <CardProduct
                                        setSelectError={setSelectError}
                                        product_id={item.product.id}
                                        title={item?.product[`title_${lang}`]}
                                        description={removeHtmlTags(item?.product[`description_${lang}`])}
                                        img={`${STORAGE_URL}/${item.product.main_image}`}
                                        id={item.id}
                                        onRemove={() => dispatch(removeFromBasket(item.id))}
                                        quantity={item.product_count}
                                        price={item.sumar_price}
                                    />
                                </Fragment>
                            )) : basketItems.map((item, i) => (
                                <Fragment key={i}>
                                    <CardProduct
                                        setSelectError={setSelectError}
                                        product_id={item.product.id}
                                        title={item?.product[`title_${lang}`]}
                                        description={removeHtmlTags(item?.product[`description_${lang}`])}
                                        img={`${STORAGE_URL}/${item.product.main_image}`}
                                        id={item.id}
                                        onRemove={() => dispatch(removeFromBasket(item.id))}
                                        quantity={item.product_count}
                                        price={item.sumar_price}
                                    />
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{
                    background: theme === 'ligth' ? '#fff' : ''
                }} className='orderSummaryContainer'>
                    <div className='orderSummaryTitleContainer'>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} className='orderSummaryTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Order_Summary')[0][`value_${lang}`] : t('Order Summary')}</p>
                    </div>
                    <div className='orderSubtotalContainer'>
                        <div className='subtotal'>
                            <p style={{
                                color: theme === 'ligth' ? '#000' : ''
                            }} className='subtotalTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Subtotal')[0][`value_${lang}`] :t('Subtotal')}</p>
                            <p style={{
                                color: theme === 'ligth' ? '#000' : ''
                            }} className='subtotalDescription'>{currency} {basketTotalPrice}</p>
                        </div>
                        {/*<div className='subtotal'>*/}
                        {/*    <p className='subtotalTitle'>{t('Delivery')}</p>*/}
                        {/*    <p className='subtotalDescription'>{currency} {delivery}</p>*/}
                        {/*</div>*/}
                    </div>
                    <div style={{
                        background: theme === 'ligth' ? '#000' : ''
                    }} className='orderSummaryLine'></div>
                    <div className='totalContainer'>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} className='subtotalTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Total')[0][`value_${lang}`] :t('Total')}</p>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} className='subtotalDescription'>
                            {currency} {basketTotalPrice + delivery}
                        </p>
                    </div>
                    <Button
                        text={trans.length > 0 ? trans.filter((e)=>e.key === 'Continue')[0][`value_${lang}`] :t('Continue')}
                        // icon={PLUS}
                        // alt='Plus'
                        link={false}
                        onClick={handleGoNext}
                        // to={`/${lang}/checkout/place-order`}
                        className='checkoutContinueBtn'
                        style={{
                            background: "var(--main-color-pink)",
                            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.04)",
                            border: "none",
                            color: "var(--secondary-color-white)",
                            fontFamily: "Poppins-600, sans-serif",
                            fontWeight: "600",
                            textDecoration: "none",
                            display: "block",
                            textAlign: "center",
                        }}
                    />
                </div>
            </div>
            <div className='shopSuggestionsSliderContainer container'>
                {/*<Title title='Related Projects' />*/}
                <Slider className='portfolioSlider' showArrowBtns={showArrowBtns}>
                    {/*{orders.map((order, i) => (*/}
                    {/*  <div className='productSliderItem' key={i}>*/}
                    {/*    <Product*/}
                    {/*      name={order.name}*/}
                    {/*      price={order.price}*/}
                    {/*      img={order.img}*/}
                    {/*      // pending={order.pending}*/}
                    {/*      // onBtnClick={() => setViewOrder(true)}*/}
                    {/*      btnText={t("add-to-cart")}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*))}*/}
                </Slider>
            </div>
        </CheckoutLayout>
    );
};

export default CheckoutOrder;
