import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import english from './assets/locales/en/translation.json'
import russian from './assets/locales/ru/translation.json'
import armenian from './assets/locales/am/translation.json'
i18next
    .use(HttpApi)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        supportedLngs: ["ru", "en", "am"],
        fallbackLng: "en",
        // debug: false,
        detection: {
            order: ["path", "cookie", "htmlTag"],
            caches: ["cookie"],
        },
        react: {useSuspense: false},
        resources: {
            en: {
                translation: english
            },
            ru: {
                translation: russian
            },
            am: {
                translation: armenian
            }
        },
        backend: {
            loadPath: "/assets/locales/{{lng}}/translation.json",
        },
    });

export default i18next;
