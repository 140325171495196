import CheckoutLayout from "./CheckoutLayout";
import "./style.css";
import Button from "../../../components/Button/Button";
import {ErrorMessage, Field, Form, Formik} from "formik";
import ValidationSchema from "../../../utils/ValidationSchema";
import Checkbox from "../../../components/Checkbox/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useState} from "react";
import InputMask from 'react-input-mask';
import {BaseUrl, loginModalInfo, registerBeforeCheckout} from "../../../redux/actions/newActions/newActions";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {use} from "i18next";
import currency from "../../../Helpers/currency";
import parsePhoneNumber from 'libphonenumber-js'
import Cookies from "js-cookie";

const CheckoutPlaceOrder = () => {
    const trans = useSelector(state => state.newReducer.trans)
    const theme = useSelector(state => state.newReducer.themeChange)
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token')
    const {orderAddresSchema, orderSchema} = ValidationSchema();
    const delivery = 0;
    const lang = Cookies.get("i18next") || "en";
    const basketTotalPrice = useSelector(state => state.newReducer.basketTotalPrice);
    const basketItems = useSelector(state => state.newReducer.modalShowBasket);
    const basketSelected = JSON.parse(localStorage.getItem('basketSelected'))
    const [cartData, setCartData] = useState([]);
    const [checked, setChecked] = useState(false)
    const [checkedError, setCheckedError] = useState('')
    const [userInfo, setUserInfo] = useState({
        name: user?.name || '',
        surname: user?.surname || '',
        email: user?.email || '',
        phone: user?.phone || '',
        message: '',
        country: user?.country || '',
        region: user?.region || '',
        locality: '',
        address: user?.address || '',
    })
    const [surnameError, setSurnameError] = useState('')
    const [addressError, setAddressError] = useState('')
    const [nameError, setNameError] = useState('')
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [regionError, setRegionError] = useState('');
    const [localityError, setLocalityError] = useState('');
    const handleChange = useCallback((key) => (ev) => {
        setNameError('')
        setPhoneError('')
        setSurnameError('')
        setCountryError('')
        setRegionError('')
        setLocalityError('')
        setAddressError('')
        setUserInfo({
            ...userInfo,
            [key]: ev.target.value,
        });
    }, [userInfo]);
    const handleChangePhone = useCallback((phone, country) => {
        setUserInfo({
            ...userInfo,
            phone: phone
        })
        setPhoneError('')
    }, [userInfo])
    useEffect(() => {
        const updatedCartData = basketItems.filter((e) => basketSelected?.includes(e.id)).map((e) => {
            return {
                product_id: e.product.id,
                product_count: e.product_count
            }
        })
        setCartData(updatedCartData);
    }, [basketItems]);
    const handleCheckoutOrder = useCallback(async () => {
        setCheckedError('')
        const {payload} = await dispatch(registerBeforeCheckout({...userInfo, cart: cartData, token}))
        if (payload?.status === 'error') {
            if (payload?.message === 'Token is Expired') {
                dispatch(loginModalInfo(true))
            }
            if (payload?.messages) {
                if (payload?.messages.surname) {
                    setSurnameError(t("validations.surname_required"));
                } else {
                    setSurnameError('');
                }

                if (payload?.messages.address) {
                    setAddressError(t("validations.address_required"));
                } else {
                    setAddressError('');
                }
                if (payload?.messages.name) {
                    setNameError(t("validations.name_required"));
                } else {
                    setNameError('');
                }
                if (payload?.messages.country) {
                    setCountryError(t("validations.country_required"));
                } else {
                    setCountryError('');
                }
                if (payload?.messages.locality) {
                    setLocalityError(t("validations.locality_required"));
                } else {
                    setLocalityError('');
                }
                if (payload?.messages.region) {
                    setRegionError(t("validations.region_required"));
                } else {
                    setRegionError('');
                }
                if (payload?.messages.phone) {
                    setPhoneError(t("validations.invalid_phone_r"));
                } else {
                    setPhoneError('');
                }
            }
        }
        if (payload.redirectURL) {
            localStorage.setItem('myCart', JSON.stringify(cartData))
            localStorage.setItem('user', JSON.stringify({...user, ...userInfo}))
            window.location.replace(payload.redirectURL)
        }
    }, [userInfo, cartData])
    return (
        <CheckoutLayout>
            <div className='checkoutPlaceOrderContainer container'>
                <div className='checkoutPlaceOrderFormsContainer'>
                    <div className='checkoutPlaceOrderPerosnalInfoFormContainer'>
                        <Formik
                            initialValues={{
                                first_name: "",
                                email: "",
                                phone: "",
                                message: "",
                                surname: ""
                            }}
                            onSubmit={(values, actions) => {
                                // actions.setSubmitting(false);
                            }}
                            validationSchema={orderSchema}>
                            {() => (
                                <Form style={{
                                    background: theme === 'ligth' ? '#fff' : ''
                                }} className='checkoutPlaceOrderForm1'>
                                    <p style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} className='checkoutPlaceOrderTitle'>
                                        {trans.length > 0 ? trans.filter((e)=>e.key === 'Personal_info')[0][`value_${lang}`]:t("personal_info")}
                                    </p>
                                    <div className='checkoutPlaceOrderFormGroupContainer'>
                                        <div className='checkoutPlaceOrderFormGroup'>
                                            <label style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }} htmlFor='first_name'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Name')[0][`value_${lang}`]:t('name')} *</label>
                                            <div className='checkoutPlaceOrderFormGroupInput'>
                                                <input
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        borderBottom: theme === 'ligth' ? '1px solid #000' : ''
                                                    }}
                                                    defaultValue={user?.name || userInfo.name}
                                                    value={userInfo.name}
                                                    // readOnly={!!user?.name}
                                                    onChange={handleChange('name')}
                                                    type='text'
                                                    name='first_name'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_name')[0][`value_${lang}`]:t('placeholder.enter_your_name')}
                                                />
                                            </div>
                                            {userInfo.name ? null : <ErrorMessage
                                                name='first_name'
                                                component='p'
                                                className='authError'
                                            />}
                                            {nameError && <p className='authError'>{nameError}</p>}
                                        </div>
                                        <div className='checkoutPlaceOrderFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }} htmlFor='surname'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Surname')[0][`value_${lang}`]:t('surname')} *</label>
                                            <div className='checkoutPlaceOrderFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        borderBottom: theme === 'ligth' ? '1px solid #000' : ''

                                                    }}
                                                    defaultValue={user?.surname || userInfo.surname}
                                                    value={userInfo.surname}
                                                    // readOnly={!!user?.surname}
                                                    onChange={handleChange('surname')}
                                                    type='text'
                                                    name='surname'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_surname')[0][`value_${lang}`]:t('placeholder.enter_your_surname')}
                                                />
                                            </div>
                                            {userInfo.surname ? null : <ErrorMessage
                                                name='surname'
                                                component='p'
                                                className='authError'
                                            />}
                                            {surnameError && <p className='authError'>{surnameError}</p>}
                                        </div>
                                    </div>
                                    <div className='checkoutPlaceOrderFormGroupContainer'>
                                        <div className='checkoutPlaceOrderFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }} htmlFor='email_address'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Email_Address')[0][`value_${lang}`]:t('email_address')} *</label>
                                            <div className='checkoutPlaceOrderFormGroupInput'>
                                                <input
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        borderBottom: theme === 'ligth' ? '1px solid #000' : ''
                                                    }}
                                                    value={user?.email || userInfo.email}
                                                    readOnly={!!user?.email}
                                                    onChange={handleChange('email')}
                                                    type='text'
                                                    name='email_address '
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_email_address')[0][`value_${lang}`]:t('placeholder.enter_your_email_address')}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name='email_address'
                                                component='p'
                                                className='authError'
                                            />
                                        </div>
                                        <div className='checkoutPlaceOrderFormGroup'>
                                            <label style={{
                                                color: theme === 'ligth' ? '#000' : '',
                                            }} htmlFor='phone'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_Number')[0][`value_${lang}`]:t('phone_number')} *</label>
                                            <div style={{
                                                marginTop: 17
                                            }} className='checkoutPlaceOrderFormGroupInput'>
                                                <PhoneInput
                                                    // defaultValue={user?.phone || userInfo.phone}
                                                    // value={user?.phone || userInfo.phone}
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_phone_number')[0][`value_${lang}`]:t('placeholder.enter_your_phone_number')}
                                                    inputClass={`custom-phone-input ${theme === 'ligth' ? 'ligth' : ''}`}
                                                    country={'am'}
                                                    inputStyle={{
                                                        color: theme === 'ligth' ? '#000 !important' : '',
                                                    }}
                                                    value={userInfo.phone.toString()}
                                                    onChange={(value, country) => handleChangePhone(value, country)}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name='phone'
                                                component='p'
                                                className='authError'
                                            />
                                            {phoneError && <p className='authError'>{phoneError}</p>}

                                        </div>
                                    </div>
                                    <div className='checkoutPlaceOrderFormGroup checkoutPlaceOrderFormGroupMessage'>
                                        <label
                                            style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }} htmlFor='message'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Message')[0][`value_${lang}`]:t('message')}</label>
                                        <div className='checkoutPlaceOrderFormGroupInput'>
                                            <input
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : '',
                                                    borderBottom: theme === 'ligth' ? '1px solid #000' : ''
                                                }}
                                                onChange={handleChange('message')}
                                                type='text'
                                                name='message'
                                                placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_message')[0][`value_${lang}`]:t('placeholder.enter_your_your_message')}
                                            />
                                        </div>
                                        <ErrorMessage
                                            name='message'
                                            component='p'
                                            className='authError'
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className='checkoutPlaceOrderShippingAddressFormContainer'>
                        <Formik
                            initialValues={{
                                country: "",
                                city: "",
                                address: "",
                                region: "",
                                locality: ""
                            }}
                            onSubmit={(values, actions) => {
                                actions.setSubmitting(false);
                            }}
                            validationSchema={orderAddresSchema}>
                            {() => (
                                <Form className='checkoutPlaceOrderForm2'>
                                    <p style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} className='checkoutPlaceOrderTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Shipping Address')[0][`value_${lang}`]:t('Shipping Address')}</p>
                                    <div className='checkoutPlaceOrderFormGroupContainer'>
                                        <div className='checkoutPlaceOrderFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }}
                                                htmlFor='country'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Country/Region')[0][`value_${lang}`]:t('Country/Region')} *</label>
                                            <div className='checkoutPlaceOrderFormGroupInput'>
                                                <input
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        borderBottom: theme === 'ligth' ? '1px solid #000' : '',
                                                    }}
                                                    defaultValue={user?.country || userInfo.country}
                                                    value={userInfo.country}
                                                    // readOnly={!!user?.country}
                                                    type='text'
                                                    name='country'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter your country')[0][`value_${lang}`]:t('placeholder.enter_your_country')}
                                                    onChange={handleChange('country')}
                                                />
                                            </div>
                                            {userInfo.country ? null : <ErrorMessage
                                                name='country'
                                                component='p'
                                                className='authError'
                                            />}
                                            {countryError && <p className='authError'>{countryError}</p>}

                                        </div>
                                        <div className='checkoutPlaceOrderFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }}
                                                htmlFor='region'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Region / District')[0][`value_${lang}`]:t('Region / District')} *
                                            </label>
                                            <div className='checkoutPlaceOrderFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        borderBottom: theme === 'ligth' ? '1px solid #000' : ''
                                                    }}
                                                    defaultValue={user?.region || userInfo.region}
                                                    value={userInfo.region}
                                                    // readOnly={!!user?.region}
                                                    type='text'
                                                    name='region'
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter your region')[0][`value_${lang}`]:t('placeholder.enter_your_region')}
                                                    onChange={handleChange('region')}
                                                />
                                            </div>
                                            {userInfo.region ? null : <ErrorMessage
                                                name='region'
                                                component='p'
                                                className='authError'
                                            />}
                                            {regionError && <p className='authError'>{regionError}</p>}

                                        </div>
                                    </div>
                                    <div className='checkoutPlaceOrderFormGroupContainer'>
                                        <div className='checkoutPlaceOrderFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }} htmlFor='address'>{t("Address ")} *</label>
                                            <div className='checkoutPlaceOrderFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        borderBottom: theme === 'ligth' ? '1px solid #000' : ''
                                                    }}
                                                    defaultValue={user?.address || userInfo.address}
                                                    value={userInfo.address}
                                                    // readOnly={!!user?.address}
                                                    onChange={handleChange('address')}
                                                    type='text'
                                                    name='address'
                                                    placeholder={t('placeholder.enter_your_address')}
                                                />
                                            </div>
                                            {userInfo.address ? null : <ErrorMessage
                                                name='address'
                                                component='p'
                                                className='authError'
                                            />}
                                            {addressError && <p className='authError'>{addressError}</p>}
                                        </div>
                                        <div className='checkoutPlaceOrderFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? '#000' : ''
                                                }} htmlFor='locality'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Locality')[0][`value_${lang}`]:t('Locality')}</label>
                                            <div className='checkoutPlaceOrderFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? '#000' : '',
                                                        borderBottom:theme === 'ligth' ? '1px solid #000' : ''
                                                    }}
                                                    value={userInfo.locality}
                                                    // readOnly={!!user?.location}
                                                    type='text'
                                                    name='locality'
                                                    onChange={handleChange('locality')}
                                                    placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter your locality')[0][`value_${lang}`]:t('placeholder.enter_your_locality')}
                                                />
                                            </div>
                                            {userInfo.locality ? null : <ErrorMessage
                                                name='locality'
                                                component='p'
                                                className='authError'
                                            />}
                                            {localityError && <p className='authError'>{localityError}</p>}

                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div style={{
                    background:theme === 'ligth' ? '#fff' : ''
                }} className='orderSummaryContainer'>
                    <div className='orderSummaryTitleContainer'>
                        <p style={{
                            color:theme === 'ligth' ? '#000' : ''
                        }} className='orderSummaryTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Order_Summary')[0][`value_${lang}`]:t('Order Summary')}</p>
                    </div>
                    <div className='orderSubtotalContainer'>
                        <div className='subtotal'>
                            <p style={{
                                color:theme === 'ligth' ? '#000' : ''
                            }} className='subtotalTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Subtotal')[0][`value_${lang}`]:t('Subtotal')}</p>
                            <p style={{
                                color:theme === 'ligth' ? '#000' : ''
                            }} className='subtotalDescription'>{currency} {basketTotalPrice}</p>
                        </div>
                        {/*<div className='subtotal'>*/}
                        {/*    <p className='subtotalTitle'>{t('Delivery')}</p>*/}
                        {/*    <p className='subtotalDescription'>{currency} {delivery}</p>*/}
                        {/*</div>*/}
                    </div>
                    <div style={{
                        background:theme === 'ligth' ? '#000' : ''

                    }} className='orderSummaryLine'></div>
                    <div className='totalContainer'>
                        <p  style={{
                            color:theme === 'ligth' ? '#000' : ''
                        }} className='subtotalTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Total')[0][`value_${lang}`]:t('Total')}</p>
                        <p  style={{
                            color:theme === 'ligth' ? '#000' : ''
                        }} className='subtotalDescription'>
                            {currency} {basketTotalPrice + delivery}
                        </p>
                    </div>
                    <div style={{
                        background:theme === 'ligth' ? '#000' : ''
                    }} className='orderSummaryLine'></div>
                    <Checkbox
                        border={true}
                        checked={checked}
                        onChange={(e) => {
                            setCheckedError('')
                            setChecked(e.target.checked)
                        }}
                        parent='terms'
                        text={t('requaire')}
                        uniqueId={"termsCheckbox"}
                    />
                    {checkedError && <p className='authError'>{checkedError}</p>}
                    <Button
                        text={t('Checkout')}
                        onClick={checked ? handleCheckoutOrder : () => setCheckedError(t('checkError'))}
                        // icon={PLUS}
                        // alt='Plus'
                        link={true}
                        // to='/checkout/place-order'
                        className='checkoutContinueBtn'
                        style={{
                            background: "var(--main-color-pink)",
                            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.04)",
                            border: "none",
                            color: "var(--secondary-color-white)",
                            fontFamily: "Poppins-600, sans-serif",
                            fontWeight: "600",
                            textDecoration: "none",
                            display: "block",
                            textAlign: "center",
                        }}
                    />
                </div>
            </div>
        </CheckoutLayout>
    );
};

export default CheckoutPlaceOrder;
