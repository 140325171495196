import ARROW from "../../assets/icons/arrow-left-pink.svg";
import PERSON from "../../assets/person-images/1.jpg";
import Img from "../../components/Img";
import LINK from "../../assets/icons/link-pink.svg";
import LINKEDIN from "../../assets/icons/linked-in-pink.svg";
import TWITTER from "../../assets/icons/twitter-pink.svg";
import FACEBOOK from "../../assets/icons/facebook-pink.svg";
import IMG_3 from "../../assets/ui-fake-images/blog-1.jpg";
import "./style.css";
import {Link, useParams} from "react-router-dom";
import Button from "../../components/Button/Button";
import Title from "../../components/Title/Title";
import Slider from "../../components/Slider/Slider";
import Footer from "../../components/Footer/Footer";
import SingleBlogBox from "../../components/SingleBlogBox/SingleBlogBox";
import {useEffect, useState} from "react";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useDispatch, useSelector} from "react-redux";
import {fetchPost} from "../../redux/actions/postActions";
import Spinner from "../../components/Spinner/Spinner";
import {STORAGE_URL} from "../../services/apiService";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import moment from "moment";

const Article = () => {
    const theme = useSelector(state => state.newReducer.themeChange)
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const {width} = useWindowSize();
    const [showArrowBtns, setShowArrowBtns] = useState(false);
    const trans = useSelector(state => state.newReducer.trans)
    useEffect(() => {
        const sliderContainer = document.querySelector(".slider ");
        const items = document.querySelectorAll(".blogItem");
        const gap = 32;
        let totalWidth = 0;
        if (sliderContainer && items) {
            items.forEach(img => {
                totalWidth += img.offsetWidth + gap;
            });
            setShowArrowBtns(totalWidth > sliderContainer.offsetWidth);
        }
    }, [width]);

    const {blogName} = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPost(blogName));
    }, [dispatch, blogName]);
    const post = useSelector(state => state.post);
    const handleGoBack = () => {
        window.history.back()
    }
    if (post.post === null || post.loading)
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        );
    return (
        <div>
            {post.post && (
                <>
                    <div className='articleContainer'>
                        <div className='articleBackBtnContainer'>
                            <div style={{cursor: "pointer", color: theme === 'ligth' ? '#000' : ''}}
                                 onClick={handleGoBack} className='articleBackBtn'>
                                <div>
                                    <Img src={ARROW} alt='Arrow'/>
                                </div>
                                {trans.length > 0 ? trans.filter((e)=>e.key === 'back')[0][`value_${lang}`] : t("back")}
                            </div>
                        </div>
                        {width < 600 && (
                            <div className='articleAuthorMedias'>
                                {/*<Link target='_blank' to={post.post.post.custom_link}>*/}
                                {/*    <Img src={LINK} alt={"Custom"}/>*/}
                                {/*</Link>*/}
                                {/*<Link target='_blank' to={post.post.post.linkedin_link}>*/}
                                {/*    <Img src={LINKEDIN} alt={"Linkedin"}/>*/}
                                {/*</Link>*/}
                                {/*<Link target='_blank' to={post.post.post.twitter_link}>*/}
                                {/*    <Img src={TWITTER} alt={"Twitter"}/>*/}
                                {/*</Link>*/}
                                <Link style={{
                                    background: theme === 'ligth' ? '#000' : ''
                                }} target='_blank' to={post.post.post.facebook_link}>
                                    <Img src={FACEBOOK} alt={"Facebook"}/>
                                </Link>
                            </div>
                        )}
                        <div className='article'>
                            <p style={{
                                color: theme === 'ligth' ? '#000' : ''
                            }} className='articleTitle'>{post.post.post[`title_${lang}`]}</p>
                            <div className='articleAuthorContainer'>
                                <div className='articleAuthor'>
                                    <div className='articleAuthorImg'>
                                        <Img
                                            src={`${STORAGE_URL}/${post.post.post.author_image}`}
                                            alt='Person'
                                        />
                                    </div>
                                    <div className='articleAuthorNameContainer'>
                                        <p style={{
                                            color: theme === 'ligth' ? '#000' : ''
                                        }} className='articleAuthorName'>
                                            {post.post.post.author_name}{" "}
                                            {post.post.post.author_surname}
                                        </p>
                                        <p style={{
                                            color: theme === 'ligth' ? '#000' : ''
                                        }} className='articleAuthorDate'>
                                            {moment(post.post.post.created_date).format('DD MMM YYYY')} <span style={{
                                            background: theme === 'ligth' ? '#000' : ''
                                        }}
                                                                                                              className='articleAuthorDateDot'></span>
                                            5 min read
                                        </p>
                                    </div>
                                </div>
                                {width > 600 && (
                                    <div className='articleAuthorMedias'>

                                        {/*<Link target="_blank" to={post.post.post.custom_link}>*/}
                                        {/*    <Img src={LINK} alt={"Custom"}/>*/}
                                        {/*</Link>*/}
                                        {/*<Link target="_blank" to={post.post.post.linkedin_link}>*/}
                                        {/*    <Img src={LINKEDIN} alt={"Linkedin"}/>*/}
                                        {/*</Link>*/}
                                        {/*<Link target="_blank" to={post.post.post.twitter_link}>*/}
                                        {/*    <Img src={TWITTER} alt={"Twitter"}/>*/}
                                        {/*</Link>*/}
                                        <Link style={{
                                            background: theme === 'ligth' ? '#000' : ''
                                        }} target='_blank' to={post.post.post.facebook_link}>
                                            <Img src={FACEBOOK} alt={"Facebook"}/>
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <Img
                                style={{width: '100%', borderRadius: 16}}
                                src={`${STORAGE_URL}/${post.post.post.image}`}
                                alt='Image'
                            />
                            <div className='articleDescriptionContainer'>
                                <p style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} className='articleDescription'
                                   dangerouslySetInnerHTML={{__html: post.post.post[`description_${lang}`]}}/>
                            </div>
                            <div style={{
                                background: theme === 'ligth' ? '#000' : ''
                            }} className='articleLine'></div>
                            <div  className='articleTagsContainer'>
                                <p style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} className='articleTagsTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Tags')[0][`value_${lang}`] : t('tags')} :</p>
                                <div className='articleTags'>
                                    {post.post.post.tags.map((tag, i) => (
                                        <div className='singleBlogBoxBtn' key={i}>
                                            <Button text={tag[`title_${lang}`]}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='portfolioSliderContainer container'>
                        {trans.filter((e) => e.key === 'related_posts').map((e) => (
                            <Title title={e[`value_${lang}`]}/>
                        ))}
                        <Slider className='portfolioSlider' showArrowBtns={showArrowBtns}>
                            {post?.post?.related_posts?.map((blog, i) => (
                                <div className='blogItem' key={i}>
                                    <SingleBlogBox
                                        isTest={theme === 'ligth'}
                                        tags={blog.tags}
                                        className='blogSectionBlog'
                                        size='small'
                                        style={{
                                            flexDirection: "column",
                                            gap: "16px",
                                            width: width < 800 ? 200 : ''
                                        }}
                                        name={blog[`title_${lang}`]}
                                        description={removeHtmlTags(blog[`description_${lang}`])}
                                        // btnText='Interior Design'
                                        img={`${STORAGE_URL}/${blog.image}`}
                                        to={`/${lang}/blog/${blog.id}`}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <Footer/>
                </>
            )}
        </div>
    );
};

export default Article;
