import PERSON from "../../../assets/person-images/1.svg";
import ORDERS from "../../../assets/icons/orders-tick-pink.svg";
import HEART from "../../../assets/icons/heart-pink.svg";
import LOCATION from "../../../assets/icons/location-pink.svg";
import PEN from "../../../assets/icons/pen-white.svg";
import "./style.css";
import {Link, NavLink, useNavigate} from "react-router-dom";
import AccountLayout from "../AccountLayout";
import {useTranslation} from "react-i18next";
import ARROW from "../../../assets/icons/arrow-right-gray.svg";
import Img from "../../../components/Img";
import Cookies from "js-cookie";
import {STORAGE_URL} from "../../../services/apiService";
import {useSelector} from "react-redux";

const ControlPanel = () => {
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate()
    const theme = useSelector(state => state.newReducer.themeChange)
    const trans = useSelector(state => state.newReducer.trans)
    const handleNavigate = (point) => {
        navigate(`/${lang}/account/${point}`)
    }
    return (
        <AccountLayout>
            <div className='controlPanelContainer'>
                <div style={{
                    background: theme === 'ligth' ? 'rgb(239, 239, 239)' : ''
                }} className='controlPanelWaypoint'>
                    <div className='controlPanelWaypointOverview controlPanelWaypointOverviewPerson'>
                        <div className='controlPanelWaypointOverviewIcon'>
                            {user?.image ? <img
                                    src={user?.image?.startsWith('https:') ? user.image : `${STORAGE_URL}${user?.image}`}/> :
                                <img src={PERSON} alt='Person'/>}
                        </div>
                        <Link style={{
                            background: theme === 'ligth' ? '#fff' : ''
                        }} to={`/${lang}/account/settings`} className='controlPanelPen'>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.3601 3.07866L14.2869 2.15178C15.8226 0.616074 18.3125 0.616074 19.8482 2.15178C21.3839 3.68748 21.3839 6.17735 19.8482 7.71306L18.9213 8.63993M13.3601 3.07866C13.3601 3.07866 13.4759 5.04828 15.2138 6.78618C16.9517 8.52407 18.9213 8.63993 18.9213 8.63993M13.3601 3.07866L4.83882 11.5999C4.26166 12.1771 3.97308 12.4656 3.7249 12.7838C3.43213 13.1592 3.18114 13.5653 2.97634 13.995C2.80273 14.3593 2.67368 14.7465 2.41556 15.5208L1.32181 18.8021M18.9213 8.63993L10.4001 17.1612C9.82294 17.7383 9.53436 18.0269 9.21616 18.2751C8.84082 18.5679 8.43469 18.8189 8.00498 19.0237C7.6407 19.1973 7.25352 19.3263 6.47918 19.5844L3.19792 20.6782M3.19792 20.6782L2.39584 20.9456C2.01478 21.0726 1.59466 20.9734 1.31063 20.6894C1.0266 20.4053 0.927428 19.9852 1.05445 19.6042L1.32181 18.8021M3.19792 20.6782L1.32181 18.8021"
                                    stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5"/>
                            </svg>
                        </Link>
                    </div>
                    <div className='controlPanelInfo'>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} className='controlPanelTitle'>
                            {user?.name || ""} {user?.surname || ""}
                        </p>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} className='controlPanelDescription'>{user?.email}</p>
                    </div>
                </div>
                <div onClick={() => handleNavigate('orders')} style={{
                    cursor: 'pointer',
                    background: theme === 'ligth' ? 'rgb(239, 239, 239)' : ''
                }}
                     className='controlPanelWaypoint'>
                    <div className='controlPanelWaypointOverview'>
                        <div style={{
                            background: theme === 'ligth' ? '#fff' : ''
                        }} className='controlPanelWaypointOverviewIcon'>
                            <img src={ORDERS} alt='Orders'/>
                        </div>
                        {/* <div className='controlPanelPen'>
              <img src={PEN} alt='Pen' />
            </div> */}
                    </div>
                    <div className='controlPanelInfo'>
                        <p style={{
                            color:theme === 'ligth' ? '#000' : ''
                        }} className='controlPanelTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Orders')[0][`value_${lang}`] : t("orders")}</p>
                        <p className='controlPanelDescription'>
                            {/*{t("next_purchase")} : <span> is not expected</span>*/}
                        </p>
                    </div>
                </div>
                <div style={{cursor: 'pointer',background: theme === 'ligth' ? 'rgb(239, 239, 239)' : ''}} onClick={() => navigate(`/${lang}/interior-design`)}
                     className='controlPanelWaypoint'>
                    <div className='controlPanelWaypointOverview'>
                        <div className='controlPanelWaypointOverviewIcon'>
                            <img src={HEART} alt='Heart'/>
                        </div>
                        {/* <div className='controlPanelPen'>
              <img src={PEN} alt='Pen' />
            </div> */}
                    </div>
                    <div className='controlPanelInfo'>
                        <p style={{
                            color:theme === 'ligth'  ? '#000' : ''
                        }} className='controlPanelTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Interior_Design')[0][`value_${lang}`] :t("design")}</p>
                        <p className='controlPanelDescription'>
                            {/*{t("next_purchase")} : <span> is not expected</span>*/}
                        </p>
                    </div>
                </div>
                <div style={{cursor: "pointer",background: theme === 'ligth' ? 'rgb(239, 239, 239)' : ''}} onClick={() => navigate(`/${lang}/customcraft`)}
                     className='controlPanelWaypoint'>
                    <div className='controlPanelWaypointOverview'>
                        <div className='controlPanelWaypointOverviewIcon'>
                            <img src={HEART} alt='Heart'/>
                        </div>
                        {/* <div className='controlPanelPen'>
              <img src={PEN} alt='Pen' />
            </div> */}
                    </div>
                    <div className='controlPanelInfo'>
                        <p  style={{
                            color:theme === 'ligth'  ? '#000' : ''
                        }} className='controlPanelTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Custom_furniture_production')[0][`value_${lang}`] :t("crafted")}</p>
                        <p className='controlPanelDescription'>
                            {/*{t("next_purchase")} : <span> is not expected</span>*/}
                        </p>
                    </div>
                </div>
                <div style={{
                    background: theme === 'ligth' ? 'rgb(239, 239, 239)' : ''
                }} className='controlPanelWaypoint'>
                    <div className='controlPanelWaypointOverview'>
                        <div className='controlPanelWaypointOverviewIcon'>
                            <img src={LOCATION} alt='Location'/>
                        </div>
                        {/* <div className='controlPanelPen'>
              <img src={PEN} alt='Pen' />
            </div> */}
                    </div>
                    <div className='controlPanelInfo'>
                        <p style={{
                            color:theme === 'ligth'  ? '#000' : ''
                        }} className='controlPanelTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Addresses')[0][`value_${lang}`] :t("address")}</p>
                        <Link
                            style={{
                                color: theme === 'ligth' ? '#000' : ''
                            }}
                            to={`/${lang}/account/address`}
                            className='controlPanelDescription controlPanelDescriptionLink'>
                            {trans.length > 0 ? trans.filter((e)=>e.key === 'Check_your_addresses')[0][`value_${lang}`] :t("check_your_address")}
                            <svg width="6" height="12" viewBox="0 0 6 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.83438 5.85312C5.83476 6.04783 5.76694 6.23653 5.64271 6.38646L1.47604 11.3865C1.3346 11.5566 1.13134 11.6637 0.91098 11.684C0.690625 11.7043 0.471224 11.6362 0.301043 11.4948C0.130863 11.3533 0.0238427 11.1501 0.00352627 10.9297C-0.0167902 10.7094 0.051261 10.49 0.19271 10.3198L3.92604 5.85312L0.326044 1.38646C0.256822 1.30122 0.205129 1.20314 0.173936 1.09785C0.142743 0.99257 0.132665 0.882162 0.144281 0.772971C0.155897 0.663781 0.188977 0.557962 0.241622 0.461598C0.294267 0.365234 0.365438 0.280224 0.451043 0.211456C0.536728 0.135139 0.63725 0.0773399 0.746311 0.0416793C0.855373 0.00601876 0.970623 -0.00673358 1.08484 0.00421897C1.19906 0.0151715 1.30979 0.0495931 1.41009 0.105329C1.51038 0.161065 1.59809 0.236915 1.66771 0.328123L5.69271 5.32812C5.79731 5.48236 5.84719 5.66721 5.83438 5.85312Z"
                                    fill={theme === 'ligth' ? 'black' : ''}/>
                            </svg>

                        </Link>
                    </div>
                </div>
            </div>
        </AccountLayout>
    );
};

export default ControlPanel;
