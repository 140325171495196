import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import ContactLayout from "../ContactLayout/ContactLayout";
import Img from "../Img";
import Map from "../Map";
import LOCATION from "../../assets/icons/location-white.svg";
import {useState} from "react";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const Locations = ({locations, isContact = false}) => {
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const [coords, setCoords] = useState(
        locations.address_2_coordinates.split(",")
    );
    const [isFullScreen, setIsFullScreen] = useState(false)
    const theme = useSelector(state => state.newReducer.themeChange)

    const fixingCoords = coords => coords.map(coord => Number(coord));
    const [addresTitle, setAddressTitle] = useState(
        locations[`address_1_${lang}`]
    );
    const trans = useSelector(state => state.newReducer.trans)
    return (
        <ContactLayout
            pageTitle={locations[`heading_${lang}`]}
            pageDescription={removeHtmlTags(locations[`description_${lang}`])}
            detailsChildren={
                <>
                    <div className='contactDetail'>
                        <div className='contactDetailIcon'>
                            <Img src={LOCATION} alt='Locations'/>
                        </div>
                        <div className='contactDetailInfo'>
                            <p style={{
                                color: theme === 'ligth' ? '#000' : ''
                            }} className='contactDetailInfoDescription'>{t("address")}</p>
                            <p style={{
                                color: theme === 'ligth' ? '#000' : ''
                            }} className='contactDetailInfoTitle'>
                                {locations[`address_1_${lang}`]}
                            </p>
                            <button
                                style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }}
                                className='addressViewMapBtn'
                                onClick={() => {
                                    setIsFullScreen(true)
                                    setCoords(locations.address_1_coordinates.split(","));
                                    setAddressTitle(locations[`address_1_${lang}`]);
                                }}>
                                {trans.length > 0? trans.filter((e)=>e.key==='View Map')[0][`value_${lang}`]:t("view-map")}
                            </button>
                        </div>
                    </div>
                </>
            }>
            <div className='contactMap'>
                <Map setIsFullScreen={setIsFullScreen} isFullScreen={isFullScreen} coords={fixingCoords(coords)}
                     addressTitle={addresTitle}/>
            </div>
        </ContactLayout>
    );
};

export default Locations;
