import AboutCompany from "../components/AboutCompany/AboutCompany";
import Blog from "../components/Blog/Blog";
import Button from "../components/Button/Button";
import FAQ from "../components/FAQ/FAQ";
import Footer from "../components/Footer/Footer";
import FurnitureInfos from "../components/FurnitureInfo/FurnitureInfos";
import MainUI from "../components/MainUI/MainUI";
import Partners from "../components/Partners/Partners";
import Portfolio from "../components/Portfolio/Portfolio";
import Reviews from "../components/Reviews/Reviews";
import BG from "../assets/ui-fake-images/about-company-bg.svg";
import { motion } from "framer-motion";
import { animate, initial } from "../utils/transition";
import { STORAGE_URL } from "../services/apiService";
import { removeHtmlTags } from "../Helpers/removeHtmlTags";
import {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReviews } from "../redux/actions/reviewsActions";
import { fetchPartners } from "../redux/actions/partnersActions";
import { fetchHome } from "../redux/actions/homeActions";
import Spinner from "../components/Spinner/Spinner";
import { fetchFAQ } from "../redux/actions/faqActions";
import Locations from "../components/Locations/Locations";
import { fetchLocations } from "../redux/actions/locationsActions";
import { fetchProductsHome } from "../redux/actions/productsHomeActions";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import {
  getCarers,
  getEmail,
  getFavorites,
  getPhone,
  getTranslations,
  getUserBasket
} from "../redux/actions/newActions/newActions";
import Img from "../components/Img";
import LOCATION from "../assets/icons/location-white.svg";
import TIME from "../assets/icons/time-white.svg";
import {useWindowSize} from "../hooks/useWindowSize";
import SecondButton from "../components/SecondButton/SecondButton";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import ThemeSwitcher from "../components/ThemSwitcher/ThemeSwitcher";

const Home = () => {
  const lang = Cookies.get("i18next") || "en";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {width} = useWindowSize()
  const user = JSON.parse(localStorage.getItem('user'))
  const token = localStorage.getItem('token')
  const translations = useSelector(state => state.newReducer.trans)
  const theme = useSelector(state => state.newReducer.themeChange)

  useEffect(() => {
    dispatch(getTranslations())
    dispatch(fetchReviews());
    dispatch(fetchPartners());
    dispatch(fetchHome());
    dispatch(fetchFAQ());
    dispatch(fetchLocations());
    dispatch(fetchProductsHome());
    if (token){
      dispatch(getUserBasket({id:user?.id , token}))
    }
    if (token){
      dispatch(getFavorites({id:user?.id , token}))
    }
    dispatch(getCarers({page:1}))
  }, [token]);

  const home = useSelector(state => state.home);
  const reviews = useSelector(state => state.reviews);
  const partners = useSelector(state => state.partners);
  const faq = useSelector(state => state.faq);
  const locations = useSelector(state => state.locations);
  const productsHome = useSelector(state => state.productsHome);
  const career_mainData = useSelector(state => state.newReducer.career_mainData)
  if (
    home.loading &&
    home.home === null &&
    reviews.loading &&
    reviews.reviews === null &&
    partners.loading &&
    partners.partners === null &&
    faq.loading &&
    faq.faq === null &&
    locations.locations === null &&
    productsHome.productsHome === null
  )
    return (
      <div className='spinnerContainer'>
        <Spinner />
      </div>
    );
  return (
    <motion.div initial={initial} animate={animate} className='home' id='home'>
      {home.home &&
        reviews.reviews &&
        partners.partners &&
        faq.faq &&
        locations.locations?.locations &&
        productsHome.productsHome && (
              <>
                <Helmet>
                  <title>WOW Effects</title>
                </Helmet>
                {/*<div>*/}
                {/*  <button onClick={()=>{*/}
                {/*    window.gtag('event' , 'click' , {*/}
                {/*      'event_category': 'event category',*/}
                {/*      'event_label': 'event mark',*/}
                {/*      'value': JSON.stringify({*/}
                {/*        productId:10,*/}
                {/*        quantity:5*/}
                {/*      })*/}
                {/*    })*/}
                {/*  }}>*/}
                {/*    click test*/}
                {/*  </button>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <button onClick={()=>{*/}
                {/*    window.ym(96573062,'reachGoal','testBtn')*/}
                {/*  }}>*/}
                {/*    click*/}
                {/*  </button>*/}
                {/*</div>*/}
                <MainUI
                    go_shopping={home.home.go_shopping}
                    slides={home.home.slides}
                />
                <AboutCompany
                    title={home.home.about_section[`heading_${lang}`]}
                    description={removeHtmlTags(
                        home.home.about_section[`description_${lang}`]
                    )}
                    btn={
                      translations.filter((e)=>e.key === 'Read_More').map((e)=>(
                          <Button
                              link={true}
                              to={`/${lang}/about-us`}
                              text={e[`value_${lang}`]}
                              className='aboutCompanyReadMoreBtn'
                              style={{
                                fontFamily: "Poppins-600, sans-serif",
                                fontWeight: "600",
                                textDecoration: "none",
                                color: "var(--secondary-color-white)",
                                background: "var(--main-color-pink)",
                                borderRadius: "8px",
                                display: "block",
                                width: "fit-content",
                              }}
                          />
                      ))
                    }
                    img={`${STORAGE_URL}/${home.home.about_section.image}`}
                    bgImg={BG}
                />
                <Portfolio portfolio_main={home.home.portfolio_main}/>
                <Reviews reviews={reviews.reviews}/>
                <Partners partners={partners.partners}/>
                <FurnitureInfos
                    product_suggestions={productsHome.productsHome.products_in_home}
                />
                <Blog/>
                  <FAQ title={t("faq")} className='mainFAQ' data={faq.faq}/>
                {width > 700 ? <Locations locations={locations.locations?.locations}/>:null}
                {career_mainData[career_mainData.length-1] ? (<div style={{background: theme === 'ligth' ? "#EFEFEF" : 'rgba(255, 255, 255, 0.2)'}} className='hiringContainer'>
                  <div style={{display:'flex' , justifyContent:'space-between' , alignItems:'center' , width:'100%'}}>
                    <p style={{fontSize: 30, color: theme === 'ligth' ? "#000" : "#fff", marginTop: 0 , fontWeight:'regular'}}
                       className='hiringTitle'>{career_mainData[career_mainData.length - 1][`title_${lang}`]}
                    </p>
                    <SecondButton
                        className='moreBtnLarge'
                        to={`/career/${career_mainData[career_mainData.length - 1].id}`}
                    />
                  </div>
                  <p
                      className='hiringDescription'
                      style={{textAlign: 'left',color: theme === 'ligth' ? "#000" : "#fff",}}
                      dangerouslySetInnerHTML={{__html: career_mainData[career_mainData.length - 1][`description_${lang}`]}}
                  />
                  <div className='vacancyInfo' style={{alignItems:'flex-start' , justifyContent:'space-between' , flexDirection: width < 700 ?'column' :'row' , marginLeft:-100}}>
                    <div style={{display: 'flex'}}>
                      <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 9.14329C1 4.64588 4.58172 1 9 1C13.4183 1 17 4.64588 17 9.14329C17 13.6055 14.4467 18.8124 10.4629 20.6744C9.53426 21.1085 8.46574 21.1085 7.53707 20.6744C3.55332 18.8124 1 13.6055 1 9.14329Z"
                            stroke={theme === 'ligth' ? "black" : 'white'} stroke-width="1.5"/>
                        <circle cx="9" cy="9" r="3" stroke={theme === 'ligth' ? "black" : 'white'} stroke-width="1.5"/>
                      </svg>
                      <p style={{
                        color: theme === 'ligth' ? "#000" : "#fff",
                      }}
                         className='hiringDescription__location'>{career_mainData[career_mainData.length - 1][`location_${lang}`]}</p>
                    </div>
                    <div style={{display: 'flex'}}>
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11" cy="11" r="10" stroke={theme === 'ligth' ? "black" : 'white'} stroke-width="1.5"/>
                        <path d="M11 7V11L13.5 13.5" stroke={theme === 'ligth' ? "black" : 'white'} stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                      </svg>
                      <p style={{
                        color: theme === 'ligth' ? "#000" : "#fff",
                      }}>{career_mainData[career_mainData.length - 1].type}</p>
                    </div>
                  </div>
                </div>) : null}
                <Footer send={translations.length > 0 ? translations[0][`value_${lang}`] : ''} slider={false}/>
              </>
          )}
    </motion.div>
  );
};

export default Home;
