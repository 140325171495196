import {Outlet, useLocation} from "react-router-dom";
import BottomHeader from "./BottomHeader/BottomHeader";
import {TopHeader} from "./TopHeader/TopHeader";
import Basket from "./Basket/Basket";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Favorites from "./Favorites/Favorites";

const Layout = () => {
    const location = useLocation();
    const hideHeadersPaths = ["/sign-in", "/sign-up"];
    const shouldHideHeaders = hideHeadersPaths.includes(location.pathname);
    return (
        <div>
            {!shouldHideHeaders && <TopHeader/>}
            {!shouldHideHeaders && <BottomHeader/>}
            <Basket/>
            <Favorites/>
            <div>
                <Outlet/>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    );
};

export default Layout;
