import "./style.css";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {useSelector} from "react-redux";

const Checkbox = ({text, className, uniqueId, onChange, checked,parent='',border}) => {
    const navigate = useNavigate()
    const lang = Cookies.get("i18next") || "en";
    const theme = useSelector(state => state.newReducer.themeChange)

    return (
        <div className='checkboxContainer'>
            <div  className='checkbox-wrapper-23'>
                <input
                    type='checkbox'
                    id={uniqueId}
                    onChange={onChange}
                    checked={checked}
                />
                <label  style={{
                    color: theme === 'ligth' ? '#000' : '',
                    background:theme === 'ligth' ? '#EFEFEF' : '',
                    border:theme === 'ligth' && border ? '1px solid black' : ''
                }} htmlFor={uniqueId}>
                    <svg viewBox='0,0,50,50'>
                        <path d='M5 30 L 20 45 L 45 5'></path>
                    </svg>
                </label>
            </div>
            {text && (
                <label
                    style={{
                        color: theme === 'ligth' ? '#000' : ''
                    }}
                    onClick={() => parent === 'terms' ?  navigate(`/${lang}/privacy-policy?tab=policy`) : null}
                    className='checkboxTitle'
                    // for="check-23"
                    htmlFor={uniqueId}
                    dangerouslySetInnerHTML={{__html: text}}>
                </label>
            )}
        </div>
    );
};

export default Checkbox;
