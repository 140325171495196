import { useRef } from "react";
import ARROW_LEFT from "../../assets/icons/arrow-left-white.svg";
import ARROW_RIGHT from "../../assets/icons/arrow-right-white.svg";
import "./style.css";
import { useWindowSize } from "../../hooks/useWindowSize";
import {useSelector} from "react-redux";

const Slider = ({ children, className, showArrowBtns , size }) => {
  const sliderRef = useRef(null);
  const scrollAmount = 320;
  const { width } = useWindowSize();
  const theme = useSelector(state => state.newReducer.themeChange)

  return (
    <div className={`${className} slider`}>
      {showArrowBtns && (
        <button
            style={{
              background:theme === 'ligth' ? '#fff' : "",
              border:theme === 'ligth' ? '1px solid black' : ''
            }}
          className='sliderBtn sliderLeftBtn'
          onClick={() => {
            const container = sliderRef.current;
            container.scrollLeft -= scrollAmount;
          }}>
          <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33073 1.2207L1.66406 8.99848L8.33073 16.7763" stroke={theme === 'ligth' ? "black" : 'white'} stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      )}
      {width < 797 ? (
          className !== "partnersSlider" && (
              <div className='sliderContainer' ref={sliderRef}>
                {children}
              </div>
          )
      ) : (
          <div className='sliderContainer' ref={sliderRef}>
          {children}
        </div>
      )}
      {showArrowBtns && (
        <button
            style={{
              background:theme === 'ligth' ? '#fff' : "",
              border:theme === 'ligth' ? '1px solid black' : ''
            }}
          className={`sliderBtn sliderRightBtn ${size === 3 ? 'left1' : '' } `}
          onClick={() => {
            const container = sliderRef.current;
            container.scrollLeft += scrollAmount;
          }}>
          <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.0013 1L7.66797 8.77778L1.0013 16.5556" stroke={theme === 'ligth' ? "black" : 'white'} stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      )}
      {width < 797 && className === "partnersSlider" && (
          <div className='partnersSliderWrapper'>
            <div className='sliderContainer' ref={sliderRef}>
              {children}
            </div>
            <div className='sliderBtns'>
            <button
                style={{
                  background:theme === 'ligth' ? '#fff' : "",
                  border:theme === 'ligth' ? '1px solid black' : ''
                }}
              className='partnerSliderBtn'
              onClick={() => {
                const container = sliderRef.current;
                container.scrollLeft -= scrollAmount;
              }}>
              <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.33073 1.2207L1.66406 8.99848L8.33073 16.7763" stroke={theme === 'ligth' ? "black" : 'white'}
                      stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
              <button
                  style={{
                    background:theme === 'ligth' ? '#fff' : "",
                    border:theme === 'ligth' ? '1px solid black' : ''
                  }}
                  className='partnerSliderBtn'
                  onClick={() => {
                    const container = sliderRef.current;
                    container.scrollLeft += scrollAmount;
                  }}>
                <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.0013 1L7.66797 8.77778L1.0013 16.5556" stroke={theme === 'ligth' ? "black" : 'white'}
                      stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </button>
            </div>
          </div>
      )}
    </div>
  );
};

export default Slider;
