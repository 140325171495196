import {useTranslation} from "react-i18next";
import Img from "../Img";
import Title from "../Title/Title";
import "./style.css";
import {useSelector} from "react-redux";
import layout from "../Layout";
import Cookies from "js-cookie";

const AboutCompany = ({
                          title,
                          description,
                          img,
                          btn,
                          bgImg,
                          className,
                          customer_savings,
                          downloads,
                          hours_saved,
                          year_growth,
                          social_responsibility
                      }) => {
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const translations = useSelector(state => state.newReducer.trans)
    const theme = useSelector(state => state.newReducer.themeChange)

    const capitalFunc = (text)=>{
        if (text) {
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        }
    }
    return (
        <div style={{
            background:theme === 'ligth' ? '#fff' : ''
        }} className={`${className} aboutCompanyContainer`}>
            {bgImg && <Img src={bgImg} alt='Background' className='aboutCompanyBG'/>}
            <div className='aboutCompanyInfo'>
                <Title title={title} className={className ? `${className}Title` : ""}/>
                <p style={{
                    color:theme === 'ligth' ? '#000' : ''
                }} className='aboutCompanyDesc'>{description}</p>
                {customer_savings && downloads && hours_saved && year_growth && social_responsibility && (
                    <div className='aboutCountingNumbers'>
                        <div className='aboutCountingNumber1'>
                            <div className='customerSavings'>
                                <p className='customerSavingsTitle'>{customer_savings}</p>
                                <p
                                    style={{
                                        color:theme === 'ligth' ? 'black' : ''
                                    }}
                                    className='customerSavingsDescription'>
                                    { translations?.length > 0 ? capitalFunc(translations[2][`value_${lang}`]) : null}
                                </p>
                            </div>
                            <div className='hoursSaved'>
                                <p className='customerSavingsTitle'>{hours_saved}</p>
                                <p
                                    style={{
                                        color:theme === 'ligth' ? 'black' : ''
                                    }}
                                    className='customerSavingsDescription'>
                                    {translations?.length > 0 ? capitalFunc(translations[3][`value_${lang}`]) : null}
                                </p>
                            </div>
                        </div>
                        <div className='aboutCountingNumber2'>
                            <div className='customerSavings'>
                                <p className='customerSavingsTitle'>{year_growth}</p>
                                <p
                                    style={{
                                        color:theme === 'ligth' ? 'black' : ''
                                    }}
                                    className='customerSavingsDescription'>
                                    {translations?.length > 0 ? capitalFunc(translations[4][`value_${lang}`]) : null}
                                </p>
                            </div>
                            <div className='hoursSaved'>
                                <p className='customerSavingsTitle'>{downloads}</p>
                                <p
                                    style={{
                                        color:theme === 'ligth' ? 'black' : ''
                                    }}
                                    className='customerSavingsDescription'>
                                    { translations?.length > 0 ?  capitalFunc(translations[5][`value_${lang}`]) : null}
                                </p>
                            </div>
                        </div>
                        <div className='aboutCountingNumber2'>
                            <div className='customerSavings'>
                                    <p className='customerSavingsTitle'>{social_responsibility}</p>
                                    <p
                                        style={{
                                            color:theme === 'ligth' ? 'black' : ''
                                        }}
                                        className='customerSavingsDescription'>
                                        { translations?.length > 0 ?  capitalFunc(translations[5][`value_${lang}`]) : null}
                                    </p>
                            </div>
                        </div>
                    </div>
                )}
                {btn}
            </div>
            {img && (
                <div
                    className='aboutCompanyImg'
                    style={{backgroundImage: `url(${img})`}}></div>
            )}
        </div>
    );
};

export default AboutCompany;
