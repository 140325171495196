import {ErrorMessage, Field, Form, Formik} from "formik";
import IMG_1 from "../../assets/ui-fake-images/house-design-furnishing-1.svg";
import Button from "../../components/Button/Button";
import GMAIL from "../../assets/icons/gmail.svg";
import FACEBOOK from "../../assets/icons/facebook.svg";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import "./style.css";
import {useEffect, useState} from "react";
import PasswordRecovery from "./PasswordRecovery";
import {motion} from "framer-motion";
import ValidationSchema from "../../utils/ValidationSchema";
import {animate, initial} from "../../utils/transition";
import Checkbox from "../../components/Checkbox/Checkbox";
import apiService from "../../services/apiService";
import Spinner from "../../components/Spinner/Spinner";
import Modal from "../../components/Modal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../redux/actions/authActions";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {faceBookLoginRequest, googleLoginRequest, loginModalInfo} from "../../redux/actions/newActions/newActions";
import {useGoogleLogin} from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";

const Auth = ({auth}) => {
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const [showPassword, setShowPassword] = useState(false);
    const localBasket = JSON.parse(localStorage.getItem('localBasket'))
    const localAddFavorites = JSON.parse(localStorage.getItem('localFavorites'))
    const [sendBasket, setSendBasket] = useState([])
    const [sendFavorites, setSendFavorites] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const theme = useSelector(state => state.newReducer.themeChange)
    const trans = useSelector(state => state.newReducer.trans)
    useEffect(() => {
        if (localBasket && localBasket.length) {
            setSendBasket(localBasket.map((e) => {
                return {
                    product_id: e.id,
                    product_count: e.quantity
                }
            }))
        }
        if (localAddFavorites && localAddFavorites.length) {
            setSendFavorites(localAddFavorites.map((e) => {
                return {
                    product_id: e.id
                }
            }))
        }
    }, []);
    const toggleEye = () => {
        setShowPassword(!showPassword);
    };

    const [passwordRecovery, setPasswordRecovery] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [hasNavigated, setHasNavigated] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const {payload} = await dispatch(googleLoginRequest({
                ...tokenResponse,
                cart: sendBasket,
                favorites: sendFavorites
            }))
            if (payload.message === 'User successfully logined' || payload.message === 'User successfully registered') {
                localStorage.removeItem('localBasket')
                localStorage.removeItem('basketSelected')
                localStorage.removeItem('localFavorites')
                localStorage.setItem("token", payload.access_token);
                localStorage.setItem("user", JSON.stringify(payload.user));
                dispatch(loginModalInfo(false))
                navigate(`/${lang}/`)
                dispatch(login());
            }
        },
        onFailure: error => {
            console.error("Google login failed:", error);
        }
    });
    const handleSignIn = async values => {
        setLoading(true);
        setError(null);
        await apiService.post("login", values, {}, ({loading, error, data}) => {
            setLoading(loading);
            setError(error);
            if (!error && data && data.access_token && data.user && !hasNavigated) {
                localStorage.removeItem('localBasket')
                localStorage.removeItem('basketSelected')
                localStorage.removeItem('localFavorites')
                localStorage.setItem("token", data.access_token);
                localStorage.setItem("user", JSON.stringify(data.user));
                dispatch(loginModalInfo(false))
                setHasNavigated(true);
                !hasNavigated && navigate(`/${lang}/`);
                dispatch(login());
            }
            setResponseData(data);
        });
    };

    const {signInSchema} = ValidationSchema();

    return (
        <motion.div initial={initial} animate={animate} className='authContainer'>
            <div className='authFormContainer container'>
                {/*<NavLink to={`/${lang}/`} className='authLogo'>*/}
                {/*  <Img src={LOGO} alt='Wow Logo' className='authLogoImg' />*/}
                {/*</NavLink>*/}
                {passwordRecovery ? (
                    <PasswordRecovery setPasswordRecovery={setPasswordRecovery}/>
                ) : (
                    <motion.div
                        initial={{
                            opacity: 0,
                            x: -100,
                        }}
                        animate={{
                            opacity: 1,
                            x: 0,
                        }}
                        style={{
                            minHeight: '100vh'
                        }}
                    >
                        <div className='authGreeting'>
                            <p style={{
                                color: theme === 'ligth' ? 'black' : ''
                            }} className='authGreetingTitle'>
                                {trans.length>0 ? trans.filter((e)=>e.key === 'HEY_SIGN_IN_TO_YOUR_ACCOUNT')[0][`value_${lang}`] : t("sign_in_greeting1")}
                            </p>
                            <p style={{
                                color: theme === 'ligth' ? 'black' : ''
                            }} className='authGreetingDescription'>
                                {trans.length>0 ? trans.filter((e)=>e.key === 'Welcome_to_Wow_transforming_furniture')[0][`value_${lang}`] :t("sign_in_greeting2")}
                            </p>
                        </div>
                        <Formik
                            initialValues={{
                                email: "",
                                password: "",
                            }}
                            onSubmit={(values, actions) => {
                                actions.setSubmitting(false);
                                handleSignIn({...values, cart: sendBasket, favorites: sendFavorites});
                            }}
                            validationSchema={signInSchema}>
                            {() => (
                                <Form className='authForm'>
                                    <div className='authFormGroup'>
                                        <label style={{
                                            color: theme === 'ligth' ? 'black' : ''
                                        }} htmlFor='email'>{trans.length>0 ? trans.filter((e)=>e.key === 'Email_Address')[0][`value_${lang}`] : t("email_address")}</label>
                                        <div className='authFormGroupInput'>
                                            <Field
                                                style={{
                                                    color: theme === 'ligth' ? 'black' : '',
                                                    background:theme === 'ligth' ? '#F8F8F8' : '',
                                                }}
                                                type='text'
                                                name='email'
                                                placeholder={trans.length>0 ? trans.filter((e)=>e.key === 'Enter_your_email_address')[0][`value_${lang}`] : t("placeholder.enter_your_email_address")}
                                            />
                                        </div>
                                        <ErrorMessage
                                            name='email'
                                            component='p'
                                            className='authError'
                                        />
                                    </div>
                                    <div className='authFormGroup'>
                                        <label style={{
                                            color: theme === 'ligth' ? 'black' : ''
                                        }} htmlFor='password'>{trans.length>0 ? trans.filter((e)=>e.key === 'Password')[0][`value_${lang}`] :t("password")}</label>
                                        <div className='authFormGroupInput'>
                                            <Field
                                                style={{
                                                    color: theme === 'ligth' ? 'black' : '',
                                                    background:theme === 'ligth' ? '#F8F8F8' : ''
                                                }}
                                                type={showPassword ? "text" : "password"}
                                                name='password'
                                                placeholder={trans.length>0 ? trans.filter((e)=>e.key === 'Enter_your_password')[0][`value_${lang}`] :t("placeholder.enter_your_password")}
                                            />
                                            <div
                                                className={`eyeContainer ${
                                                    showPassword ? "eyeOpen" : "eyeClose"
                                                }`}
                                                onClick={toggleEye}>
                        <span className='icon-eye middle-right'>
                          <svg
                              viewBox='-20 -200 320 400'
                              xmlns='http://www.w3.org/2000/svg'
                              xmlnsXlink='http://www.w3.org/1999/xlink'>
                            <g id='eye' strokeWidth='30' fill='none'>
                              <g id='eye-lashes' stroke='currentColor'>
                                <line x1='140' x2='140' y1='90' y2='180'/>
                                <line x1='70' x2='10' y1='60' y2='140'/>
                                <line x1='210' x2='270' y1='60' y2='140'/>
                              </g>
                              <path
                                  id='eye-bottom'
                                  d='m0,0q140,190 280,0'
                                  stroke='currentColor'
                              />
                              <path
                                  id='eye-top'
                                  d='m0,0q140,190 280,0'
                                  stroke='currentColor'
                              />
                              <circle
                                  id='eye-pupil'
                                  cx='140'
                                  cy='0'
                                  r='50'
                                  fill='currentColor'
                                  stroke='none'
                              />
                            </g>
                          </svg>
                        </span>
                                            </div>
                                        </div>
                                        <ErrorMessage
                                            name='password'
                                            component='p'
                                            className='authError'
                                        />
                                    </div>
                                    <div className='authHub'>
                                        <Checkbox
                                            text={trans.length>0 ? trans.filter((e)=>e.key === 'Remember_me')[0][`value_${lang}`] : t("remember_me")}
                                            uniqueId='remember_me'
                                            className='rememberMeHandlerTitle'
                                        />
                                        <div
                                            className='passwordRecovery'
                                            onClick={() => setPasswordRecovery(true)}>
                                            <p style={{
                                                color:theme === 'ligth' ? 'black' : ''
                                            }} className='passwordRecoveryTitle'>
                                                {trans.length>0 ? trans.filter((e)=>e.key === 'Forget_password')[0][`value_${lang}`] :t("forget_password")}
                                            </p>
                                        </div>
                                    </div>
                                    <Button
                                        btnType='submit'
                                        link={false}
                                        text={
                                            loading ? (
                                                <Spinner color='#fff' size={18}/>
                                            ) : (
                                               trans.length > 0 ? trans.filter((e)=>e.key==='Sign_In')[0][`value_${lang}`] :t("bottomHeader.sign-in")
                                            )
                                        }
                                        disabled={loading}
                                        style={{
                                            backgroundColor: "var(--main-color-pink)",
                                            border: "none",
                                            fontFamily: "Poppins-600, sans-serif",
                                            fontWeight: "600",
                                            borderRadius: "var(--main-border-radius)",
                                            color: "var(--secondary-color-white)",
                                        }}
                                        className='bottomHeaderRegisterBtn'
                                    />
                                </Form>
                            )}
                        </Formik>
                        <div className='authCredentials'>
                            {auth === "sign-up" ? (
                                <p className='authCredentialsGreetings'>
                                    Have an account?{" "}
                                    <NavLink to={`/${lang}/sign-in`}>Log In</NavLink>
                                </p>
                            ) : (
                                <p style={{
                                    color:theme === 'ligth' ? 'black' : ''
                                }} className='authCredentialsGreetings'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key==='Don’t_have_an_account?')[0][`value_${lang}`] :t("dont_you_have_account")}{" "}
                                    <NavLink to={`/${lang}/sign-up`}>
                                        {trans.length > 0 ? trans.filter((e)=>e.key==='Sign_Up')[0][`value_${lang}`] :t("bottomHeader.register")}
                                    </NavLink>
                                </p>
                            )}
                            <div className='authCredentialsOr'>
                                <div style={{
                                    background:theme === 'ligth' ? 'black' :''
                                }} className='authCredentialsLine1'></div>
                                <p style={{
                                    color:theme === 'ligth' ? 'black' : ''
                                }}>{trans.length > 0 ? trans.filter((e)=>e.key==='or')[0][`value_${lang}`] :t("or")}</p>
                                <div style={{
                                    background:theme === 'ligth' ? 'black' :''
                                }} className='authCredentialsLine2'></div>
                            </div>
                            <div className='authCredentialsBtns'>
                                <button style={{
                                    color:theme === 'ligth' ? 'black' : '',
                                    background:theme === 'ligth' ? '#F8F8F8' : '',
                                }} onClick={() => googleLogin()}>
                                    <img src={GMAIL} alt='Gmail'/>
                                    Google
                                </button>
                                <FacebookLogin
                                    appId="184481350300786"
                                    onSuccess={async (response) => {
                                        const {payload} = await dispatch(faceBookLoginRequest({
                                            ...response,
                                            cart: sendBasket,
                                            favorites: sendFavorites
                                        }))

                                        if (payload.message === 'User successfully logined' || payload.message === 'User successfully registered') {
                                            localStorage.removeItem('localBasket')
                                            localStorage.removeItem('basketSelected')
                                            localStorage.removeItem('localFavorites')
                                            localStorage.setItem("token", payload.access_token);
                                            localStorage.setItem("user", JSON.stringify(payload.user));
                                            dispatch(loginModalInfo(false))
                                            navigate(`/${lang}/`)
                                            dispatch(login());
                                        }
                                    }}
                                    onFail={(error) => {
                                        console.error('Login Failed!', error);
                                    }}
                                    render={({onClick}) => (
                                        <button style={{
                                            color:theme === 'ligth' ? 'black' : '',
                                            background:theme === 'ligth' ? '#F8F8F8' : '',
                                        }} onClick={onClick}>
                                            <img src={FACEBOOK} alt='Facebook'/>
                                            Facebook
                                        </button>
                                    )}
                                />

                            </div>
                        </div>
                    </motion.div>
                )}
            </div>
            <div
                className='authSecondPart'
                style={{backgroundImage: `url(${IMG_1})`}}></div>
            <Modal
                title=''
                open={error?.response?.data}
                onClose={() => setError(null)}>
                <p className='authResponseMessage'>{t('incorrect')}</p>
            </Modal>
        </motion.div>
    );
};

export default Auth;
