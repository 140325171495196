import Footer from "../../../components/Footer/Footer";
import {motion} from "framer-motion";
import {initial, animate} from "../../../utils/transition";
import Img from "../../../components/Img";
import React, {Fragment, useEffect, useRef, useState} from "react";
import Slider from "../../../components/Slider/Slider";
import Title from "../../../components/Title/Title";
import Product from "../../../components/Product/Product";
import Button from "../../../components/Button/Button";
import SaveButton from "../../../components/SaveButton/SaveButton";
import {useDispatch, useSelector} from "react-redux";
import {addToBasket} from "../../../redux/actions/basketActions";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";
import "./style.css";
import {useNavigate, useParams} from "react-router-dom";
import {
    addBasket,
    addToFavoritesAction,
    getSingleProduct,
    localAddFavorites,
    localBasketAdd,
    localBasketDecrement,
    localBasketIncrement,
    postStoreRequirements,
    productCartDecrement,
    productCartIncrement
} from "../../../redux/actions/newActions/newActions";
import Cookies from "js-cookie";
import apiService, {STORAGE_URL} from "../../../services/apiService";
import Spinner from "../../../components/Spinner/Spinner";
import {removeHtmlTags} from "../../../Helpers/removeHtmlTags";
import currency from "../../../Helpers/currency";
import {toast} from "react-toastify";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Modal from "../../../components/Modal/Modal";
import ValidationSchema from "../../../utils/ValidationSchema";
import Carousel from "nuka-carousel";
import NewSaveButton from "../../../components/NewSaveButton";
import QauntityField from "../../../components/QauntityField/QauntityField";
import PageNotFound from "../../PageNotFound/PageNotFound";
import FAQ from "../../../components/FAQ/FAQ";
import SingleShopAcardion from "../../../components/FAQ/SingleShopAcardion";
import arrow_right from '../../../assets/icons/arrow-right-white.svg'
import arrow_left from '../../../assets/icons/arrow-left-white.svg'


const SingleShop = () => {
    function findIndexById(array, id) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                return i;
            }
        }
        return -1;
    }

    const theme = useSelector(state => state.newReducer.themeChange)
    const {storeRequirements} = ValidationSchema();
    const favoriteProjects = useSelector(state => state.newReducer.favorites)
    const [selectedProductId, setSelectedProductId] = useState('')
    const [showForm, setShowForm] = useState(false)
    const [loading, setLoading] = useState(false);
    const {isAuthenticated} = useSelector(state => state.auth);
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {width} = useWindowSize();
    const navigate = useNavigate()
    const {productName} = useParams()
    const lang = Cookies.get("i18next") || "en";
    const [item, setItem] = useState(0)
    const [quanity, setQuanity] = useState(1)
    const singleLoadin = useSelector(state => state.newReducer.singleLoadin)
    const [acardion, setAcardion] = useState([])
    const [itemImage, setItemImage] = useState(0)
    const [transitioning, setTransitioning] = useState(false);
    const smallImagesRef = useRef(null)
    const [openModal, setOpenModal] = useState(false)
    const trans = useSelector(state => state.newReducer.trans)
    const handleHeart = async (product_id, f) => {
        if (isAuthenticated) {
            const {payload} = await dispatch(addToFavoritesAction({product_id, user_id: user?.id, token}))
            if (payload?.status === 'success') {
                toast(t('add_favorites'))
            }
        } else {
            dispatch(localAddFavorites({...f}))
            toast(t('add_favorites'))
        }
    }
    useEffect(() => {
        setLoading(true)
        dispatch(getSingleProduct({id: productName}))
        setLoading(false)
    }, [productName]);
    const [showArrowBtns, setShowArrowBtns] = useState(false);
    const [bigImage, setBigImage] = useState('')
    const product = useSelector(state => state.newReducer.singleProduct)
    // useEffect(() => {
    //     setBigImage(`${STORAGE_URL}/${product?.product?.main_image}`)
    // }, [product]);
    const handleGoSingle = (id) => {
        navigate(`/${lang}/shop/${id}`)
    }
    const localAddFavoritesItems = useSelector(state => state.newReducer.localFavorites)

    const addToCartLogOut = (product) => {
        dispatch(localBasketAdd({
            ...product,
            myPrice: +quanity * +product?.price,
            newPrice: +quanity * +product?.price,
            quantity: quanity
        }))
        toast(t('add-cart'))
    }
    const handleShowForm = (id) => {
        setShowForm(true)
        setSelectedProductId(id)
    }
    const addToCart = async values => {
        const {payload} = await dispatch(addBasket({
            product_id: values.id,
            product_count: quanity,
            user_id: user.id,
            token
        }))
        if (payload.status === 'success') {
            toast(t('add-cart'))
        }
    };
    useEffect(() => {
        if (singleLoadin !== 'pending' || singleLoadin !== 'rejected') {
            setBigImage(`${STORAGE_URL}/${product?.product?.images[itemImage].path}`)
        }
    }, [itemImage, product]);
    useEffect(() => {
        if (singleLoadin === 'ok') {
            setAcardion([{
                id: 1,
                title: t('Details & Overview'),
                text: product?.product[`details_${lang}`]
            },
                {
                    id: 2,
                    title: t('Dimensions'),
                    text: product?.product[`dimensions_${lang}`]
                }
            ])
        }
    }, [singleLoadin])
    if (singleLoadin === 'pending') {
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        );
    }
    if (singleLoadin === 'rejected') {
        return (
            <PageNotFound/>
        )
    }
    return (
        <motion.div
            initial={initial}
            animate={animate}
            className='singleShopContainer'>
            <div className='personalizationStation'>
                <div className='choiceConfig'>
                    <div>
                        <div className='productImgsContainer'>
                            <div ref={smallImagesRef} style={{
                                overflowY: (product?.product?.images?.length > 4 && width > 900) ? 'scroll' : 'hidden',
                                overflowX: (product?.product?.images?.length > 3 && width < 899) ? 'scroll' : 'hidden'
                            }} className='productSmallImgs'>
                                {product?.product?.images?.map((img, i) => (
                                    <Fragment key={img.id}>
                                        <div
                                            key={img.id}
                                            style={{
                                                cursor: 'pointer',
                                                paddingRight: 15
                                            }}
                                            onClick={() => {
                                                setItemImage(findIndexById(product?.product?.images, img.id))
                                                setBigImage(`${STORAGE_URL}/${img.path}`)
                                            }}>
                                            <Img style={{
                                                border: i === itemImage ? '2px solid #AF4B85' : '',
                                            }} src={`${STORAGE_URL}/${img.path}`} alt='Product Small'/>
                                        </div>
                                    </Fragment>
                                ))}
                            </div>
                            <div className='big-image-single'>
                                <div style={{height: '100%'}}>
                                    {product?.product?.images?.length > 1 ? (
                                        itemImage > 0 ? (<div
                                            onClick={() => {
                                                if (itemImage > 0) {
                                                    setTransitioning(true);
                                                    setItemImage(itemImage - 1)
                                                    // smallImagesRef.current.scrollTop -=150
                                                }
                                            }}
                                            style={{
                                                position: 'absolute',
                                                left: 5,
                                                top: '50%',
                                                cursor: 'pointer',
                                                width: 40,
                                                height: 40,
                                                background: "#0F0F0F",
                                                display: 'flex',
                                                justifyContent: "center",
                                                alignItems: 'center',
                                                borderRadius: '50%'
                                            }}>
                                            <img src={arrow_left} alt='arrow'/>
                                        </div>) : null
                                    ) : null}
                                    <img
                                        onClick={() => setOpenModal(true)}
                                        alt='Product'
                                        style={{
                                            cursor: 'pointer',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                        onLoad={() => setTransitioning(false)}
                                        src={bigImage}/>
                                    {product.product.images.length > 1 ? (
                                        product.product.images.length - 1 === itemImage ? null : (<div
                                            onClick={() => {
                                                if (itemImage < product.product.images.length - 1) {
                                                    setTransitioning(true);
                                                    setItemImage(itemImage + 1)
                                                    // smallImagesRef.current.scrollTop +=150
                                                }
                                            }}
                                            style={{
                                                position: 'absolute',
                                                right: 5,
                                                top: '50%',
                                                cursor: 'pointer',
                                                width: 40,
                                                height: 40,
                                                background: "#0F0F0F",
                                                display: 'flex',
                                                justifyContent: "center",
                                                alignItems: 'center',
                                                borderRadius: '50%'
                                            }}
                                        >
                                            <img src={arrow_right} alt='arrow'/>
                                        </div>)

                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='productDetailsOverview'>
                            <SingleShopAcardion data={acardion} className='detailsOverviewFAQ'/>
                        </div>
                    </div>
                    <div className='valueVaultContainer'>
                        <div className='valueVaultDetailsContainer'>
                            <div className='valueVaultTitleContainer'>
                                <p style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} className='valueVaultTitle'>{product?.product[`title_${lang}`]}</p>
                                {!product?.product?.price || product?.product?.price == '0' ? null : (
                                    <p style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} className='valueVaultPrice'>{currency} {+product?.product?.price * quanity}</p>)}
                            </div>
                            <p style={{
                                color: theme === 'ligth' ? '#000' : ''
                            }} className='valueVaultDescription'>
                                {removeHtmlTags(product?.product[`description_${lang}`])}
                            </p>
                        </div>
                        <div style={{
                            background: theme === 'ligth' ? '#000' : ''
                        }} className='valueVaultDividor'></div>
                        <div className='pricecountContainer'>
                            <div className='priceContainer'>
                                <p style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} className='priceCountTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Colors')[0][`value_${lang}`] : t('color')}</p>
                                <div className='priceCountOptions'>
                                    {product?.product?.colors?.map((e, i) => (
                                        <div
                                            key={i}
                                            style={{
                                                background: e.hex_code,
                                                width: 20,
                                                height: 20,
                                                borderRadius: '50%'
                                            }}>

                                        </div>
                                    ))}
                                    {/*<button style={{background: product?.product?.color?.hex_code}}></button>*/}
                                    {/*<button></button>*/}
                                </div>
                            </div>
                            <div className='quantityContainer'>
                                <p style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} className='priceCountTitle'>{t('Qty')}:</p>
                                <div className='quantity-field'>
                                    <button
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background: theme === 'ligth' ? 'rgb(239, 239, 239)' : '',
                                            border: theme === 'ligth' ? '1px solid black' : ''
                                        }}
                                        className='value-button decrease-button'
                                        onClick={() => {
                                            if (quanity > 1) {
                                                setQuanity(quanity - 1)
                                            }
                                        }}>
                                        -
                                    </button>
                                    <input
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background: theme === 'ligth' ? 'rgb(239, 239, 239)' : '',
                                            borderTop: theme === 'ligth' ? '1px solid black' : '',
                                            borderBottom: theme === 'ligth' ? '1px solid black' : ''
                                        }}
                                        value={quanity}
                                        className='number'
                                        readOnly
                                    />
                                    <button
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background: theme === 'ligth' ? 'rgb(239, 239, 239)' : '',
                                            border: theme === 'ligth' ? '1px solid black' : ''
                                        }}
                                        className='value-button increase-button'
                                        onClick={() => setQuanity(quanity + 1)}>
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            background: theme === 'ligth' ? '#000' : ''
                        }} className='valueVaultDividor'></div>
                        <div className='typeMaterialContainer'>
                            <div className='typeContainer'>
                                <p style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} className='typeMaterialTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Type')[0][`value_${lang}`] : t('type')}</p>
                                {product?.product?.types?.map((e, i) => (
                                    <p style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} key={i} className='typeMaterialDescription'>{e[`title_${lang}`]}</p>
                                ))}
                            </div>
                            <div className='materialContainer'>
                                <p style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} className='typeMaterialTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Material')[0][`value_${lang}`] : t("material")}</p>
                                {product?.product?.materials?.length > 0 ? product?.product?.materials?.map((e, i) => (
                                    <p style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} key={i} className='typeMaterialDescription'> {e[`title_${lang}`]}</p>
                                )) : null}
                            </div>
                            <div className='btns' style={{
                                display: 'flex',
                                gap: 16
                            }}>
                                <Button
                                    text={product.product.price == 0 ? (trans.length > 0 ? trans.filter((e)=>e.key === 'To_Order')[0][`value_${lang}`] :t('To Order')) : (trans.length > 0 ? trans.filter((e)=>e.key === 'Add_to_cart')[0][`value_${lang}`] :t("add-to-cart"))}
                                    className='addToCartBtn'
                                    style={{
                                        background: "var(--main-color-pink)",
                                        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.04)",
                                        border: "none",
                                        color: "var(--secondary-color-white)",
                                        width: "fit-content",
                                        fontFamily: "Poppins-600, sans-serif",
                                        fontWeight: "600",
                                    }}
                                    onClick={() => product.product.price == '0' ? handleShowForm(product.id) : (isAuthenticated ? addToCart({...product.product}) : addToCartLogOut(product.product))}
                                />
                                <NewSaveButton
                                    isSingle={true}
                                    isSaved={(favoriteProjects?.some(project => project.product?.id === +productName)) || (localAddFavoritesItems?.some(e => e.id === +productName))}
                                    onClick={() => handleHeart(product.product.id, {...product.product})}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='shopSuggestionsSliderContainer container'>
                {trans.filter((e) => e.key === 'related_posts').map((e, i) => (
                    <Title key={i} title={e[`value_${lang}`]}/>
                ))}
                <Carousel
                    renderCenterLeftControls={() => null}
                    renderCenterRightControls={() => null}
                    renderBottomCenterControls={() => null}
                    slidesToShow={width > 900 ? 4 : 2}
                    className='portfolioSlider' showArrowBtns={showArrowBtns}>
                    {product.product_suggestions.map((order, i) => (
                        <div key={i}>
                            <Product
                                goSingle={() => handleGoSingle(order.id)}
                                isSingle={true}
                                name={order[`title_${lang}`]}
                                price={order.price}
                                pending={order.pending}
                                onBtnClick={() => order.price == '0' ? handleShowForm(order.id) : (isAuthenticated ? addToCart({...order}) : addToCartLogOut(order))}
                                heartit={() => handleHeart(order.id, {...order})}
                                btnText={order.price == 0 ? (trans.length > 0 ? trans.filter((e)=>e.key === 'To_Order')[0][`value_${lang}`] :t('To Order')) : (trans.length > 0 ? trans.filter((e)=>e.key === 'Add_to_cart')[0][`value_${lang}`] :t("add-to-cart"))}
                                img={`${STORAGE_URL}/${order.main_image}`}
                                id={order.id}
                            />
                        </div>
                    ))}
                </Carousel>
            </div>
            <Modal
                isSlider={true}
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                {product?.product?.images?.length > 0 ?
                    <div>
                        <Carousel
                            renderCenterLeftControls={({previousSlide, currentSlide}) => (
                                currentSlide === 0 ? null : (
                                    <button
                                        style={{
                                            cursor: currentSlide === 0 ? 'not-allowed' : '',
                                            width: 40,
                                            height: 40,
                                            background: "#0F0F0F",
                                            display: 'flex',
                                            justifyContent: "center",
                                            alignItems: 'center',
                                            borderRadius: '50%',
                                            border: 'none',
                                            outline: 'none'
                                        }}
                                        disabled={currentSlide === 0} onClick={previousSlide}>
                                        <img src={arrow_left} alt='arrow'/>
                                    </button>
                                )

                            )}
                            renderCenterRightControls={(props) => (
                                product?.product?.images?.length - 1 <= props.currentSlide ? null : (<button
                                    style={{
                                        cursor: product?.product?.images?.length - 1 <= props.currentSlide ? 'not-allowed' : '',
                                        width: 40,
                                        height: 40,
                                        background: "#0F0F0F",
                                        display: 'flex',
                                        justifyContent: "center",
                                        alignItems: 'center',
                                        borderRadius: '50%',
                                        border: 'none',
                                        outline: 'none'
                                    }}
                                    disabled={product?.product?.images?.length - 1 <= props.currentSlide}
                                    onClick={props.nextSlide}>
                                    <img src={arrow_right} alt='arrow'/>
                                </button>)

                            )}
                            // renderBottomCenterControls={()=>null}
                            animation={'zoom'}
                            slidesToShow={1}
                            style={{
                                width: width > 900 ? 600 : ''
                            }}
                        >
                            {product?.product?.images?.map((e, i) => (
                                <div key={i} style={{
                                    width: '100%',
                                    height: width > 900 ? 600 : 350
                                }}>
                                    <Img
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: 15
                                        }}
                                        src={`${STORAGE_URL}/${e?.path}`}
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    : null}
            </Modal>
            <Modal
                title={trans.length > 0 ? trans.filter((e)=>e.key === 'Create an application')[0][`value_${lang}`] :t("Create_Requirement")}
                open={showForm}
                onClose={() => setShowForm(false)}>
                <Formik
                    initialValues={{
                        name: '',
                        surName: '',
                        phone: '',
                        email: ''
                    }}
                    onSubmit={async (values, actions) => {
                        setLoading(true)
                        const {payload} = await dispatch(postStoreRequirements({
                            ...values,
                            product_id: selectedProductId,
                            token
                        }))
                        if (payload.status === "success") {
                            toast.success(payload?.message)
                        }
                        setLoading(false)
                        setShowForm(false)

                    }}
                    validationSchema={storeRequirements}>
                    {() => (
                        <Form>
                            <div className='newAddresFormGroup'>
                                <label style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} htmlFor='name'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Name')[0][`value_${lang}`] :t("name")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(239, 239, 239)' : ''
                                        }}
                                        type='text'
                                        name='name'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_name')[0][`value_${lang}`] : t("placeholder.enter_your_name")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='name'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='surname'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Surname')[0][`value_${lang}`] :t("surname")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(239, 239, 239)' : ''
                                        }}
                                        type='text'
                                        name='surname'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_surname')[0][`value_${lang}`] :t("placeholder.enter_your_surname")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='surname'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='email'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Email')[0][`value_${lang}`] :t("email")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(239, 239, 239)' : ''
                                        }}
                                        type='text'
                                        name='email'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_email_address')[0][`value_${lang}`] :t("placeholder.enter_your_email")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='email'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='phone'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_Number')[0][`value_${lang}`] :t("phone_number")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(239, 239, 239)' : ''
                                        }}
                                        type='text'
                                        name='phone'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_phone_number')[0][`value_${lang}`] :t("placeholder.enter_your_phone_number")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='phone'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <button
                                // text={
                                //     loading ? (
                                //         <Spinner color='#fff' size={18}/>
                                //     ) : (
                                //     )
                                // }
                                // btnType='submit'
                                type='submit'
                                className='addAddressBtn'
                                style={{
                                    background: "var(--main-color-pink)",
                                    color: "var(--secondary-color-white)",
                                    border: "none",
                                    fontFamily: "Poppins-600, sans-serif",
                                    fontWeight: "600",
                                }}
                            >
                                {trans.length > 0 ? trans.filter((e)=>e.key === 'Create an application')[0][`value_${lang}`] :t("Create_Requirement")}

                            </button>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <Footer/>
        </motion.div>
    );
};

export default SingleShop;
