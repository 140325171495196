import Title from "../Title/Title";
import ARROW from "../../assets/icons/arrow-down-white.svg";
import "./style.css";
import { useState } from "react";
import Cookies from "js-cookie";
import {useSelector} from "react-redux";

const SingleShopAcardion = ({ title, className, data }) => {
    const theme = useSelector(state => state.newReducer.themeChange)
    const faq2 = {
        faq_main: {
            heading_en: "sfhjbk123258",
            heading_ru: "hhvuj",
            heading_am: "sfsg",
            description_en: "<p>afsdg</p>",
            description_ru: "<p>sdgg</p>",
            description_am: "<p>sgsg</p>",
            faqs: [
                {
                    id: 4,
                    question_en: "<p>bghgvbujfd147</p>",
                    question_ru: "<p>fhgfh</p>",
                    question_am: "<p>fhfghg</p>",
                    answer_en: "<p>hgfhg</p>",
                    answer_ru: "<p>fhgj</p>",
                    answer_am: "<p>dgthut</p>",
                },
            ],
        },
    };

    const lang = Cookies.get("i18next") || "en";
    const [openAccordion, setOpenAccordion] = useState(null);

    return (
        <div className={`${className} faqContainer`}>
            {title && <Title title={title} />}
            <div className='accordion'>
                {data.map(f => (
                    <div
                        className={`${
                            openAccordion === f.id && "accordion-item-open"
                        } accordion-item`}
                        id={`question${f.id}`}
                        key={f.id}>
                        <a
                            style={{
                                background:theme === 'ligth' ? 'transparent' : '',
                                color:theme === 'ligth' ? '#000' : ''
                            }}
                            className={`${
                                openAccordion === f.id && "accordion-link-open"
                            } accordion-link`}
                            href={`#question${f.id}`}
                            onClick={e => {
                                e.preventDefault();
                                openAccordion === f.id
                                    ? setOpenAccordion(null)
                                    : setOpenAccordion(f.id);
                            }}>
                            <div
                                className='flex'
                                dangerouslySetInnerHTML={{ __html: f.title }}
                            />
                            <div style={{
                                background: theme === 'ligth' ? '#F8F8F8' : ''
                            }} className='accordionArrow'>
                                <svg width="12" height="6" viewBox="0 0 12 6" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.75 0.75L6 5.25L11.25 0.75" stroke={theme === 'ligth' ? 'black' : 'white'} stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </a>
                        <div
                            style={{
                                background: theme === 'ligth' ? 'transparent' : '',
                                color: theme === 'ligth' ? 'transparent' : ''
                            }}
                            className={theme !== 'ligth' ?  'answer' : 'answer desc-ligth'}
                            dangerouslySetInnerHTML={{ __html: f.text }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SingleShopAcardion;
