import Checkbox from "../Checkbox/Checkbox";
import Img from "../Img";
import QauntityField from "../QauntityField/QauntityField";
import DELETE from "../../assets/icons/delete-white.svg";
import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {
    cheCkError,
    deleteFromCart,
    deleteFromLocalBasket,
    selectedItemsAdd
} from "../../redux/actions/newActions/newActions";
import currency from "../../Helpers/currency";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {closeBasketModal} from "../../redux/actions/basketActions";

const CardProduct = ({
                         title,
                         description,
                         img,
                         onRemove,
                         className,
                         quantity,
                         price,
                         id,
                         product_id,
                         setSelectError
                     }) => {
    const dispatch = useDispatch()
    const theme = useSelector(state => state.newReducer.themeChange)
    const {isAuthenticated} = useSelector(state => state.auth);
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token')
    const basketSelected = useSelector(state => state.newReducer.basketSelected)
    const navigate = useNavigate()
    const lang = Cookies.get("i18next") || "en";
    return (
        <div className={`${className} cardProductContainer`}>
            <div className='firstSecondThird'>
                <div className='firstSecond'>
                    <div className='first'>
                        <Checkbox
                            checked={basketSelected.includes(id)}
                            uniqueId={id}
                            onChange={() => {
                                setSelectError('')
                                dispatch(cheCkError(''))
                                dispatch(selectedItemsAdd(id))
                            }}/>
                    </div>
                    <div className='second'>
                        <Img src={img} alt={title}/>
                    </div>
                </div>
                <div className='third'>
                    <div className='thirdTitleContainer'>
                        <p style={{cursor:'pointer' , color:theme==='ligth' ? '#000' : ''}} onClick={()=> {
                            navigate(`/${lang}/shop/${product_id}`)
                            dispatch(closeBasketModal())
                        }} className='cardProductTitle'>{title}</p>
                        <p style={{color:theme==='ligth' ? '#000' : ''}} className='cardProductDescription'>{description}</p>
                    </div>
                    <div className='btns' style={{display:'flex'}}>
                        <QauntityField  price={price} product_id={product_id} quantity={quantity} id={id}/>
                        {className !== "checkoutCardProductContainer" ? (
                            <button className='cardProductDeleteBtn'
                                    style={{
                                        marginLeft:15
                                    }}
                                    onClick={() => isAuthenticated ?
                                        dispatch(deleteFromCart({user_id: user?.id, product_id: product_id, token})) :
                                        dispatch(deleteFromLocalBasket({product_id}))}>
                                <Img src={DELETE} alt='Delete'/>
                            </button>
                        ) : (
                            <p style={{
                                color:theme === 'ligth' ? '#000' :""
                            }} className='cardProductPrice'>{currency} {price}</p>
                        )}
                    </div>
                </div>
            </div>
            <div className='forth'>
                {className !== "checkoutCardProductContainer" ? (
                    <p style={{
                        color:theme === 'ligth' ? '#000' :""
                    }} className='cardProductPrice'>{currency} {price}</p>
                ) : (
                    <button className='cardProductDeleteBtn' onClick={onRemove}>
                        <Img src={DELETE} alt='Delete'/>
                    </button>
                )}
            </div>
        </div>
    );
};

export default CardProduct;
