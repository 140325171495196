import {useCallback, useEffect, useState} from "react";
import {motion} from "framer-motion";
import ContactLayout from "../components/ContactLayout/ContactLayout";
import {ErrorMessage, Field, Form, Formik} from "formik";
import ValidationSchema from "../utils/ValidationSchema";
import LOCATION from "../assets/icons/location-white.svg";
import EMAIL from "../assets/icons/sms-white.svg";
import TELEPHONE from "../assets/icons/telephone-ring-white.svg";
import WORK_DAYS from "../assets/icons/work_days.png";
import WORK_HOURS from "../assets/icons/work_time.png";
import Button from "../components/Button/Button";
import Img from "../components/Img";
import FAQ from "../components/FAQ/FAQ";
import Footer from "../components/Footer/Footer";
import {animate, initial} from "../utils/transition";
import {useDispatch, useSelector} from "react-redux";
import {fetchLocations} from "../redux/actions/locationsActions";
import {fetchContacts} from "../redux/actions/contactsActions";
import Spinner from "../components/Spinner/Spinner";
import Locations from "../components/Locations/Locations";
import {removeHtmlTags} from "../Helpers/removeHtmlTags";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {fetchFAQ} from "../redux/actions/faqActions";
import {getMainInfo, postContactUs} from "../redux/actions/newActions/newActions";
import INSTAGRAM from "../assets/social-medias/instagram-white.svg";
import YOUTUBE from "../assets/social-medias/youtube-white.svg";
import FACEBOOK from "../assets/social-medias/facebook-white.svg";
import TWITTER from "../assets/social-medias/twitter-white.svg";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

const Contact = () => {
    const trans = useSelector(state => state.newReducer.trans)
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [message, setMessage] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(fetchLocations());
        dispatch(fetchContacts());
        dispatch(fetchFAQ());
        dispatch(getMainInfo())
    }, [dispatch]);
    const handleSendForm = useCallback(async (form) => {
        const {payload} = await dispatch(postContactUs({...form}))
        if (payload.status === 'success') {
            setMessage(payload?.message[`message_${lang}`])
            toast.success(payload?.message[`message_${lang}`])
        }
    }, [])
    const locations = useSelector(state => state.locations);
    const contacts = useSelector(state => state.contacts);
    const mainInfo = useSelector(state => state.newReducer.main_info)
    const lang = Cookies.get("i18next") || "en";
    const faq = useSelector(state => state.faq);

    const {contactSchema} = ValidationSchema();
    const theme = useSelector(state => state.newReducer.themeChange)
    if (
        locations.loading &&
        locations.locations === null &&
        contacts.loading &&
        contacts.contacts === null
    )
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        );

    return (
        <motion.div
            initial={initial}
            animate={animate}
            className='contactContainer'
            style={{paddingTop: "80px"}}>
            {locations.locations?.locations && contacts.contacts && (
                <>
                    <Helmet>
                        <title>Contact</title>
                    </Helmet>
                    <ContactLayout
                        pageTitle={contacts.contacts.contacts_main[`heading_${lang}`]}
                        pageDescription={removeHtmlTags(
                            contacts.contacts.contacts_main[`description_${lang}`]
                        )}
                        detailsChildren={
                            <>
                                <div className='contactDetail'>
                                </div>
                                <div className='contactDetail'>
                                    <div className='contactDetailIcon'>
                                        <Img src={EMAIL} alt='Email'/>
                                    </div>
                                    <div className='contactDetailInfo'>
                                        <p style={{
                                            color: theme === 'ligth' ? 'black' : ''
                                        }}
                                           className='contactDetailInfoTitle'>{trans.length > 0 ? trans.filter((e) => e.key === 'Email')[0][`value_${lang}`] : t("email")}</p>
                                        <a href={`mailto:${contacts.contacts.contacts_main.email}`}
                                           style={{
                                               textDecoration: 'none',
                                               color: theme === 'ligth' ? 'black' : 'white'
                                           }}>
                                            <p className='contactDetailInfoDescription'>
                                                {contacts.contacts.contacts_main.email}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div className='contactDetail'>
                                    <div className='contactDetailIcon'>
                                        <Img src={TELEPHONE} alt='Telephone'/>
                                    </div>
                                    <div className='contactDetailInfo'>
                                        <p style={{
                                            color: theme === 'ligth' ? 'black' : ''
                                        }} className='contactDetailInfoTitle'>
                                            {trans.length > 0 ? trans.filter((e) => e.key === 'Phone_Number')[0][`value_${lang}`] : t("phone_number")}
                                        </p>
                                        <a style={{
                                            textDecoration: 'none',
                                            color: theme === 'ligth' ? 'black' : 'white'
                                        }}
                                           href={`tel:${contacts.contacts.contacts_main.phone}`}>
                                            <p className='contactDetailInfoDescription'>
                                                {contacts.contacts.contacts_main.phone}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                {/*////////////////*/}
                                <div className='contactDetail'>
                                    <div className='contactDetailIcon'>
                                        <Img src={WORK_DAYS} alt='Telephone'/>
                                    </div>
                                    <div className='contactDetailInfo'>
                                        <p style={{
                                            color: theme === 'ligth' ? 'black' : ''
                                        }} className='contactDetailInfoTitle'>
                                            {trans.length > 0 ? trans.filter((e) => e.key === 'Working_Days')[0][`value_${lang}`] : t("Working Days")}
                                        </p>
                                        <a style={{
                                            textDecoration: 'none',
                                            color: theme === 'ligth' ? 'black' : 'white'
                                        }}
                                           href={`tel:${contacts.contacts.contacts_main.phone}`}>
                                            <p className='contactDetailInfoDescription'>
                                                {mainInfo[`working_days_${lang}`]}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div className='contactDetail'>
                                    <div className='contactDetailIcon'>
                                        <Img style={{width: 20, height: 20}} src={WORK_HOURS} alt='Telephone'/>
                                    </div>
                                    <div className='contactDetailInfo'>
                                        <p style={{
                                            color: theme === 'ligth' ? 'black' : ''
                                        }} className='contactDetailInfoTitle'>
                                            {trans.length > 0 ? trans.filter((e) => e.key === 'Working_Days')[0][`value_${lang}`] : t("Working Hours")}
                                        </p>
                                        <a style={{
                                            textDecoration: 'none',
                                            color: theme === 'ligth' ? 'black' : 'white'
                                        }}
                                           href={`tel:${contacts.contacts.contacts_main.phone}`}>
                                            <p className='contactDetailInfoDescription'>
                                                {mainInfo[`working_hours`]}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                {/*////////////////*/}

                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    // justifyContent:'space-between',
                                    // width:'20%'
                                    gap: 20
                                }}>
                                    <a href={mainInfo.facebook_link} target="_blank">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.0023 9.05625C18.0023 4.0545 13.9725 0 9.00225 0C4.02975 0.001125 0 4.0545 0 9.05738C0 13.5765 3.29175 17.3227 7.59375 18.0022V11.6741H5.31V9.05738H7.596V7.0605C7.596 4.79138 8.94038 3.53812 10.9958 3.53812C11.9813 3.53812 13.0106 3.71475 13.0106 3.71475V5.94225H11.8755C10.7584 5.94225 10.4096 6.64087 10.4096 7.3575V9.05625H12.9049L12.5066 11.673H10.4085V18.0011C14.7105 17.3216 18.0023 13.5754 18.0023 9.05625Z"
                                                fill={theme === 'ligth' ? "black" : 'white'}/>
                                        </svg>

                                    </a>
                                    <a href={mainInfo.instagram_link} target="_blank">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.1276 0H4.87245C2.18573 0 0 2.18573 0 4.87245V13.1277C0 15.8143 2.18573 18 4.87245 18H13.1277C15.8143 18 18 15.8143 18 13.1277V4.87245C18 2.18573 15.8143 0 13.1276 0ZM9 13.9217C6.2861 13.9217 4.07827 11.7139 4.07827 9C4.07827 6.2861 6.2861 4.07827 9 4.07827C11.7139 4.07827 13.9217 6.2861 13.9217 9C13.9217 11.7139 11.7139 13.9217 9 13.9217ZM14.0394 5.239C13.2374 5.239 12.5851 4.58666 12.5851 3.78464C12.5851 2.98262 13.2374 2.33023 14.0394 2.33023C14.8415 2.33023 15.4939 2.98266 15.4939 3.78464C15.4939 4.58663 14.8414 5.239 14.0394 5.239Z"
                                                fill={theme === 'ligth' ? "black" : 'white'}/>
                                            <path
                                                d="M8.99931 5.13273C6.86741 5.13273 5.13281 6.86723 5.13281 8.99923C5.13281 11.1311 6.86741 12.8657 8.99931 12.8657C11.1313 12.8657 12.8658 11.1311 12.8658 8.99923C12.8658 6.86723 11.1313 5.13273 8.99931 5.13273ZM14.0387 3.38477C13.8187 3.38477 13.6396 3.5639 13.6396 3.78387C13.6396 4.00388 13.8187 4.18296 14.0387 4.18296C14.2588 4.18296 14.4379 4.00402 14.4379 3.78387C14.4379 3.56375 14.2588 3.38477 14.0387 3.38477Z"
                                                fill={theme === 'ligth' ? "black" : 'white'}/>
                                        </svg>
                                    </a>
                                    <a href={mainInfo.youtube_link} target="_blank">
                                        <svg width="17" height="13" viewBox="0 0 17 13" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.55472 0H8.64929C9.52272 0.00347006 13.9483 0.0381709 15.1416 0.38749C15.5023 0.4941 15.831 0.701903 16.0949 0.990133C16.3587 1.27836 16.5484 1.63692 16.6451 2.02998C16.7524 2.46953 16.8279 3.05134 16.8789 3.65166L16.8895 3.77196L16.9129 4.07269L16.9214 4.19299C16.9904 5.2502 16.9989 6.24032 17 6.45662V6.54338C16.9989 6.76777 16.9894 7.82498 16.9129 8.92615L16.9044 9.0476L16.8948 9.1679C16.8417 9.82952 16.763 10.4865 16.6451 10.97C16.5487 11.3632 16.3591 11.722 16.0952 12.0102C15.8313 12.2985 15.5025 12.5062 15.1416 12.6125C13.909 12.9734 9.22414 12.9988 8.57491 13H8.42403C8.09569 13 6.73773 12.9931 5.31389 12.9399L5.13326 12.9329L5.04081 12.9283L4.85912 12.9202L4.67742 12.9121C3.49797 12.8554 2.37484 12.764 1.85737 12.6114C1.49661 12.5052 1.16783 12.2976 0.903951 12.0096C0.640073 11.7215 0.450365 11.363 0.353835 10.97C0.23589 10.4877 0.15726 9.82952 0.104132 9.1679L0.095631 9.04645L0.0871304 8.92615C0.0346902 8.14238 0.00563146 7.35696 0 6.57114L0 6.42886C0.00212513 6.18018 0.0106257 5.32076 0.0680043 4.37227L0.0754422 4.25314L0.0786299 4.19299L0.0871304 4.07269L0.110507 3.77196L0.121133 3.65166C0.172136 3.05134 0.247578 2.46837 0.354897 2.02998C0.451265 1.63677 0.6409 1.27804 0.904789 0.989756C1.16868 0.701477 1.49755 0.493781 1.85843 0.38749C2.3759 0.237121 3.49903 0.144586 4.67848 0.0867514L4.85912 0.0786546L5.04188 0.0717147L5.13326 0.0682447L5.31496 0.0601479C6.32621 0.0247239 7.33788 0.00505803 8.34965 0.00115674H8.55472V0ZM6.80043 3.71296V9.28588L11.2175 6.50058L6.80043 3.71296Z"
                                                fill={theme === 'ligth' ? "black" : 'white'}/>
                                        </svg>

                                    </a>
                                    <a href={mainInfo.twitter_link} target="_blank">
                                        <svg width="19" height="15" viewBox="0 0 19 15" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.09175 14.7508C12.8845 14.7508 16.6004 9.1224 16.6004 4.25003C16.6004 4.09253 16.6004 3.93278 16.5936 3.77528C17.3171 3.25158 17.9414 2.6032 18.4375 1.86053C17.7617 2.15849 17.0456 2.35486 16.3124 2.44328C17.0847 1.98162 17.6632 1.25524 17.9402 0.399155C17.2146 0.829044 16.4204 1.13082 15.5924 1.29128C15.0357 0.698431 14.2991 0.305687 13.4966 0.173882C12.6941 0.0420767 11.8706 0.178566 11.1535 0.562209C10.4364 0.945852 9.86591 1.55524 9.53026 2.29597C9.1946 3.03671 9.11258 3.86745 9.29688 4.65953C7.82846 4.5859 6.39192 4.20443 5.08042 3.53987C3.76893 2.8753 2.61177 1.94248 1.684 0.801905C1.21301 1.61537 1.06927 2.57761 1.28198 3.4932C1.49468 4.4088 2.04789 5.20913 2.82925 5.73165C2.24373 5.71176 1.67109 5.55453 1.1575 5.27265V5.32328C1.15851 6.17545 1.45372 7.00113 1.99323 7.66076C2.53275 8.32039 3.28347 8.77351 4.1185 8.94353C3.80155 9.03085 3.47413 9.07437 3.14538 9.07291C2.91361 9.07361 2.6823 9.05213 2.45462 9.00878C2.69063 9.74234 3.15018 10.3837 3.7689 10.8431C4.38762 11.3024 5.13453 11.5567 5.905 11.5704C4.59612 12.5984 2.97933 13.156 1.315 13.1533C1.02174 13.1545 0.728676 13.1376 0.4375 13.1027C2.1267 14.1796 4.08846 14.7514 6.09175 14.7508V14.7508Z"
                                                fill={theme === 'ligth' ? "black" : 'white'}/>
                                        </svg>
                                    </a>
                                </div>
                            </>
                        }>
                        <Formik
                            initialValues={{
                                full_name: "",
                                email: "",
                                phone: "",
                                message: "",
                            }}
                            onSubmit={(values, actions) => {
                                actions.resetForm()
                                handleSendForm({...values})
                            }}
                            validationSchema={contactSchema}>
                            {() => (
                                <Form style={{
                                    background: theme === 'ligth' ? 'white' : '',
                                    border: theme === 'ligth' ? '1px solid black' : ''
                                }} className='contactForm'>
                                    <div className='contactFormGroup'>
                                        <label style={{
                                            color: theme === 'ligth' ? 'black' : ''
                                        }}
                                               htmlFor='full_name'>{trans.length > 0 ? trans.filter((e) => e.key === 'Full_Name')[0][`value_${lang}`] : t("full_name")}</label>
                                        <div className='contactFormGroupInput'>
                                            <Field
                                                style={{
                                                    color: theme === 'ligth' ? 'black' : '',
                                                    borderBottom: theme === 'ligth' ? '1px solid black' : ''
                                                }}
                                                type='text'
                                                name='full_name'
                                                placeholder={trans.length > 0 ? trans.filter((e) => e.key === 'Enter_your_full_name')[0][`value_${lang}`] : t("placeholder.enter_your_full_name")}
                                            />
                                        </div>
                                        <ErrorMessage
                                            name='full_name'
                                            component='p'
                                            className='authError'
                                        />
                                    </div>
                                    <div className='contactFormGroup2ndLayout'>
                                        <div className='contactFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? 'black' : '',
                                                }}
                                                htmlFor='email'>{trans.length > 0 ? trans.filter((e) => e.key === 'Email_Address')[0][`value_${lang}`] : t("email_address")}</label>
                                            <div className='contactFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? 'black' : '',
                                                        borderBottom: theme === 'ligth' ? '1px solid black' : ''

                                                    }}
                                                    type='email'
                                                    name='email'
                                                    placeholder={trans.length > 0 ? trans.filter((e) => e.key === 'Enter_your_email_address')[0][`value_${lang}`] : t(
                                                        "placeholder.enter_your_email_address"
                                                    )}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name='email'
                                                component='p'
                                                className='authError'
                                            />
                                        </div>
                                        <div className='contactFormGroup'>
                                            <label
                                                style={{
                                                    color: theme === 'ligth' ? 'black' : ''
                                                }}
                                                htmlFor='phone'>{trans.length > 0 ? trans.filter((e) => e.key === 'Phone_Number')[0][`value_${lang}`] : t("phone_number")}</label>
                                            <div className='contactFormGroupInput'>
                                                <Field
                                                    style={{
                                                        color: theme === 'ligth' ? 'black' : '',
                                                        borderBottom: theme === 'ligth' ? '1px solid black' : ''

                                                    }}
                                                    type='text'
                                                    name='phone'
                                                    placeholder={trans.length > 0 ? trans.filter((e) => e.key === 'Enter_your_phone_number')[0][`value_${lang}`] : t("placeholder.enter_your_phone_number")}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name='phone'
                                                component='p'
                                                className='authError'
                                            />
                                        </div>
                                    </div>
                                    <div className='contactFormGroup'>
                                        <label
                                            style={{
                                                color: theme === 'ligth' ? 'black' : ''
                                            }}
                                            htmlFor='message'>{trans.length > 0 ? trans.filter((e) => e.key === 'Message')[0][`value_${lang}`] :t("message")}</label>
                                        <div className='contactFormGroupInput'>
                                            <Field
                                                style={{
                                                    color: theme === 'ligth' ? 'black' : '',
                                                    borderBottom: theme === 'ligth' ? '1px solid black' : ''
                                                }}
                                                type='text'
                                                name='message'
                                                placeholder={trans.length > 0 ? trans.filter((e) => e.key === 'Enter_your_message')[0][`value_${lang}`]: t("placeholder.enter_your_your_message")}
                                            />
                                        </div>
                                        <ErrorMessage
                                            name='message'
                                            component='p'
                                            className='authError'
                                        />
                                    </div>
                                    <Button
                                        btnType='submit'
                                        link={false}
                                        text={trans.length > 0 ? trans.filter((e) => e.key === 'Send_Message')[0][`value_${lang}`]:t("send-message")}
                                        style={{
                                            backgroundColor: "var(--main-color-pink)",
                                            border: "none",
                                            fontFamily: "Poppins-600, sans-serif",
                                            fontWeight: "600",
                                            borderRadius: "var(--main-border-radius)",
                                            color: "var(--secondary-color-white)",
                                            width: "fit-content",
                                        }}
                                        className='bottomHeaderRegisterBtn'
                                    />
                                    {message ? <p style={{fontSize: 14}} className='packagePrice'>{message}<span
                                        style={{fontSize: 20, marginLeft: 10}}>&#10003;</span></p> : null}
                                </Form>
                            )}
                        </Formik>
                    </ContactLayout>
                    <ContactLayout
                        pageTitle={faq?.faq?.faq_main[`heading_${lang}`]}
                        pageDescription={removeHtmlTags(faq?.faq?.faq_main[`description_${lang}`])}>
                        {/*<FAQ className='contactFAQ' />*/}
                        <FAQ className='contactFAQ' data={faq.faq}/>
                    </ContactLayout>
                    <Locations isContact={true} locations={locations.locations?.locations}/>
                    <Footer/>
                </>
            )}
        </motion.div>
    );
};

export default Contact;
