import "./style.css";
import Title from "../Title/Title";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import {STORAGE_URL} from "../../services/apiService";
import Cookies from "js-cookie";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useSelector} from "react-redux";

const MeetUs = ({meet_us}) => {
    const lang = Cookies.get("i18next") || "en";
    const {width} = useWindowSize();
    const theme = useSelector(state => state.newReducer.themeChange)

    return (
        <div style={{
            background: theme === 'ligth' ? '#fff' : ''
        }} className='meetUsContainer container'>
            <div className='meetUsInfo'>
                <Title title={meet_us[`title_${lang}`]}/>
                <p style={{
                    color: theme === 'ligth' ? '#000' : ''
                }} className='meetUsDesc'>
                    {removeHtmlTags(meet_us[`description_${lang}`])}
                </p>
            </div>
            <div className='meetUsMembers'>
                {meet_us.team_members.map(member => (
                    <div style={{width: width < 900 ? '100%' : `calc(100% / ${meet_us.team_members.length})`}}
                         className='meetUsMember'
                         key={member.id}>
                        <div
                            className='meetUsMemberImg'
                            style={{
                                backgroundImage: `url(${STORAGE_URL}/${member.image})`,
                            }}></div>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} className='meetUsMemberName'>
                            {member[`name_${lang}`]} {member[`surname_${lang}`]}
                        </p>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} className='meetUsMemberPosition'>{member[`position_${lang}`]}</p>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default MeetUs;
