import Img from "../Img";
import CLOSE from "../../assets/icons/close-white.svg";
import "./style.css";
import {Fragment, useEffect, useState} from "react";
import Button from "../Button/Button";
import {AnimatePresence, motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {animate, initial} from "../../utils/transition";
import Cookies from "js-cookie";
import {
    addBasket,
    getFavorites,
    localBasketAdd,
    openFavoritesAction, postStoreRequirements,
} from "../../redux/actions/newActions/newActions";
import FavoriteProduct from "./FavoriteProduct";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Modal from "../Modal/Modal";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Spinner from "../Spinner/Spinner";
import ValidationSchema from "../../utils/ValidationSchema";
import {theme} from "antd";

const Favorites = () => {
    const lang = Cookies.get("i18next") || "en";
    const {t} = useTranslation()
    const [showForm, setShowForm] = useState(false)
    const {isAuthenticated} = useSelector(state => state.auth);
    const user = JSON.parse(localStorage.getItem("user"));
    const [selectedProductId, setSelectedProductId] = useState('')
    const openFavorites = useSelector(state => state.newReducer.openFavorites)
    const dispatch = useDispatch();
    const favorites = useSelector(state => state.newReducer.favorites)
    const [loading, setLoading] = useState(false);
    const {storeRequirements} = ValidationSchema();
    const trans = useSelector(state => state.newReducer.trans)
    useEffect(() => {
        document.body.classList.toggle("no-scroll", openFavorites);
        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [openFavorites]);
    const userId = JSON.parse(localStorage.getItem('user'))?.id
    const token = localStorage.getItem('token')
    useEffect(() => {
        if (token) {
            dispatch(getFavorites({id: userId, token}))
        }
    }, []);
    useEffect(() => {
        favorites.length === 0 && dispatch(openFavoritesAction(false));
    }, [favorites]);
    const handleShowForm = (id) => {
        dispatch(openFavoritesAction(false))
        setShowForm(true)
        setSelectedProductId(id)
    }
    const theme = useSelector(state => state.newReducer.themeChange)

    const addToCart = async values => {
        const {payload} = await dispatch(addBasket({
            product_id: values.product.id,
            product_count: 1,
            user_id: user.id,
            token
        }))
        if (payload.status === 'success') {
            toast(t('add-cart'))
        }
    };
    const addToCartLogOut = (product) => {
        dispatch(localBasketAdd({...product, quantity: 1}))
        toast(t('add-cart'))
    }
    return (
        <>
            <Modal
                title={trans.length > 0 ? trans.filter((e)=>e.key === 'Create an application')[0][`value_${lang}`] :t("Create_Requirement")}
                open={showForm}
                onClose={() => setShowForm(false)}>
                <Formik
                    initialValues={{
                        name: '',
                        surname: '',
                        phone: '',
                        email: ''
                    }}
                    onSubmit={async (values, actions) => {
                        setLoading(true)
                        const {payload} = await dispatch(postStoreRequirements({
                            ...values,
                            product_id: selectedProductId,
                            token
                        }))
                        if (payload?.status === "success") {
                            toast.success(payload?.message)
                        }
                        // setLoading(false)
                        // setShowForm(false)

                    }}
                    validationSchema={storeRequirements}>
                    {() => (
                        <Form>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='name'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Name')[0][`value_${lang}`] :t("name")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='name'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_name')[0][`value_${lang}`] :t("placeholder.enter_your_name")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='name'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='surname'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Surname')[0][`value_${lang}`] :t("surname")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''

                                        }}
                                        type='text'
                                        name='surname'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_surname')[0][`value_${lang}`] :t("placeholder.enter_your_surname")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='surname'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='email'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Email')[0][`value_${lang}`] :t("email")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''

                                        }}
                                        type='text'
                                        name='email'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_email_address')[0][`value_${lang}`] :t("placeholder.enter_your_email")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='email'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='phone'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_Number')[0][`value_${lang}`] :t("phone_number")}
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''

                                        }}
                                        type='text'
                                        name='phone'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_phone_number')[0][`value_${lang}`] :t("placeholder.enter_your_phone_number")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='phone'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <Button
                                text={
                                    loading ? (
                                        <Spinner color='#fff' size={18}/>
                                    ) : (
                                        (trans.length > 0 ? trans.filter((e)=>e.key === 'Create an application')[0][`value_${lang}`] :t("Create_Requirement"))
                                    )
                                }
                                btnType='submit'
                                className='addAddressBtn'
                                style={{
                                    background: "var(--main-color-pink)",
                                    color: "var(--secondary-color-white)",
                                    border: "none",
                                    fontFamily: "Poppins-600, sans-serif",
                                    fontWeight: "600",
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            </Modal>
            <AnimatePresence>
                {openFavorites && (
                    <motion.div
                        initial={initial}
                        animate={animate}
                        transition={{duration: 0.3}}
                        className='basketOverlay'
                        onClick={() => dispatch(openFavoritesAction(false))}>
                        <motion.div
                            initial={{opacity: 0, x: "100vw"}}
                            animate={{opacity: 1, x: 0}}
                            exit={{opacity: 0, x: "100vw"}}
                            transition={{duration: 0.3}}
                            className='basketContainer'
                            style={{
                                background: theme === 'ligth' ? '#fff' : ''
                            }}
                            onClick={e => e.stopPropagation()}>
                            <div className='basketHeader'>
                                <p style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} className='basketHeaderTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Favorites')[0][`value_${lang}`] :t('Favorites')}</p>
                                <button
                                    className='basketHeaderClose'
                                    onClick={() => dispatch(openFavoritesAction(false))}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 13L13 1M1 1L13 13" stroke={theme === 'ligth' ? 'black' : "white"}
                                              stroke-width="1.5"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className='basketBody'>
                                <div className='basketProducts'>
                                    {favorites.map((item) => (
                                        <FavoriteProduct
                                            key={item.id}
                                            onBtnClick={() => (item?.product?.price == '0' || item?.price == '0') ? handleShowForm(item?.product?.id) : (isAuthenticated ? addToCart({...item}) : addToCartLogOut(item))}
                                            product_id={item?.product?.id || item.id}
                                            image={item?.product?.main_image || item.image || item.main_image}
                                            description={item.product ? item?.product[`description_${lang}`] : ''}
                                            title={item[`title_${lang}`] || item?.product[`title_${lang}`]}
                                            price={item.price || item?.product?.price}
                                        />
                                    ))}
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default Favorites;
