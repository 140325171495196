import "./style.css";
import {useSelector} from "react-redux";

const PagesTitle = ({ title, description, style }) => {
    const theme = useSelector(state => state.newReducer.themeChange)
  return (
    <div className='pagesTitleContainer container' style={style}>
      <p style={{
      color:theme === 'ligth' ? '#000' : '#fff'
      }} className='pagesTitle'>{title}</p>
      {description && <p style={{
      color:theme === 'ligth' ? '#000' : '#fff'}
      } className='pagesTitleDescription'>{description}</p>}
    </div>
  );
};

export default PagesTitle;
