import {Link, NavLink} from "react-router-dom";
import WOW_LOGO from "../../assets/logo/wow-logo.svg";
import "./style.css";
import Button from "../Button/Button";
import {Dropdown, Space} from "antd";
import ARROW_DOWN from "../../assets/icons/arrow-down-white.svg";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useEffect, useState} from "react";
import HEART from "../../assets/icons/heart-without-bg.svg";
import BASKET from "../../assets/icons/basket-without-bg.svg";
import Img from "../Img";
import {useDispatch, useSelector} from "react-redux";
import {openBasketModal} from "../../redux/actions/basketActions";
import PERSON from "../../assets/person-images/1.svg";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {STORAGE_URL} from "../../services/apiService";
import {getUserBasket, openFavorites, openFavoritesAction} from "../../redux/actions/newActions/newActions";
import ThemeSwitcher from "../ThemSwitcher/ThemeSwitcher";

const BottomHeader = () => {
    const lang = Cookies.get("i18next");
    const {t} = useTranslation();
    const [canScrollUp, setCanScrollUp] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token')
    const favorites = useSelector(state => state.newReducer.favorites)
    useEffect(() => {
        if (menuIsOpen) {
            setMenuIsOpen(false)
        }
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const isScrollingUpEnabled = document.documentElement.scrollTop > 0;
            setCanScrollUp(isScrollingUpEnabled);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const trans = useSelector(state => state.newReducer.trans)

    const menu = [
        {
            id: 1,
            name: trans.length > 0 ? trans.filter((e)=>e.key === 'Custom_furniture_production')[0][`value_${lang}`] : t("bottomHeader.customcraft"),
            to: "/customcraft",
            dropdown: null,
        },
        {
            id: 2,
            name:trans.length > 0 ? trans.filter((e)=>e.key === 'Interior_Design')[0][`value_${lang}`] :  t("bottomHeader.interior-design"),
            to: "/interior-design",
            dropdown: null,
        },
        {
            id: 3,
            name:trans.length > 0 ? trans.filter((e)=>e.key === 'Shop')[0][`value_${lang}`] : t("bottomHeader.shop"),
            to: "/shop",
            dropdown: null,
        },
        {
            id: 4,
            name:trans.length > 0 ? trans.filter((e)=>e.key === 'about_us')[0][`value_${lang}`] : t("bottomHeader.about-us"),
            to: "/about-us",
            dropdown: null,
        },
        {
            id: 5,
            name:trans.length > 0 ? trans.filter((e)=>e.key === 'blog')[0][`value_${lang}`] : t("bottomHeader.blog"),
            to: "/blog",
            dropdown: null,
        },
        {
            id: 6,
            name:trans.length > 0 ? trans.filter((e)=>e.key === 'portfolio')[0][`value_${lang}`] : t("bottomHeader.portfolio"),
            to: "/portfolio",
            dropdown: null,
        },
        {
            id: 7,
            name:trans.length > 0 ? trans.filter((e)=>e.key === 'Contact_Us')[0][`value_${lang}`] : t("bottomHeader.contact-us"),
            to: "/contact-us",
            dropdown: null,
        },
    ];
    const {width} = useWindowSize();
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;

            setVisible(prevScrollPos > currentScrollPos);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos, visible]);
    const items = [
        {
            label: <Link onClick={() => handleLanguageChange("ru")}>ru</Link>,
            code: "ru",
            key: "0",
        },
        {
            label: <Link onClick={() => handleLanguageChange("am")}>am</Link>,
            code: "am",
            key: "1",
        },
        {
            label: <Link onClick={() => handleLanguageChange("en")}>en</Link>,
            code: "en",
            key: "2",
        },
    ];
    const langs = [
        {
            id: 1,
            code: "en",
            longName: "ENGLISH",
            label: <Link>en</Link>,
            key: "0",
        },
        {
            id: 2,
            code: "ru",
            longName: "Русский",
            label: <Link>ru</Link>,
            key: "1",
        },
        {
            id: 3,
            code: "am",
            longName: "Հայերեն",
            label: <Link>am</Link>,
            key: "3",
        },
    ];
    const handleLanguageChange = language => {
        i18next.changeLanguage(language).then(() => {
            const currentPath = window.location.pathname;
            const languagePrefix = currentPath.split("/")[1];
            let newPath;
            if (languagePrefix && languagePrefix.length === 2) {
                newPath = currentPath.replace(`/${languagePrefix}`, `/${language}`);
            } else {
                newPath = `/${language}${currentPath}`;
            }
            window.location.href = newPath;
        });
    };
    const dispatch = useDispatch();
    const basketItems = useSelector(state => state.newReducer.modalShowBasket);
    useEffect(() => {
        if (token){
            dispatch(getUserBasket({id: user?.id, token}))
        }
    }, [])
    const favoriteProjects = useSelector(state => state.newReducer.favorites);
    const theme = useSelector(state => state.newReducer.themeChange)


    const {isAuthenticated} = useSelector(state => state.auth);
    const copyLangs = items.filter(item => item.code !== lang);
    const differentLang = langs.find(
        item1 => !copyLangs.some(item2 => item1.id === item2.id)
    );

    return (
        <div
            style={{
                top: !visible ? '0px' : !canScrollUp && width > 700 ? '50px' : '0px',
                background:theme === 'ligth' ? '#fff' :''
        }}
            className={`bottomHeaderContainer container`}>
            <div className='bottomHeaderLogo'>
                <NavLink onClick={()=>window.scrollTo(0, 0)} to={`/${lang}/`}>
                    <img src={WOW_LOGO} alt='Wow logo'/>
                </NavLink>
            </div>
            <div className='bottomHeader2'>
                <div className='bottomHeaderMenu'>
                    {menu.map(m => {
                        return m.dropdown !== null ? (
                            <Dropdown
                                key={m.id}
                                menu={{
                                    items: m.dropdown,
                                }}
                                trigger={["click"]}>
                                <div>
                                    <NavLink
                                        style={{
                                            color:theme === 'ligth' ? '#fff' : ''
                                        }}
                                        // onClick={e => e.preventDefault()}
                                        to={`/${lang}${m.to}`}
                                        className='bottomHeaderShopBtn'>
                                        <Space>
                                            {m.name}
                                            <img
                                                src={ARROW_DOWN}
                                                alt='Arrow'
                                                className='bottomHeaderShopBtnImg'
                                            />
                                        </Space>
                                    </NavLink>
                                </div>
                            </Dropdown>
                        ) : (
                            <NavLink style={{
                                color:theme === 'ligth' ? '#000' : ''
                            }} to={`/${lang}${m.to}`} key={m.id}>
                                {m.name}
                            </NavLink>
                        );
                    })}
                </div>
                <div className='bottomHeaderSignIn'>
                    <div className='quickPick'>
                        <ThemeSwitcher/>
                        <button onClick={() => favorites.length > 0 ? dispatch(openFavoritesAction(true)) : null}
                                className='quickPickFavorites'>
                            <div className='quickPickBasketQuantity'>
                                {favorites?.length}
                            </div>
                            <svg width="20" height="20" viewBox="0 0 36 31" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.6029 27.0433L13.0672 26.4543L12.6029 27.0433ZM17.6667 4.69287L17.1263 5.21297C17.2677 5.35986 17.4628 5.44287 17.6667 5.44287C17.8705 5.44287 18.0656 5.35986 18.207 5.21297L17.6667 4.69287ZM22.7304 27.0433L23.1948 27.6323L22.7304 27.0433ZM13.0672 26.4543C10.5708 24.4863 7.75317 22.501 5.52329 19.9901C3.31912 17.5081 1.75 14.572 1.75 10.7536H0.25C0.25 15.0402 2.0304 18.316 4.40172 20.9861C6.74732 23.6274 9.73115 25.7345 12.1386 27.6323L13.0672 26.4543ZM1.75 10.7536C1.75 6.9818 3.88206 3.79282 6.83273 2.44406C9.73612 1.11691 13.5684 1.51642 17.1263 5.21297L18.207 4.17277C14.2652 0.0773666 9.76415 -0.54518 6.20914 1.07982C2.70141 2.68322 0.25 6.4206 0.25 10.7536H1.75ZM12.1386 27.6323C12.9969 28.3089 13.8865 29.0044 14.7803 29.5266C15.6739 30.0488 16.6429 30.4418 17.6667 30.4418V28.9418C17.0237 28.9418 16.3261 28.6926 15.5371 28.2316C14.7483 27.7706 13.9393 27.1418 13.0672 26.4543L12.1386 27.6323ZM23.1948 27.6323C25.6022 25.7345 28.586 23.6274 30.9316 20.9861C33.3029 18.316 35.0833 15.0402 35.0833 10.7536H33.5833C33.5833 14.572 32.0142 17.5081 29.81 19.9901C27.5802 22.501 24.7626 24.4863 22.2661 26.4543L23.1948 27.6323ZM35.0833 10.7536C35.0833 6.4206 32.6319 2.68322 29.1242 1.07982C25.5692 -0.54518 21.0681 0.0773666 17.1263 4.17277L18.207 5.21297C21.765 1.51642 25.5972 1.11691 28.5006 2.44406C31.4513 3.79282 33.5833 6.9818 33.5833 10.7536H35.0833ZM22.2661 26.4543C21.394 27.1418 20.5851 27.7706 19.7962 28.2316C19.0072 28.6926 18.3096 28.9418 17.6667 28.9418V30.4418C18.6904 30.4418 19.6595 30.0488 20.553 29.5266C21.4468 29.0044 22.3364 28.3089 23.1948 27.6323L22.2661 26.4543Z"
                                    fill={theme === 'dark' ?  "white" : '#000'}/>
                            </svg>
                        </button>
                        <button
                            className='quickPickBasket'
                            onClick={() =>
                                basketItems?.length > 0 && dispatch(openBasketModal())
                            }>
                            <div className='quickPickBasketQuantity'>
                                {basketItems?.length}
                            </div>
                            <svg width="20" height="20" viewBox="0 0 30 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M14.5242 2.23256C12.6746 2.23256 11.1753 3.73188 11.1753 5.5814V5.96819C12.0044 5.95347 12.9142 5.95348 13.9126 5.95349H15.1357C16.1341 5.95348 17.0439 5.95347 17.873 5.96819V5.5814C17.873 3.73188 16.3737 2.23256 14.5242 2.23256ZM20.1056 6.06947V5.5814C20.1056 2.49888 17.6067 0 14.5242 0C11.4416 0 8.94276 2.49888 8.94276 5.5814V6.06947C8.73033 6.08776 8.52505 6.10893 8.32668 6.13344C6.82347 6.31917 5.58422 6.70933 4.5315 7.583C3.47879 8.45668 2.86693 9.60281 2.40736 11.046C1.96198 12.4447 1.62485 14.2428 1.20105 16.5032L1.17027 16.6674C0.572252 19.8567 0.100967 22.3701 0.0144787 24.3516C-0.0741706 26.3826 0.22799 28.0647 1.3737 29.4452C2.51941 30.8257 4.11706 31.4327 6.12963 31.7199C8.09311 32 10.6503 32 13.8951 32H15.1531C18.398 32 20.9552 32 22.9187 31.7199C24.9313 31.4327 26.5289 30.8257 27.6746 29.4452C28.8203 28.0647 29.1225 26.3826 29.0338 24.3516C28.9473 22.3701 28.4761 19.8567 27.878 16.6674L27.8473 16.5032C27.4235 14.2429 27.0863 12.4447 26.641 11.046C26.1814 9.60281 25.5695 8.45668 24.5168 7.583C23.4641 6.70933 22.2248 6.31917 20.7216 6.13344C20.5233 6.10893 20.318 6.08776 20.1056 6.06947ZM8.60044 8.34915C7.32708 8.50648 6.55801 8.80243 5.95729 9.30098C5.35658 9.79953 4.92397 10.5009 4.53467 11.7234C4.13599 12.9754 3.82232 14.6376 3.38159 16.9881C2.76284 20.2881 2.32364 22.6451 2.24491 24.4489C2.16753 26.2219 2.4519 27.2486 3.09168 28.0195C3.73145 28.7903 4.68809 29.259 6.44499 29.5097C8.23239 29.7647 10.63 29.7674 13.9875 29.7674H15.0609C18.4183 29.7674 20.8159 29.7647 22.6033 29.5097C24.3602 29.259 25.3169 28.7903 25.9566 28.0194C26.5964 27.2486 26.8808 26.2219 26.8034 24.4489C26.7247 22.6451 26.2855 20.2881 25.6667 16.9881C25.226 14.6376 24.9123 12.9754 24.5136 11.7234C24.1243 10.5009 23.6917 9.79953 23.091 9.30098C22.4903 8.80243 21.7212 8.50648 20.4479 8.34915C19.1438 8.18803 17.4524 8.18605 15.0608 8.18605H13.9875C11.596 8.18605 9.90447 8.18803 8.60044 8.34915Z"
                                      fill={theme === 'dark' ?  "white" : '#000'}/>
                            </svg>

                        </button>
                    </div>
                    {isAuthenticated ? (
                        <NavLink
                            to={`/${lang}/account/control-panel`}
                            className='navbarProfilePicContainer'>
                            {user?.image ? <Img
                                    src={user?.image?.startsWith('https:') ? user.image : `${STORAGE_URL}${user?.image}`}
                                    alt='Avatar'/> :
                                <Img src={PERSON} alt='Avatar'/>}
                        </NavLink>
                    ) : (
                        <>
                            {trans.filter((e) => e.key === 'Sign_In').map((e) => (
                                <Button
                                    link={true}
                                    to={`/${lang}/sign-in`}
                                    text={e[`value_${lang}`]}
                                    style={{
                                        backgroundColor: theme === 'dark' ? "var(--main-bg-color-black)" : '#fff',
                                        border: "none",
                                        fontFamily: "Poppins-600, sans-serif",
                                        fontWeight: "600",
                                        color:  theme === 'dark' ? "var(--secondary-color-white)" : '#000',
                                        borderRadius: "var(--main-border-radius)",
                                    }}
                                    className='bottomHeaderSignInBtn'
                                />
                            ))}
                            {trans.filter((e) => e.key === 'Sign_Up').map((e) => (
                                <Button
                                    link={true}
                                    to={`/${lang}/sign-up`}
                                    text={e[`value_${lang}`]}
                                    style={{
                                        backgroundColor: "var(--main-color-pink)",
                                        border: "none",
                                        fontFamily: "Poppins-600, sans-serif",
                                        fontWeight: "600",
                                        borderRadius: "var(--main-border-radius)",
                                        color: "var(--secondary-color-white)",
                                    }}
                                    className='bottomHeaderRegisterBtn'
                                />
                            ))}

                        </>
                    )}
                </div>
                <nav role='navigation' className='mobileMenu'>
                    <div id='menuToggle'>
                        <input onClick={() => setMenuIsOpen(!menuIsOpen)} type='checkbox'/>
                        <div style={{
                            transition: 'all 0.2'
                        }}>
                            <span style={{
                                transform: menuIsOpen ? 'rotate(45deg)' : '',
                                background:theme === 'ligth' ? '#000' : ''
                            }}>
                            </span>
                            <span style={{
                                opacity: menuIsOpen ? 0 : 1,
                                background:theme === 'ligth' ? '#000' : ''
                            }}></span>
                            <span style={{
                                transform: menuIsOpen ? 'rotate(-45deg)' : '',
                                transformOrigin:menuIsOpen ?  '4px 6px' :'',
                                background:theme === 'ligth' ? '#000' : ''
                            }}>
                            </span>
                        </div>
                        <ul id='menu' style={{
                            transform: menuIsOpen ? 'none' : '',
                            background:theme === 'ligth' ? '#fff' : ''
                        }}>
                            {menu.map(m => {
                                return m.dropdown !== null ? (
                                    <Dropdown
                                        key={m.id}
                                        menu={{
                                            items: m.dropdown,
                                        }}
                                        trigger={["click"]}>
                                        <NavLink
                                            to={`/${lang}${m.to}`}
                                            className='bottomHeaderShopBtnMobile'>
                                            <Space>
                                                {m.name}
                                                <img
                                                    src={ARROW_DOWN}
                                                    alt='Arrow'
                                                    className='bottomHeaderShopBtnImg'
                                                />
                                            </Space>
                                        </NavLink>
                                    </Dropdown>
                                ) : (
                                    <NavLink
                                        style={{color:theme === 'ligth' ? '#000' : ''}}
                                        onClick={() => {
                                        setMenuIsOpen(false)
                                    }} key={m.id} to={`/${lang}${m.to}`}>
                                        {m.name}
                                    </NavLink>
                                );
                            })}
                            <Dropdown
                                overlayStyle={{backgroundColor: 'rgba(255, 255, 255, 0.20)'}}
                                menu={{
                                    items: copyLangs,
                                }}
                                trigger={["click"]}>
                                <button
                                    style={{
                                        background:theme === 'ligth' ?  '#000' : ''
                                    }}
                                    onClick={e => e.preventDefault()}
                                    className='topHeaderLangsBtn mobl'>
                                    <Space>
                                        {lang}
                                        <img
                                            src={ARROW_DOWN}
                                            alt='Arrow'
                                            className='topHeaderLangBtnImg'
                                        />
                                    </Space>
                                </button>
                            </Dropdown>
                        </ul>
                    </div>
                </nav>
                {width < 700 && (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div onClick={()=>window.scrollTo(0,0)} className='bottomHeaderLogoMobile'>
                            <NavLink to={`/${lang}/`} onClick={() => setMenuIsOpen(false)}>
                                <img src={WOW_LOGO} alt='Wow logo'/>
                            </NavLink>
                        </div>
                        {/*<div className='bottomHeaderUser'></div>*/}
                        <div style={{position: 'absolute', right: 10}} className='quickPick'>
                            <ThemeSwitcher/>
                            <button  onClick={() => favorites.length > 0 ? dispatch(openFavoritesAction(true)) : null} className='quickPickFavorites'>
                                <div className='quickPickBasketQuantity'>
                                    {favoriteProjects?.length}
                                </div>
                                <svg width="20" height="20" viewBox="0 0 36 31" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.6029 27.0433L13.0672 26.4543L12.6029 27.0433ZM17.6667 4.69287L17.1263 5.21297C17.2677 5.35986 17.4628 5.44287 17.6667 5.44287C17.8705 5.44287 18.0656 5.35986 18.207 5.21297L17.6667 4.69287ZM22.7304 27.0433L23.1948 27.6323L22.7304 27.0433ZM13.0672 26.4543C10.5708 24.4863 7.75317 22.501 5.52329 19.9901C3.31912 17.5081 1.75 14.572 1.75 10.7536H0.25C0.25 15.0402 2.0304 18.316 4.40172 20.9861C6.74732 23.6274 9.73115 25.7345 12.1386 27.6323L13.0672 26.4543ZM1.75 10.7536C1.75 6.9818 3.88206 3.79282 6.83273 2.44406C9.73612 1.11691 13.5684 1.51642 17.1263 5.21297L18.207 4.17277C14.2652 0.0773666 9.76415 -0.54518 6.20914 1.07982C2.70141 2.68322 0.25 6.4206 0.25 10.7536H1.75ZM12.1386 27.6323C12.9969 28.3089 13.8865 29.0044 14.7803 29.5266C15.6739 30.0488 16.6429 30.4418 17.6667 30.4418V28.9418C17.0237 28.9418 16.3261 28.6926 15.5371 28.2316C14.7483 27.7706 13.9393 27.1418 13.0672 26.4543L12.1386 27.6323ZM23.1948 27.6323C25.6022 25.7345 28.586 23.6274 30.9316 20.9861C33.3029 18.316 35.0833 15.0402 35.0833 10.7536H33.5833C33.5833 14.572 32.0142 17.5081 29.81 19.9901C27.5802 22.501 24.7626 24.4863 22.2661 26.4543L23.1948 27.6323ZM35.0833 10.7536C35.0833 6.4206 32.6319 2.68322 29.1242 1.07982C25.5692 -0.54518 21.0681 0.0773666 17.1263 4.17277L18.207 5.21297C21.765 1.51642 25.5972 1.11691 28.5006 2.44406C31.4513 3.79282 33.5833 6.9818 33.5833 10.7536H35.0833ZM22.2661 26.4543C21.394 27.1418 20.5851 27.7706 19.7962 28.2316C19.0072 28.6926 18.3096 28.9418 17.6667 28.9418V30.4418C18.6904 30.4418 19.6595 30.0488 20.553 29.5266C21.4468 29.0044 22.3364 28.3089 23.1948 27.6323L22.2661 26.4543Z"
                                        fill={theme === 'ligth' ? "#000" : '#fff'}/>
                                </svg>
                            </button>
                            <button
                                className='quickPickBasket'
                                onClick={() =>
                                    basketItems?.length > 0 && dispatch(openBasketModal())
                                }>
                                <div className='quickPickBasketQuantity'>
                                    {basketItems?.length}
                                </div>
                                <svg width="20" height="20" viewBox="0 0 30 32" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M14.5242 2.23256C12.6746 2.23256 11.1753 3.73188 11.1753 5.5814V5.96819C12.0044 5.95347 12.9142 5.95348 13.9126 5.95349H15.1357C16.1341 5.95348 17.0439 5.95347 17.873 5.96819V5.5814C17.873 3.73188 16.3737 2.23256 14.5242 2.23256ZM20.1056 6.06947V5.5814C20.1056 2.49888 17.6067 0 14.5242 0C11.4416 0 8.94276 2.49888 8.94276 5.5814V6.06947C8.73033 6.08776 8.52505 6.10893 8.32668 6.13344C6.82347 6.31917 5.58422 6.70933 4.5315 7.583C3.47879 8.45668 2.86693 9.60281 2.40736 11.046C1.96198 12.4447 1.62485 14.2428 1.20105 16.5032L1.17027 16.6674C0.572252 19.8567 0.100967 22.3701 0.0144787 24.3516C-0.0741706 26.3826 0.22799 28.0647 1.3737 29.4452C2.51941 30.8257 4.11706 31.4327 6.12963 31.7199C8.09311 32 10.6503 32 13.8951 32H15.1531C18.398 32 20.9552 32 22.9187 31.7199C24.9313 31.4327 26.5289 30.8257 27.6746 29.4452C28.8203 28.0647 29.1225 26.3826 29.0338 24.3516C28.9473 22.3701 28.4761 19.8567 27.878 16.6674L27.8473 16.5032C27.4235 14.2429 27.0863 12.4447 26.641 11.046C26.1814 9.60281 25.5695 8.45668 24.5168 7.583C23.4641 6.70933 22.2248 6.31917 20.7216 6.13344C20.5233 6.10893 20.318 6.08776 20.1056 6.06947ZM8.60044 8.34915C7.32708 8.50648 6.55801 8.80243 5.95729 9.30098C5.35658 9.79953 4.92397 10.5009 4.53467 11.7234C4.13599 12.9754 3.82232 14.6376 3.38159 16.9881C2.76284 20.2881 2.32364 22.6451 2.24491 24.4489C2.16753 26.2219 2.4519 27.2486 3.09168 28.0195C3.73145 28.7903 4.68809 29.259 6.44499 29.5097C8.23239 29.7647 10.63 29.7674 13.9875 29.7674H15.0609C18.4183 29.7674 20.8159 29.7647 22.6033 29.5097C24.3602 29.259 25.3169 28.7903 25.9566 28.0194C26.5964 27.2486 26.8808 26.2219 26.8034 24.4489C26.7247 22.6451 26.2855 20.2881 25.6667 16.9881C25.226 14.6376 24.9123 12.9754 24.5136 11.7234C24.1243 10.5009 23.6917 9.79953 23.091 9.30098C22.4903 8.80243 21.7212 8.50648 20.4479 8.34915C19.1438 8.18803 17.4524 8.18605 15.0608 8.18605H13.9875C11.596 8.18605 9.90447 8.18803 8.60044 8.34915Z"
                                          fill={theme === 'ligth' ? "#000" : '#fff'}/>
                                </svg>

                            </button>
                            <NavLink
                                onClick={() => setMenuIsOpen(false)}
                                to={isAuthenticated ? `/${lang}/account/control-panel` : `/${lang}/sign-in`}
                                className='navbarProfilePicContainer signOut'>
                                {user?.image ? <Img
                                        src={user?.image?.startsWith('https') ? user.image : `${STORAGE_URL}${user?.image}`}
                                        alt='Avatar'/> :
                                    <Img src={PERSON} alt='Avatar'/>}
                            </NavLink>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BottomHeader;
