import React from 'react';
import {STORAGE_URL} from "../../services/apiService";
import Img from "../Img";
import DELETE from "../../assets/icons/delete-white.svg";
import {useDispatch, useSelector} from "react-redux";
import {addToFavoritesAction, localAddFavorites, openFavoritesAction} from "../../redux/actions/newActions/newActions";
import currency from "../../Helpers/currency";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import BASKET_ICON from '../../assets/icons/shopping-bag.png'
import BASKET_ICON2 from '../../assets/icons/basket-without-bg.svg'
function FavoriteProduct({title, price, image, description, product_id,onBtnClick}) {
    const dispatch = useDispatch()
    const theme = useSelector(state => state.newReducer.themeChange)
    const {isAuthenticated} = useSelector(state => state.auth);
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    const navigate = useNavigate()
    const lang = Cookies.get("i18next") || "en";

    const handleDeleteFavorite = () => {

        if (isAuthenticated) {
            dispatch(addToFavoritesAction({product_id, user_id: user.id, token}))
        } else {
            dispatch(localAddFavorites({id: product_id}))
        }
    }
    return (
        <div>
            <div style={{display: "flex"}}>
                <div
                    onClick={()=> {
                        navigate(`/${lang}/shop/${product_id}`)
                        dispatch(openFavoritesAction(false))
                    }}
                    className='second'>
                    <Img style={{
                        minWidth: 120
                    }} src={`${STORAGE_URL}/${image}`} alt={title}/>
                </div>
                <div style={{marginLeft: 30, width: '90vw'}}>
                    <p style={{
                        color:theme === 'ligth' ? '#000' : ''
                    }} onClick={()=> {
                        dispatch(openFavoritesAction(false))
                        navigate(`/${lang}/shop/${product_id}`)
                    }} className='cardProductTitle'>{title}</p>
                    <p style={{width: '100%' , color:theme === 'ligth' ? '#000' : ''}} className='cardProductDescription'
                       dangerouslySetInnerHTML={{__html: description}}/>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{
                            display:'flex',
                            gap:15
                        }}>
                            <button className='cardProductDeleteBtn' onClick={onBtnClick}>
                                <Img src={BASKET_ICON2} alt='Delete'/>
                            </button>
                            <button className='cardProductDeleteBtn' onClick={handleDeleteFavorite}>
                                <Img src={DELETE} alt='Delete'/>
                            </button>
                        </div>
                        {(price && price != '0') ? (
                            <p style={{maxWidth: '' , color:theme === 'ligth' ? '#000' : ''}} className='cardProductPrice'>{currency} {price}</p>
                        ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FavoriteProduct;
