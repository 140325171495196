import Button from "../../../components/Button/Button";
import AccountLayout from "../AccountLayout";
import PLUS from "../../../assets/icons/plus-bg-white.svg";
import LOCATION from "../../../assets/icons/location-gray.svg";
import PEN from "../../../assets/icons/pen-gray.svg";
import DELETE from "../../../assets/icons/delete-gray.svg";
import "./style.css";
import Modal from "../../../components/Modal/Modal";
import {useCallback, useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import ValidationSchema from "../../../utils/ValidationSchema";
import Checkbox from "../../../components/Checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import apiService from "../../../services/apiService";
import Spinner from "../../../components/Spinner/Spinner";
import {useDispatch, useSelector} from "react-redux";
import {
    changeDefaultAddress,
    deleteAddress,
    getUserAddresses, getUserSingleAddress,
    postAddAddress,
    postPostAddAddress, updateAddress
} from "../../../redux/actions/newActions/newActions";
import {use} from "i18next";
import Cookies from "js-cookie";

const Address = () => {
    const theme = useSelector(state => state.newReducer.themeChange)

    const {t} = useTranslation();
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    // //////////////////
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUserAddresses({id: user.id, token}))
    }, []);
    const userAddresses = useSelector(state => state.newReducer.userAddresses)
    //////////////////////
    const trans = useSelector(state => state.newReducer.trans)

    const [addNewAddressModal, setAddNewAddressModal] = useState(false);
    const {newAddressSchema} = ValidationSchema();
    const [addressModal, setAddressModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedId, setSelectedId] = useState('')
    const [responseData, setResponseData] = useState(null);
    const [myAddress, setMyAddress] = useState({})
    const [isDefault, setIsDefault] = useState(false);
    const lang = Cookies.get("i18next") || "en";
    const handleUpdateAddress = useCallback(async (id) => {
        setLoading(true)
        const {payload} = await dispatch(getUserSingleAddress({id, token}))
        await setMyAddress({...payload.address})
        setSelectedId(id)
        setLoading(false)
        setAddressModal(true)
    }, [myAddress])
    return (
        <AccountLayout>
            <div className='addressContainer'>
                <div className='accountPageTitleContainer'>
                    <p style={{
                        color: theme === 'ligth' ? '#000' : ''
                    }} className='accountPageTitle'>{trans.length > 0 ? trans.filter((e)=>e.key === 'Addresses')[0][`value_${lang}`] : t("addresses")}</p>
                    <p style={{
                        color: theme === 'ligth' ? '#000' : ''
                    }} className='accountPageDescription'>{trans.length > 0 ? trans.filter((e)=>e.key === 'shipping_addresses')[0][`value_${lang}`] :t("address_page_desc")}</p>
                </div>
                <div className='addressSegment'>
                    <Button
                        text={trans.length > 0 ? trans.filter((e)=>e.key === 'Add_new_address')[0][`value_${lang}`] :t("add_new_address")}
                        icon={PLUS}
                        alt='Plus'
                        className='addNewAddressBtn'
                        style={{
                            background: "var(--main-color-pink)",
                            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.04)",
                            border: "none",
                            color: "var(--secondary-color-white)",
                        }}
                        onClick={() => setAddNewAddressModal(true)}
                    />
                    <div className='adressesWrapper'>
                        {userAddresses?.map((address) => (
                            <div
                                style={{
                                    border: address.is_default ? '1px solid #af4b85' : 'none',
                                    background: theme === 'ligth' ? '#F8F8F8' : ''
                                }}
                                className='addressWrapper' key={address.id}>
                                <div className='addressLabel'>
                                    <label htmlFor={`address_${address.title}`}>
                                        <input
                                            style={{
                                                borderColor: theme === 'ligth' ? '#000' : ''
                                            }}
                                            type='radio'
                                            id={`address_${address.title}`}
                                            name='address'
                                            checked={Boolean(address.is_default)}
                                            onChange={(e) => {
                                                dispatch(changeDefaultAddress({
                                                    id: address.id,
                                                    token,
                                                    user_id: user.id
                                                }))
                                            }}
                                        />
                                        <div>
                                            <div style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }} className='addressTitle'>
                                                {address.title}
                                                {address.is_default && <span className='primary__text'>(Primary)</span>}
                                            </div>
                                            <p style={{
                                                color: theme === 'ligth' ? '#000' : ''
                                            }} className='address'>
                                                <svg width="10" height="14" viewBox="0 0 10 14" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M5.00012 0.00188966C3.67477 0.00438703 2.40442 0.531986 1.46726 1.46915C0.530096 2.40631 0.00249736 3.67666 0 5.00201C0 6.96728 1.18484 9.12153 2.33188 10.8411C3.47892 12.5608 4.62785 13.8344 4.62785 13.8344C4.6748 13.8863 4.73209 13.9278 4.79605 13.9562C4.86001 13.9845 4.9292 13.9992 4.99917 13.9992C5.06914 13.9992 5.13834 13.9845 5.2023 13.9562C5.26625 13.9278 5.32355 13.8863 5.3705 13.8344C5.3705 13.8344 6.51943 12.5608 7.66647 10.8411C8.81351 9.12153 9.99835 6.96728 9.99835 5.00201C9.99685 3.67651 9.46989 2.40567 8.53297 1.46804C7.59606 0.530419 6.32562 0.00438976 5.00012 0.00188966ZM5.00012 0.999646C7.21672 0.999646 8.99681 2.78729 8.99681 5.00201C8.99681 6.53832 7.93859 8.6321 6.83501 10.2875C5.91662 11.6632 5.28357 12.3586 5.00201 12.6817C4.71855 12.3586 4.08362 11.6632 3.16523 10.2875C2.06354 8.6321 0.999646 6.53832 0.999646 5.00201C0.999646 2.78729 2.7854 0.999646 5.00012 0.999646ZM5.00012 3.00272C3.90221 3.00272 3.00083 3.9041 3.00083 5.00201C3.00083 6.09992 3.90221 7.0013 5.00012 7.0013C6.09992 7.0013 7.0013 6.09992 7.0013 5.00201C7.0013 3.9041 6.09992 3.00272 5.00012 3.00272ZM5.00012 4.00047C5.55947 4.00047 6.00165 4.44455 6.00165 5.00201C6.00165 5.55947 5.55947 6.00354 5.00012 6.00354C4.8684 6.00455 4.7378 5.97932 4.61594 5.92932C4.49408 5.87933 4.38339 5.80556 4.29034 5.71233C4.19729 5.61911 4.12373 5.50828 4.07397 5.38632C4.0242 5.26437 3.99921 5.13372 4.00047 5.00201C4.00047 4.44455 4.44077 4.00047 5.00012 4.00047Z"
                                                        fill={theme === 'ligth' ? '#000' : 'white'}/>
                                                </svg>
                                                {`${address.city}, ${address.address}`}
                                            </p>
                                        </div>
                                    </label>
                                </div>
                                <div className='addressActionPanel'>
                                    <button onClick={() => handleUpdateAddress(address.id)} className='addressEditBtn'>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.5517 2.86437L13.9575 1.45771C14.2506 1.16464 14.648 1 15.0625 1C15.477 1 15.8744 1.16464 16.1675 1.45771C16.4606 1.75077 16.6252 2.14825 16.6252 2.56271C16.6252 2.97716 16.4606 3.37464 16.1675 3.66771L7.31833 12.5169C6.87777 12.9572 6.33447 13.2808 5.7375 13.4585L3.5 14.1252L4.16667 11.8877C4.3444 11.2907 4.66803 10.7474 5.10833 10.3069L12.5517 2.86437ZM12.5517 2.86437L14.75 5.06271M13.5 10.7919V14.7502C13.5 15.2475 13.3025 15.7244 12.9508 16.076C12.5992 16.4277 12.1223 16.6252 11.625 16.6252H2.875C2.37772 16.6252 1.90081 16.4277 1.54917 16.076C1.19754 15.7244 1 15.2475 1 14.7502V6.00021C1 5.50293 1.19754 5.02601 1.54917 4.67438C1.90081 4.32275 2.37772 4.12521 2.875 4.12521H6.83333"
                                                stroke={theme === 'ligth' ? "#000" : '#888888'} stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    <button onClick={() => dispatch(deleteAddress({id: address.id, token}))}
                                            className='addressDeleteBtn'>
                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.1583 6.62543L9.87 14.1254M5.88 14.1254L5.59167 6.62543M13.8983 3.95043C14.1833 3.99377 14.4667 4.0396 14.75 4.08877M13.8983 3.95043L13.0083 15.5196C12.972 15.9906 12.7592 16.4306 12.4125 16.7515C12.0658 17.0724 11.6108 17.2506 11.1383 17.2504H4.61167C4.13925 17.2506 3.68419 17.0724 3.33748 16.7515C2.99078 16.4306 2.77799 15.9906 2.74167 15.5196L1.85167 3.95043M13.8983 3.95043C12.9366 3.80503 11.9698 3.69468 11 3.6196M1.85167 3.95043C1.56667 3.99293 1.28333 4.03877 1 4.08793M1.85167 3.95043C2.81344 3.80503 3.7802 3.69468 4.75 3.6196M11 3.6196V2.85627C11 1.87293 10.2417 1.05293 9.25833 1.0221C8.33635 0.992633 7.41365 0.992633 6.49167 1.0221C5.50833 1.05293 4.75 1.87377 4.75 2.85627V3.6196M11 3.6196C8.91977 3.45883 6.83023 3.45883 4.75 3.6196"
                                                stroke={theme === 'ligth' ? "#000" : '#888888'} stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Modal
                title={trans.length > 0 ? trans.filter((e)=>e.key === 'Add_new_address')[0][`value_${lang}`] :t("add_new_address")}
                open={addNewAddressModal}
                onClose={() => setAddNewAddressModal(false)}>
                <Formik
                    initialValues={{
                        country: "",
                        city: "",
                        address: "",
                        zip_code: "",
                        title: ""
                    }}
                    onSubmit={async (values, actions) => {
                        setLoading(true)
                        if (isDefault) {
                            localStorage.setItem('user', JSON.stringify({
                                ...user,
                                country: values.country,
                                address: values.address,
                                region: values.city
                            }))
                        }
                        await dispatch(postAddAddress({...values, id: user.id, token, is_default: isDefault}))
                        actions.setSubmitting(false);
                        setLoading(false)
                        setAddNewAddressModal(false)
                        setIsDefault(false)
                        setMyAddress({})
                        actions.resetForm()
                    }}
                    validationSchema={newAddressSchema}
                >
                    {() => (
                        <Form id='newAddressForm'>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : '',
                                    }}
                                    htmlFor='title'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'title')[0][`value_${lang}`] :t("title")} <span>*</span>
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background: theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='title'
                                        placeholder={t("placeholder.enter_your_title")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='title'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : '',
                                    }} htmlFor='country'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Country')[0][`value_${lang}`] :t("country")} <span>*</span>
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background: theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='country'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter your country')[0][`value_${lang}`] :t("placeholder.enter_your_country")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='country'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='city'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'City')[0][`value_${lang}`] :t("city")} <span>*</span>
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background: theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='city'
                                        placeholder={t("placeholder.enter_your_city")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='city'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='address'>
                                    {t("address")} <span>*</span>
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background: theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='address'
                                        placeholder={t("placeholder.enter_your_address")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='address'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='zip_code'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Zip_Code')[0][`value_${lang}`] :t("zip_code")} <span>*</span>
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background: theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='zip_code'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_zip_code')[0][`value_${lang}`] :t("placeholder.enter_your_zip_code")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='zip_code'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <Checkbox
                                text={trans.length > 0 ? trans.filter((e)=>e.key === 'address_default')[0][`value_${lang}`] :t("make_address_default")}
                                uniqueId='make_address_default'
                                checked={isDefault}
                                onChange={e => setIsDefault(e.target.checked)}
                            />
                            <Button
                                text={
                                    loading ? (
                                        <Spinner color='#fff' size={18}/>
                                    ) : (
                                        (trans.length > 0 ? trans.filter((e)=>e.key === 'Add_new_address')[0][`value_${lang}`] :t("add_address"))
                                    )
                                }
                                btnType='submit'
                                className='addAddressBtn'
                                style={{
                                    background: "var(--main-color-pink)",
                                    color: "var(--secondary-color-white)",
                                    border: "none",
                                    fontFamily: "Poppins-600, sans-serif",
                                    fontWeight: "600",
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            </Modal>
            {Object.keys(myAddress).length > 0 && (<Modal
                title={t("update_address")}
                open={addressModal}
                onClose={() => {
                    setMyAddress({})
                    setAddressModal(false)
                    setIsDefault(false)
                }}>
                <Formik
                    initialValues={{
                        country: myAddress.country || "",
                        city: myAddress.city || "",
                        address: myAddress.address || "",
                        zip_code: myAddress.zip_code || "",
                        title: myAddress.title || ""
                    }}
                    onSubmit={async (values, actions) => {
                        setLoading(true)
                        await dispatch(updateAddress({...values, id: selectedId, is_default: isDefault, token}))
                        setMyAddress({})
                        setLoading(false)
                        setAddressModal(false)
                        setIsDefault(false)
                        actions.setSubmitting(false);
                    }}
                    validationSchema={newAddressSchema}>
                    {() => (
                        <Form id='newAddressForm'>
                            <div className='newAddresFormGroup'>
                                <label style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} htmlFor='title'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'title')[0][`value_${lang}`] :t("title")} <span>*</span>
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme ==='ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='title'
                                        placeholder={t("placeholder.enter_your_title")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='title'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label style={{
                                    color: theme === 'ligth' ? '#000' : ''
                                }} htmlFor='country'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Country')[0][`value_${lang}`] :t("country")} <span>*</span>
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme ==='ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='country'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter your country')[0][`value_${lang}`] :("placeholder.enter_your_country")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='country'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='city'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'City')[0][`value_${lang}`] :t("city")} <span>*</span>
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme ==='ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='city'
                                        placeholder={t("placeholder.enter_your_city")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='city'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='address'>
                                    {t("address")} <span>*</span>
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme ==='ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='address'
                                        placeholder={t("placeholder.enter_your_address")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='address'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <div className='newAddresFormGroup'>
                                <label
                                    style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='zip_code'>
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Zip_Code')[0][`value_${lang}`] :t("zip_code")} <span>*</span>
                                </label>
                                <div className='newAddresFormGroupInput'>
                                    <Field
                                        style={{
                                            color: theme === 'ligth' ? '#000' : '',
                                            background:theme ==='ligth' ? 'rgb(248, 248, 248)' : ''
                                        }}
                                        type='text'
                                        name='zip_code'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_zip_code')[0][`value_${lang}`] :t("placeholder.enter_your_zip_code")}
                                    />
                                </div>
                                <ErrorMessage
                                    name='zip_code'
                                    component='p'
                                    className='newAddressError'
                                />
                            </div>
                            <Checkbox
                                text={trans.length > 0 ? trans.filter((e)=>e.key === 'address_default')[0][`value_${lang}`] :t("make_address_default")}
                                uniqueId='make_address_default'
                                checked={isDefault}
                                onChange={e => setIsDefault(e.target.checked)}
                            />
                            <Button
                                text={
                                    loading ? (
                                        <Spinner color='#fff' size={18}/>
                                    ) : (
                                        (t("update_address"))
                                    )
                                }
                                btnType='submit'
                                className='addAddressBtn'
                                style={{
                                    background: "var(--main-color-pink)",
                                    color: "var(--secondary-color-white)",
                                    border: "none",
                                    fontFamily: "Poppins-600, sans-serif",
                                    fontWeight: "600",
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            </Modal>)}
        </AccountLayout>
    );
};

export default Address;
