import PagesTitle from "../../components/Title/PagesTitle";
import Partners from "../../components/Partners/Partners";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import Img from "../../components/Img";
// import ABOUT_US_PAGE_1 from "../assets/ui-fake-images/about-us-page-1.jpg";
// import ABOUT_US_PAGE_2 from "../../assets/ui-fake-images/about-us-page-2.jpg";
import AboutCompany from "../../components/AboutCompany/AboutCompany";
// import CountingNumbers from "../../components/CountingNumbers/CountingNumbers";
import Footer from "../../components/Footer/Footer";
import MeetUs from "../../components/MeetUs/MeetUs";
import {motion} from "framer-motion";
import {animate, initial} from "../../utils/transition";
import {useDispatch, useSelector} from "react-redux";
import {fetchPartners} from "../../redux/actions/partnersActions";
import {useEffect} from "react";
import Spinner from "../../components/Spinner/Spinner";
import {fetchAboutUs} from "../../redux/actions/aboutUsActions";
import {removeHtmlTags} from "../../Helpers/removeHtmlTags";
import {STORAGE_URL} from "../../services/apiService";
import Reviews from "../../components/Reviews/Reviews";
import {fetchReviews} from "../../redux/actions/reviewsActions";
import Blog from "../../components/Blog/Blog";
import Button from "../../components/Button/Button";
import "./style.css";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import LOCATION from "../../assets/icons/location-white.svg";
import TIME from "../../assets/icons/time-white.svg";
import SecondButton from "../../components/SecondButton/SecondButton";
import {getCarers, getTranslations} from "../../redux/actions/newActions/newActions";
import {useWindowSize} from "../../hooks/useWindowSize";
import {Helmet} from 'react-helmet';

const AboutUs = () => {
    const dispatch = useDispatch();
    const lang = Cookies.get("i18next") || "en";
    const {t} = useTranslation();
    const {width} = useWindowSize()
    const career_mainData = useSelector(state => state.newReducer.career_mainData)
    useEffect(() => {
        dispatch(getCarers())
        dispatch(fetchPartners());
        dispatch(fetchAboutUs());
        dispatch(fetchReviews());
        dispatch(getTranslations())
    }, [dispatch]);
    const trans = useSelector(state => state.newReducer.trans)
    const partners = useSelector(state => state.partners);
    const about = useSelector(state => state.about);
    const reviews = useSelector(state => state.reviews);
    const theme = useSelector(state => state.newReducer.themeChange)

    if (partners.partners === null &&
        about.about === null &&
        reviews.reviews === null) {
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>About Us</title>
            </Helmet>
            <motion.div
                initial={initial}
                animate={animate}
                className='aboutUsContainer'>
                {about.about && partners.partners && reviews.reviews && (
                    <>
                        <PagesTitle
                            title={about.about.about_main[`title_${lang}`]}
                            description={removeHtmlTags(
                                about.about.about_main[`description_${lang}`]
                            )}
                        />
                        <Img
                            src={`${STORAGE_URL}/${about.about.about_main.image}`}
                            alt='Drawing'
                            style={{
                                width: "100%",
                                height: "450px",
                                objectFit: "cover",
                            }}
                        />
                        <Partners partners={partners.partners}/>
                        <AboutCompany
                            // countingNumbers={true}
                            social_responsibility={about.about.about_main.social_responsibility}
                            customer_savings={about.about.about_main.customer_savings}
                            downloads={about.about.about_main.downloads}
                            hours_saved={about.about.about_main.hours_saved}
                            year_growth={about.about.about_main.year_growth}
                            className='aboutUsPageAboutCompanyContainer'
                            title={about.about.about_main[`secondary_title_${lang}`]}
                            description={removeHtmlTags(
                                about.about.about_main[`secondary_description_${lang}`]
                            )}
                            btn={null}
                            img={`${STORAGE_URL}/${about.about.about_main.secondary_image}`}
                        />
                        <Reviews reviews={reviews.reviews}/>
                        <WhyChooseUs why_choose_us={about.about.why_choose_us}/>
                        <MeetUs meet_us={about.about.meet_us}/>
                        <Blog/>
                        {career_mainData[career_mainData?.length - 1] ? (
                            <div style={{background: theme === 'ligth' ? "#EFEFEF" : 'rgba(255, 255, 255, 0.2)'}}
                                 className='hiringContainer'>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <p style={{
                                        fontSize: 30,
                                        color: theme === 'ligth' ? "#000" : "#fff",
                                        marginTop: 0,
                                        fontWeight: 'regular'
                                    }}
                                       className='hiringTitle'>{career_mainData[career_mainData.length - 1][`title_${lang}`]}
                                    </p>
                                    <SecondButton
                                        className='moreBtnLarge'
                                        to={`/career/${career_mainData[career_mainData.length - 1].id}`}
                                    />
                                </div>
                                <p
                                    className='hiringDescription'
                                    style={{textAlign: 'left', color: theme === 'ligth' ? "#000" : "#fff",}}
                                    dangerouslySetInnerHTML={{__html: career_mainData[career_mainData.length - 1][`description_${lang}`]}}
                                />
                                <div className='vacancyInfo' style={{
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                    flexDirection: width < 700 ? 'column' : 'row',
                                    marginLeft: -100
                                }}>
                                    <div style={{display: 'flex'}}>
                                        <svg width="18" height="22" viewBox="0 0 18 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1 9.14329C1 4.64588 4.58172 1 9 1C13.4183 1 17 4.64588 17 9.14329C17 13.6055 14.4467 18.8124 10.4629 20.6744C9.53426 21.1085 8.46574 21.1085 7.53707 20.6744C3.55332 18.8124 1 13.6055 1 9.14329Z"
                                                stroke={theme === 'ligth' ? "black" : 'white'} stroke-width="1.5"/>
                                            <circle cx="9" cy="9" r="3" stroke={theme === 'ligth' ? "black" : 'white'}
                                                    stroke-width="1.5"/>
                                        </svg>
                                        <p style={{
                                            color: theme === 'ligth' ? "#000" : "#fff",
                                        }}
                                           className='hiringDescription__location'>{career_mainData[career_mainData.length - 1][`location_${lang}`]}</p>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11" cy="11" r="10"
                                                    stroke={theme === 'ligth' ? "black" : 'white'} stroke-width="1.5"/>
                                            <path d="M11 7V11L13.5 13.5" stroke={theme === 'ligth' ? "black" : 'white'}
                                                  stroke-width="1.5" stroke-linecap="round"
                                                  stroke-linejoin="round"/>
                                        </svg>
                                        <p style={{
                                            color: theme === 'ligth' ? "#000" : "#fff",
                                        }}>{career_mainData[career_mainData.length - 1].type}</p>
                                    </div>
                                </div>
                            </div>) : null}
                        <Footer slider={false}/>
                    </>
                )}
            </motion.div>
        </>
    );
};

export default AboutUs;
