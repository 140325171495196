import {createReducer} from '@reduxjs/toolkit';
import {
    addBasket,
    changeDefaultAddress,
    clearFilter,
    deleteAddress,
    getCarers,
    getCustomPageData,
    getEmail,
    getInteriorPage,
    getMainInfo,
    getPhone,
    getPolicy,
    getShopFilteredItems,
    getShopStandardItems,
    getSingleCarer,
    getSingleProduct,
    getTranslations,
    getUserAddresses,
    localBasketAdd,
    menuToogle,
    postAddAddress,
    postSubscribe,
    postUserUpdate,
    updateAddress,
    getUserBasket,
    selectedItems,
    selectedItemsAdd,
    productCartIncrement,
    productCartDecrement,
    deleteFromCart,
    selectedItemAll,
    getTotalPrice,
    thanksMessage,
    getOrders,
    openFavoritesAction,
    getFavorites,
    addToFavoritesAction,
    deleteFromLocalBasket,
    localBasketIncrement,
    localBasketDecrement,
    localAddFavorites,
    loginModalInfo,
    cheCkError,
    themeChange
} from "../actions/newActions/newActions";
import Cookies from "js-cookie";

const initialState = {
    product_categories: [],
    product_colors: [],
    product_materials: [],
    product_types: [],
    products_data: [],
    filteredProducts_data: [],
    custom_production_main: {},
    timetables: [],
    workingProcesses: [],
    policePage: [],
    career_categories: [],
    career_mainData: [],
    career_main: {},
    status: 'pending',
    singleCarer: {},
    basketItems: [],
    interior_design_main: {},
    interior_workingProcesses: [],
    interior_packages: [],
    singleProduct: {},
    shopTotalPages: 1,
    shopMessage: '',
    phone: '',
    email: '',
    main_info: {},
    menuFlag: false,
    subscribeMessage: '',
    trans: [],
    userAddresses: [],
    // localBasket: JSON.parse(localStorage.getItem('user'))?.cart ||  []
    localBasket: JSON.parse(localStorage.getItem('localBasket')) || [],
    modalShowBasket: JSON.parse(localStorage.getItem('localBasket')) || [],
    basketSelected: JSON.parse(localStorage.getItem('basketSelected')) || [],
    basketTotalPrice: 0,
    orderMessage: '',
    userOrders: [],
    openFavorites: false,
    favorites: JSON.parse(localStorage.getItem('localFavorites')) || [],
    localFavorites: JSON.parse(localStorage.getItem('localFavorites')) || [],
    loginModal: false,
    singleLoadin: 'pending',
    testLoading: '',
    checkErrorText:'',
    max_price:0,
    min_price:0,
    shopLoader:"pending",
    themeChange:localStorage.getItem('theme') || 'ligth'
};
const user = JSON.parse(localStorage.getItem("user"));
const lang = Cookies.get("i18next") || "en";

export default createReducer(initialState, (builder) => {
    builder
        .addCase(getShopStandardItems.fulfilled, (state, action) => {
            state.product_categories = action.payload.product_categories
            state.product_colors = action.payload.product_colors
            state.product_materials = action.payload.product_materials
            state.product_types = action.payload.product_types
            // state.products_data = action.payload.products
            // state.shopTotalPages = action.payload.total_pages
        })
        .addCase(getShopFilteredItems.fulfilled, (state, action) => {
            state.shopLoader='ok'
            state.filteredProducts_data = action.payload.products
            state.shopTotalPages = action.payload.total_pages
            state.max_price = action.payload.max_price
            state.min_price = action.payload.min_price
            state.shopMessage = ''
        })
        .addCase(getShopFilteredItems.rejected, (state, action) => {
            state.shopLoader='ok'
                state.filteredProducts_data = [];
                state.shopMessage = action?.payload[`message_${lang}`]
        })
        .addCase(getShopFilteredItems.pending, (state, action) => {
            state.shopLoader='pending'
        })
        .addCase(clearFilter, (state) => {
            state.filteredProducts_data = null
        })
        .addCase(getCustomPageData.fulfilled, (state, action) => {
            state.custom_production_main = action.payload.custom_production_main;
            state.timetables = action.payload.timetables;
            state.workingProcesses = action.payload.workingProcesses;
        })
        .addCase(getPolicy.fulfilled, (state, action) => {
            state.policePage = action.payload.policy_secctions
        })
        .addCase(getCarers.fulfilled, (state, action) => {
            state.career_categories = action.payload.career_categories
            state.career_mainData = action.payload.career_main.careers
            state.career_main = action.payload.career_main
            state.status = 'ok'
        })
        .addCase(getCarers.pending, (state) => {
            state.status = 'pending'
        })
        .addCase(getSingleCarer.fulfilled, (state, action) => {
            state.singleCarer_category = action.payload.career.category
            state.singleCarer = action.payload.career
        })
        .addCase(getInteriorPage.fulfilled, (state, action) => {
            state.interior_design_main = action.payload.interior_design_main
            state.interior_workingProcesses = action.payload.workingProcesses
            state.interior_packages = action.payload.packages
        })
        .addCase(getSingleProduct.fulfilled, (state, action) => {
            state.singleProduct = action.payload
            state.singleLoadin = 'ok'
        })
        .addCase(getSingleProduct.pending, (state, action) => {
            state.singleLoadin = 'pending'
        })
        .addCase(getSingleProduct.rejected, (state, action) => {
            state.singleLoadin = 'rejected'
        })
        .addCase(getPhone.fulfilled, (state, action) => {
            state.phone = action.payload.phone
        })
        .addCase(getEmail.fulfilled, (state, action) => {
            state.email = action.payload.email
        })
        .addCase(getMainInfo.fulfilled, (state, action) => {
            state.main_info = action.payload.main_info
        })
        .addCase(menuToogle, (state) => {
            state.menuFlag = true
        })
        .addCase(postSubscribe.fulfilled, (state, action) => {
            state.subscribeMessage = 'You are subscribed'
        })
        .addCase(postSubscribe.rejected, (state, action) => {
            state.subscribeMessage = 'You are already subscribed'
        })
        .addCase(postUserUpdate.fulfilled, (state, action) => {
            window.localStorage.setItem('user', JSON.stringify({...action.payload.user}))
        })
        .addCase(getTranslations.fulfilled, (state, action) => {
            state.trans = action.payload.translations
        })
        .addCase(getUserAddresses.fulfilled, (state, action) => {
            state.userAddresses = action.payload.addresses
        })
        .addCase(deleteAddress.fulfilled, (state, action) => {
            state.userAddresses = action.payload.addresses
        })
        .addCase(updateAddress.fulfilled, (state, action) => {
            state.userAddresses = action.payload.addresses
        })
        .addCase(postAddAddress.fulfilled, (state, action) => {
            state.userAddresses = action.payload.addresses
        })
        .addCase(changeDefaultAddress.fulfilled, (state, action) => {
            const {address,country,city} = action.payload.address
            localStorage.setItem('user' ,JSON.stringify({...user ,
                country:country,
                address:address,
                region:city
            }))
            const updatedAddress = action.payload.address;
            const id = action.payload.address.id
            state.userAddresses = state.userAddresses.map((e) => {
                if (e.id === id) {
                    e.is_default = updatedAddress.is_default
                    e.title = updatedAddress.title
                    e.country = updatedAddress.country
                    e.city = updatedAddress.city
                    e.address = updatedAddress.address
                    e.zip_code = updatedAddress.zip_code
                    e.is_default = true
                } else {
                    e.is_default = false
                }
                return e
            })
        })
        .addCase(getUserBasket.fulfilled, (state, action) => {
            state.modalShowBasket = action.payload.cart
        })
        .addCase(getUserBasket.rejected, (state, action) => {
            if (action?.payload?.message === 'Unauthenticated.') {
                state.loginModal = true
                localStorage.clear()
            }
        })
        .addCase(addBasket.fulfilled, (state, action) => {
            state.modalShowBasket = action.payload.cart
            if (!state.basketSelected.includes(action.payload.cart[action.payload.cart.length - 1].id)) {
                state.basketSelected = [...state.basketSelected, action.payload.cart[action.payload.cart.length - 1].id]
            }
            localStorage.setItem('basketSelected', JSON.stringify(state.basketSelected));
        })
        .addCase(addBasket.rejected, (state, action) => {
            if (action?.payload?.message === 'Unauthenticated.') {
                state.loginModal = true
            }
        })
        .addCase(selectedItemsAdd, (state, action) => {
            if (!state.basketSelected.includes(action.payload)) {
                state.basketSelected = [...state.basketSelected, action.payload]
            } else {
                state.basketSelected = state.basketSelected.filter((e) => e !== action.payload)
            }
            localStorage.setItem('basketSelected', JSON.stringify(state.basketSelected));
            const mainPrice = state.modalShowBasket
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.sumar_price, 0)
            const localPrice = state.modalShowBasket
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.price, 0)
            const localNewPrice = state.modalShowBasket
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.newPrice, 0)
            if (!isNaN(mainPrice)) {
                state.basketTotalPrice = mainPrice
            } else if (!isNaN(localNewPrice)) {
                state.basketTotalPrice = localNewPrice
            } else if (!isNaN(localPrice)) {
                state.basketTotalPrice = localPrice
            }
        })
        .addCase(selectedItemAll, (state, action) => {
            if (state.basketSelected) {
                if (state.basketSelected.length === state.modalShowBasket.length) {
                    state.basketSelected = []
                } else {
                    state.basketSelected = state.modalShowBasket.map((e) => {
                        return e.id
                    })
                }
            }
            localStorage.setItem('basketSelected', JSON.stringify(state.basketSelected));
            const mainPrice = state.modalShowBasket
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.sumar_price, 0)
            const localPrice = state.modalShowBasket
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.price, 0)
            const localNewPrice = state.modalShowBasket
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.newPrice, 0)
            if (!isNaN(mainPrice)) {
                state.basketTotalPrice = mainPrice
            } else if (!isNaN(localNewPrice)) {
                state.basketTotalPrice = localNewPrice
            } else if (!isNaN(localPrice)) {
                state.basketTotalPrice = localPrice
            }
            // state.basketTotalPrice = state.modalShowBasket
            //     .filter(item => state.basketSelected.includes(item.id))
            //     .reduce((total, item) => total + item.sumar_price, 0);
        })
        .addCase(productCartIncrement.fulfilled, (state, action) => {
            state.modalShowBasket = action.payload.cart
            state.basketTotalPrice = action.payload.cart
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.sumar_price, 0);
        })
        .addCase(productCartDecrement.fulfilled, (state, action) => {
            state.modalShowBasket = action.payload.cart
            state.basketTotalPrice = action.payload.cart
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.sumar_price, 0);
        })
        .addCase(deleteFromCart.fulfilled, (state, action) => {
            state.modalShowBasket = action.payload.carts
            state.basketSelected = state.basketSelected.filter((e) => e !== action.payload.deleted_cart_id)
            localStorage.setItem('basketSelected', JSON.stringify(state.basketSelected));
            state.basketTotalPrice = action.payload.carts
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.sumar_price, 0);
        })
        .addCase(getTotalPrice, (state) => {
            const mainPrice = state.modalShowBasket
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.sumar_price, 0)
            const localPrice = state.modalShowBasket
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.price, 0)
            const localNewPrice = state.modalShowBasket
                .filter(item => state.basketSelected.includes(item.id))
                .reduce((total, item) => total + item.newPrice, 0)
            if (!isNaN(mainPrice)) {
                state.basketTotalPrice = mainPrice
            } else if (!isNaN(localNewPrice)) {
                state.basketTotalPrice = localNewPrice
            } else if (!isNaN(localPrice)) {
                state.basketTotalPrice = localPrice
            }
        })
        .addCase(thanksMessage.fulfilled, (state, action) => {
            state.modalShowBasket = action.payload.cart
            state.orderMessage = action?.payload?.message
            localStorage.removeItem('basketSelected')
        })
        .addCase(thanksMessage.pending, (state, action) => {
            state.testLoading = 'pending'
        }).addCase(thanksMessage.rejected, (state, action) => {
        state.testLoading = 'rejected'
    })
        .addCase(getOrders.fulfilled, (state, action) => {
            state.userOrders = action.payload.orders
        })
        .addCase(localBasketAdd, (state, action) => {
            const existingItemIndex = state.localBasket.findIndex(item => item.id === action.payload.id);
            if (existingItemIndex !== -1) {
                const updatedBasket = state.localBasket.map((item, index) => {
                    if (index === existingItemIndex) {
                        return {
                            ...item,
                            quantity: item.quantity + action.payload.quantity,
                            price: item.price + action.payload.price
                        };
                    }
                    return item;
                });

                state.localBasket = updatedBasket;
            } else {
                state.localBasket = [...state.localBasket, action.payload];
            }
            localStorage.setItem("localBasket", JSON.stringify(state.localBasket));
            state.modalShowBasket = state.localBasket
        })
        .addCase(openFavoritesAction, (state, action) => {
            state.openFavorites = action.payload
        })
        .addCase(getFavorites.fulfilled, (state, action) => {
            state.favorites = action.payload.favorites
        })
        .addCase(getFavorites.rejected, (state, action) => {
            if (action?.payload?.message === 'Unauthenticated.') {
                state.loginModal = true
                window.localStorage.clear()
            }
            // state.favorites = action.payload.favorites
        })
        .addCase(addToFavoritesAction.fulfilled, (state, action) => {
            state.favorites = action.payload.favorites
        })
        .addCase(addToFavoritesAction.rejected, (state, action) => {
            if (action?.payload?.message === "Unauthenticated.") {
                state.loginModal = true
            }
        })
        .addCase(deleteFromLocalBasket, (state, action) => {
            state.localBasket = state.localBasket.filter((e) => e.id !== action.payload.product_id)
            if (state.localBasket?.length === 0) {
                state.basketSelected = []
                localStorage.setItem("basketSelected", JSON.stringify(state.basketSelected));
            }
            localStorage.setItem("localBasket", JSON.stringify(state.localBasket));
            state.modalShowBasket = state.localBasket
        })
        .addCase(localBasketIncrement, (state, action) => {
            state.localBasket = state.localBasket.map((e) => {
                if (e.id === action.payload.id) {
                    const newQuantity = e.quantity + 1;
                    const newPrice = e.price * newQuantity;
                    return {
                        ...e,
                        quantity: newQuantity,
                        newPrice,
                    };
                }
                return e;
            });

            localStorage.setItem("localBasket", JSON.stringify(state.localBasket));
            state.modalShowBasket = state.localBasket;
        })
        .addCase(localBasketDecrement, (state, action) => {
            state.localBasket = state.localBasket.map((e) => {
                if (e.id === action.payload.id) {
                    const newQuantity = e.quantity - 1;
                    const newPrice = e.price * newQuantity;

                    return {
                        ...e,
                        quantity: newQuantity,
                        newPrice,
                    };
                }
                return e;
            });

            localStorage.setItem("localBasket", JSON.stringify(state.localBasket));
            state.modalShowBasket = state.localBasket;
        })
        .addCase(localAddFavorites, (state, action) => {
            const existingIndex = state.localFavorites.findIndex(item => item.id === action.payload.id);

            if (existingIndex !== -1) {
                state.localFavorites = state.localFavorites.filter(item => item.id !== action.payload.id);
            } else {
                state.localFavorites = [...state.localFavorites, action.payload];
            }
            state.favorites = state.localFavorites
            localStorage.setItem('localFavorites', JSON.stringify(state.localFavorites))
        })
        .addCase(loginModalInfo, (state, action) => {
            state.loginModal = action.payload
        })
        .addCase(cheCkError, (state, action) => {
            state.checkErrorText = action.payload
        })
        .addCase(themeChange, (state , action)=>{
            state.themeChange = action.payload
            localStorage.setItem('theme' , action.payload)
        })

    // .addCase(localBasketAdd,(state, action)=>{
    //    state.localBasket = [...state.localBasket,action.payload]
    // })

});

