export const FETCH_PORTFOLIO_SUCCESS = "FETCH_PORTFOLIO_SUCCESS";
export const FETCH_PORTFOLIO_ERROR = "FETCH_PORTFOLIO_ERROR";
export const FETCH_PORTFOLIO_LOADING = "FETCH_PORTFOLIO_LOADING";

export const FETCH_WORK_CATEGORIES_SUCCESS = "FETCH_WORK_CATEGORIES_SUCCESS";
export const FETCH_WORK_CATEGORIES_ERROR = "FETCH_WORK_CATEGORIES_ERROR";
export const FETCH_WORK_CATEGORIES_LOADING = "FETCH_WORK_CATEGORIES_LOADING";

export const FETCH_WORK_TYPES_SUCCESS = "FETCH_WORK_TYPES_SUCCESS";
export const FETCH_WORK_TYPES_ERROR = "FETCH_WORK_TYPES_ERROR";
export const FETCH_WORK_TYPES_LOADING = "FETCH_WORK_TYPES_LOADING";
