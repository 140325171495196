import {useState} from "react";
import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {
    decreaseQuantity,
    increaseQuantity,
} from "../../redux/actions/basketActions";
import {
    localBasketDecrement,
    localBasketIncrement,
    productCartDecrement,
    productCartIncrement
} from "../../redux/actions/newActions/newActions";

const QauntityField = ({quantity, id, product_id, price}) => {
    const theme = useSelector(state => state.newReducer.themeChange)
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token')
    const dispatch = useDispatch();
    const {isAuthenticated} = useSelector(state => state.auth);
    return (
        <div style={{}} className='quantity-field'>
            <button
                style={{
                    color: theme === 'ligth' ? '#000' : '',
                    background: theme === 'ligth' ? 'rgb(248, 248, 248)' : '',
                    border:theme === 'ligth' ? '1px solid #000' : ''
                }}
                className='value-button decrease-button'
                onClick={() => {
                    if (quantity > 1) {
                        if (isAuthenticated) {
                            dispatch(productCartDecrement({user_id: user?.id, product_id: product_id, token}))
                        } else {
                            dispatch(localBasketDecrement({id: product_id}))
                        }
                    }
                }}>
                -
            </button>
            {/* <div className='number'>{value}</div> */}
            <input
                style={{
                    color: theme === 'ligth' ? '#000' : '',
                    background: theme === 'ligth' ? 'rgb(248, 248, 248)' : '',
                    borderTop:theme === 'ligth' ? '1px solid #000' : '',
                    borderBottom:theme === 'ligth' ? '1px solid #000' : '',
                }}
                value={quantity}
                className='number'
                readOnly
                // onChange={e => setValue(Number(e.target.value))}
            />
            <button
                style={{
                    color: theme === 'ligth' ? '#000' : '',
                    background: theme === 'ligth' ? 'rgb(248, 248, 248)' : '',
                    border:theme === 'ligth' ? '1px solid #000' : ''
                }}
                className='value-button increase-button'
                onClick={() => isAuthenticated ?
                    dispatch(productCartIncrement({user_id: user?.id, product_id: product_id, token})) :
                    dispatch(localBasketIncrement({id: product_id, price}))
                }>
                +
            </button>
        </div>
    );
};

export default QauntityField;
