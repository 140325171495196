import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

function GoogleAnalytics() {
    const location = useLocation();
    useEffect(() => {
        const gtag = window.gtag;
        if (typeof gtag === 'function') {
            gtag('config', 'G-DQXRYG35BW', {
                'page_title': document.title,
                'page_location': window.location.href,
                'page_path': location.pathname
            });
        }
        const ym = window.ym
        if (typeof ym === 'function') {
            ym(96661586,'hit',window.location.href)
        }
    }, [location]);

    return null;
}

export default GoogleAnalytics;
