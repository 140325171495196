import React, {Fragment, useEffect, useState} from 'react';
import "./style.css";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import {useWindowSize} from "../../hooks/useWindowSize";
import {STORAGE_URL} from "../../services/apiService";
import {
    addBasket, addToFavoritesAction,
    getShopFilteredItems,
    getShopStandardItems, localAddFavorites, localBasketAdd, postStoreRequirements
} from "../../redux/actions/newActions/newActions";
import {animate, initial} from "../../utils/transition";
import {motion} from "framer-motion";
import PagesTitle from "../../components/Title/PagesTitle";
import Filter from "./Filter/Filter";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Checkbox from "../../components/Checkbox/Checkbox";
import {Button, Slider} from "antd";
import Img from "../../components/Img";
import SEARCH from "../../assets/icons/search-pink.svg";
import ARROW from "../../assets/icons/arrow-down-white.svg";
import {filterStatus} from "../Portfolio/PortfolioPage";
import FILTER from "../../assets/icons/filter-white.svg";
import FILTER_BLACK from "../../assets/icons/filter-black.svg";
import Product from "../../components/Product/Product";
import {addToBasket} from "../../redux/actions/basketActions";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import decodeParams from "../../Helpers/decodeParams";
import {toast} from "react-toastify";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Spinner from "../../components/Spinner/Spinner";
import ValidationSchema from "../../utils/ValidationSchema";
import currency from "../../Helpers/currency";
import {Helmet} from "react-helmet";

function Shop(props) {
    const theme = useSelector(state => state.newReducer.themeChange)
    const {t} = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {width} = useWindowSize();
    const trans = useSelector(state => state.newReducer.trans)
    const {storeRequirements} = ValidationSchema();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get('page')) || 1;
    const q = searchParams.get('search') || ''
    const [search, setSearch] = useState(q || '');
    const [openFilters, setOpenFilters] = useState(false);
    const lang = Cookies.get("i18next") || "en";
    const [selectedColorsIds, setSelectedColorsIds] = useState([])
    const [showMenu, setShowMenu] = useState(false);
    const [selectedItem, setSelectedItem] = useState(trans.length > 0 ? trans.filter((e)=>e.key ==='Sort_By')[0][`value_${lang}`]: t('sort_by'));
    const [selectedProductId, setSelectedProductId] = useState('')
    const [showForm, setShowForm] = useState(false)
    const {isAuthenticated} = useSelector(state => state.auth);
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    const [showTooltip, setShowTooltip] = useState(false)
    const [loading, setLoading] = useState(false);
    const [body, setBody] = useState(Object.keys(decodeParams()).length > 0 ? decodeParams() : null);
    const categories = useSelector(state => state.newReducer.product_categories)
    const types = useSelector(state => state.newReducer.product_types)
    const colors = useSelector(state => state.newReducer.product_colors)
    const materials = useSelector(state => state.newReducer.product_materials)
    const filteredProducts = useSelector(state => state.newReducer.filteredProducts_data)
    const shopTotalPages = useSelector(state => state.newReducer.shopTotalPages)
    const shopMessage = useSelector(state => state.newReducer.shopMessage)
    const [filteredProductsSort, setFilteredProductsSort] = useState([]);
    const max_price = +useSelector(state => state.newReducer.max_price)
    const min_price = +useSelector(state => state.newReducer.min_price)
    const shopLoader = useSelector(state => state.newReducer.shopLoader)
    const [dataLoading, setDataLoading] = useState(true)
    const [localMaxPrice, setLocalMaxPrice] = useState(max_price)
    useEffect(() => {
        setLocalMaxPrice(max_price)
    }, [max_price]);
    useEffect(() => {
        setFilteredProductsSort([...filteredProducts])
    }, [filteredProducts]);
    const handleDropdownClick = () => {
        setShowMenu(!showMenu);
    };
    const handleMenuItemClick = item => {
        setSelectedItem(item);
        setShowMenu(false);
    };

    const addToCart = async values => {
        const {payload} = await dispatch(addBasket({product_id: values.id, product_count: 1, user_id: user.id, token}))
        if (payload.status === 'success') {
            toast(t('add-cart'))
        }
    };
    const updateSearchParams = (newData, key) => {
        setSearchParams((prevSearchParams) => {
            const updatedSearchParams = {...Object.fromEntries(prevSearchParams)};
            Object.keys(updatedSearchParams).forEach((param) => {
                if (param.startsWith(key)) {
                    delete updatedSearchParams[param];
                }
            });
            Object.entries(newData).forEach(([param, value]) => {
                const encodedParam = encodeURIComponent(param);
                updatedSearchParams[encodedParam] = value;
            });
            return new URLSearchParams(updatedSearchParams);
        });
    };
    const addToCartLogOut = (product) => {
        dispatch(localBasketAdd({...product, quantity: 1}))
        toast(t('add-cart'))
    }
    const handleShowForm = (id) => {
        setShowForm(true)
        setSelectedProductId(id)
    }
    const updateFilter = (key, index, id) => {
        setBody(prevData => {
            const newKey = `${key}[${index}]`;
            const newData = {...prevData};

            if (newData[newKey] !== undefined) {
                delete newData[newKey];
            } else {
                newData[newKey] = id;
            }
            updateSearchParams(newData, key);
            return newData;
        });

    };
    const handleIncrement = () => {
        const newCount = page + 1;
        setSearchParams((prevSearchParams) => {
            return new URLSearchParams({...Object.fromEntries(prevSearchParams), page: newCount});
        });
        setBody({
            ...body,
            page: newCount
        })
        updateSearchParams({
            page: newCount
        });
    };
    const handleGoSingle = (id) => {
        navigate(`/${lang}/shop/${id}`)
    }
    const handleSearch = (e) => {
        e.preventDefault()
        if (search.trim() === '') {
            setBody((prevBody) => {
                const {search, ...rest} = prevBody;
                return rest;
            });

            setSearchParams((prevSearchParams) => {
                const updatedSearchParams = {...Object.fromEntries(prevSearchParams)};
                delete updatedSearchParams.search;
                return new URLSearchParams(updatedSearchParams);
            });
        } else {
            setBody({
                ...body,
                search: search
            });

            setSearchParams((prevSearchParams) => {
                return new URLSearchParams({
                    ...Object.fromEntries(prevSearchParams),
                    search: search
                });
            });
        }
    };
    const handleChangePrice = (value) => {
        setBody({
            ...body,
            'price_range[min]': value[0],
            'price_range[max]': value[1]
        })
        updateSearchParams({
            'price_range[min]': value[0],
            'price_range[max]': value[1]
        });
    }
    useEffect(() => {
        const x = async () => {
            setDataLoading(true)
            await dispatch(getShopFilteredItems({...body, page}))
            setDataLoading(false)
        }
        x()
    }, [body, page])
    useEffect(() => {
        dispatch(getShopStandardItems(page))
    }, [page]);
    useEffect(() => {
        let newSortedProducts = [...filteredProducts];
        switch (selectedItem) {
            case trans.length > 0 ? trans.filter((e)=>e.key === 'Price_Min')[0][`value_${lang}`] : t('price_min'):
                newSortedProducts.sort((a, b) => a.price - b.price);
                break;
            case trans.length > 0 ? trans.filter((e)=>e.key === 'Price_Max')[0][`value_${lang}`] : t('price_max'):
                newSortedProducts.sort((a, b) => b.price - a.price);
                break;
            case trans.length > 0 ? trans.filter((e)=>e.key === 'alphabetically')[0][`value_${lang}`] : t('alpha'):
                newSortedProducts.sort((a, b) => a[`title_${lang}`].localeCompare(b[`title_${lang}`]));
                break;
            default:
                break;
        }
        setFilteredProductsSort(newSortedProducts);
    }, [selectedItem]);
    const handleHeart = async (product_id, f) => {
        if (isAuthenticated) {
            const {payload} = await dispatch(addToFavoritesAction({product_id, user_id: user?.id, token}))
            if (payload?.status === 'success') {
                toast(t('add_favorites'))
            }
        } else {
            dispatch(localAddFavorites({...f}))
            toast(t('add_favorites'))
        }
    }
    const filterStatus = [
        {
            id: 1,
            name: trans.length > 0 ? trans.filter((e)=>e.key === 'Price_Min')[0][`value_${lang}`] : t('price_min'),
        },
        {
            id: 2,
            name:trans.length > 0 ? trans.filter((e)=>e.key === 'Price_Max')[0][`value_${lang}`] : t('price_max'),
        },
        {
            id: 3,
            name:trans.length > 0 ? trans.filter((e)=>e.key === 'alphabetically')[0][`value_${lang}`] : t('alpha'),
        },
    ];
    useEffect(() => {
        const localIds = []
        if (body) {
            for (const [key, value] of Object.entries(body)) {
                if (key.startsWith('colors[')) {
                    localIds.push(value)
                }
            }
        }
        setSelectedColorsIds([...localIds])
    }, [body])
    console.log()
    if (!max_price) {
        return (
            <div className='spinnerContainer'>
                <Spinner/>
            </div>
        )
    }
    return (
        <motion.div initial={initial} animate={animate} className='shopContainer'>
            <>
                <Helmet>
                    <title>Shop</title>
                </Helmet>
                <PagesTitle title={trans.length>0 ? trans.filter((e)=>e.key ==='OUR_SHOP')[0][`value_${lang}`] : t("our_shop")}/>
                <div className='shopmart container'>
                    <div className='filteringSection filteringSectionDesktop'>
                        <p style={{
                            color: theme === 'ligth' ? '#000' : ''
                        }} className='filteringSectionTitle'>{trans.length>0 ? trans.filter((e)=>e.key ==='Filters')[0][`value_${lang}`] :t("filters")}</p>
                        <Filter title={trans.length>0 ? trans.filter((e)=>e.key ==='Categories')[0][`value_${lang}`] :t("categories")}>
                            {categories.map((ctg, i) => {
                                return (
                                    <div
                                        style={{width: "fit-content", cursor: "pointer"}}
                                        key={i}
                                    >
                                        <Checkbox
                                            checked={body ? body[`categories[${i}]`] : false}
                                            onChange={() => updateFilter("categories", i, ctg.id)}
                                            text={ctg[`title_${lang}`]}
                                            uniqueId={`${ctg.title_en + i}`}
                                        />
                                    </div>
                                );
                            })}
                        </Filter>
                        <div style={{
                            background: theme === 'ligth' ? '#000' : ''
                        }} className='filteringSectionDividor'></div>
                        <Filter title={trans.length>0 ? trans.filter((e)=>e.key ==='Type')[0][`value_${lang}`] : t("type")}>
                            {types.map((tp, i) => (
                                <div
                                    style={{width: "fit-content", cursor: "pointer"}}
                                    key={i}
                                >
                                    <Checkbox
                                        checked={body ? body[`types[${i}]`] : false}
                                        onChange={() => updateFilter("types", i, tp.id)}
                                        text={tp[`title_${lang}`]}
                                        uniqueId={`typeCheckbox_${tp.title_en + i}`}
                                    />
                                </div>
                            ))}
                        </Filter>
                        <div style={{
                            background: theme === 'ligth' ? '#000' : ''
                        }} className='filteringSectionDividor'></div>
                        <Filter title={trans.length>0 ? trans.filter((e)=>e.key ==='Material')[0][`value_${lang}`] :t("material")}>
                            {materials.map((mat, i) => (
                                <div
                                    key={i}
                                    style={{width: "fit-content", cursor: "pointer"}}
                                >
                                    <Checkbox
                                        checked={body ? body[`materials[${i}]`] : false}
                                        onChange={() => updateFilter("materials", i, mat.id)}
                                        text={mat[`title_${lang}`]}
                                        uniqueId={mat.title_en + i}
                                    />
                                </div>
                            ))}
                        </Filter>
                        <div style={{
                            background: theme === 'ligth' ? '#000' : ''
                        }} className='filteringSectionDividor'></div>
                        <Filter isSlider={true} showTooltip={showTooltip} setShowTooltip={setShowTooltip}
                                title={trans.length>0 ? trans.filter((e)=>e.key ==='Price')[0][`value_${lang}`]: t("price")}>
                            <Slider
                                className='slid-slider'
                                range
                                tooltip={{
                                    placement: 'bottom',
                                    open: showTooltip,
                                    formatter: (value) => `${value} ${currency}`,
                                    destroyTooltipOnHide: true
                                }}
                                step={3}
                                min={0}
                                onAfterChange={(value) => handleChangePrice(value)}
                                max={localMaxPrice}
                                defaultValue={
                                    body &&
                                    body['price_range[max]'] &&
                                    body['price_range[min]']
                                        ? [body['price_range[min]'], body['price_range[max]'],] : [0, +localMaxPrice]}
                                styles={{
                                    handle: {
                                        background: '#AF4B85',
                                    },
                                    track: {
                                        background: '#AF4B85',
                                    },
                                    tracks: {
                                        backgroundColor: '#AF4B85',
                                    }
                                }}
                            />
                        </Filter>
                        <div style={{
                            background: theme === 'ligth' ? '#000' : ''
                        }} className='filteringSectionDividor'></div>
                        <Filter title={trans.length>0 ? trans.filter((e)=>e.key ==='Colors')[0][`value_${lang}`] : t("color")} prefix='colors'>
                            {colors.map(({hex_code, id}, i) => (
                                <div className='filteringOption' key={i}>
                                    <div
                                        onClick={() => updateFilter('colors', i, id)}
                                        className='filteringOptionColor'
                                        style={{
                                            background: hex_code,
                                            cursor: "pointer",
                                            border: selectedColorsIds.includes(id) ? '1.5px solid #000' : '',
                                            boxShadow: selectedColorsIds.includes(id) ? '0 0 0 3px white' : '',
                                        }}>

                                    </div>
                                </div>
                            ))}
                        </Filter>
                        <div style={{
                            background: theme === 'ligth' ? '#000' : ''
                        }} className='filteringSectionDividor'></div>
                        <Button
                            className='seeMoreBtn'
                            // link={false}
                            onClick={() => {
                                setSelectedItem(trans.length > 0 ? trans.filter((e)=>e.key ==='Sort_By')[0][`value_${lang}`]: t('sort_by'))
                                setBody({})
                                setSearch('')
                                setSearchParams({})
                            }}
                            style={{
                                color: theme === 'ligth' ? "black" : "white",
                                marginTop: "48px",
                                width: "100%",
                                padding: '18px 0px',
                                background: '#af4b85',
                            }}
                        >
                            {trans.length > 0 ? trans.filter((e)=>e.key ==='Clear_filter')[0][`value_${lang}`]: t('clear-filter')}
                        </Button>
                    </div>
                    {dataLoading ? <div className='spinnerContainer'><Spinner size={36}/></div> : (
                        <div className='productShowcaseContainer'>
                            <div className='searchPanelContainer'>
                                <form onSubmit={(e) => handleSearch(e)} className='searchPanel'>
                                    <input
                                        style={{
                                            background: theme === 'ligth' ? '#F8F8F8' : '',
                                            color: theme === 'ligth' ? '#000' : '',
                                        }}
                                        onChange={(event) => setSearch(event.target.value)}
                                        type='text'
                                        placeholder={trans.length > 0 ? trans.filter((e)=>e.key ==='search')[0][`value_${lang}`]: t("placeholder.search")}
                                        value={search}
                                    />
                                    <div style={{cursor: "pointer"}} onClick={handleSearch}>
                                        <Img src={SEARCH} alt='Search'/>
                                    </div>
                                </form>
                                {width > 900 ? (
                                    <div
                                        style={{
                                            border: theme === 'ligth' ? '1px solid black' : ''
                                        }}
                                        className='filterDropHolder'
                                        onClick={handleDropdownClick}>
                                        <div className='filterDropdown'>
                                            <p
                                                style={{
                                                    color: theme === 'ligth' ? 'black' : ''
                                                }}
                                            >{selectedItem}</p>
                                            <svg width="12" height="6" viewBox="0 0 12 6" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.75 0.75L6 5.25L11.25 0.75"
                                                      stroke={theme === 'ligth' ? "black" : "white"} stroke-width="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <ul style={{
                                            background: theme === 'ligth' ? 'white' : "",
                                            border: theme === 'ligth' ? '1px solid black' : ""
                                        }} className={`${showMenu && "showMenu"} filterMenu`}>
                                            {filterStatus.map((st, index) => (
                                                <li
                                                    style={{
                                                        color: theme === 'ligth' ? 'black' : '',
                                                        borderBottom: theme === 'ligth' && index !== 2 ? '1px solid black' : '',
                                                    }}
                                                    key={st.id}
                                                    onClick={() => handleMenuItemClick(st.name)}>
                                                    {st.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <button
                                        style={{
                                            background: theme === 'ligth' ? 'white' : "black",
                                            border: theme === 'ligth' ? '1px solid black' : ''
                                        }}
                                        className='mobileFilterBtn'
                                        onClick={() => setOpenFilters(true)}>
                                        {theme === 'ligth' ? <img src={FILTER_BLACK} alt='Filter'/> :
                                            <img src={FILTER} alt='Filter'/>}
                                    </button>
                                )}
                            </div>
                            <div className='productShowcase'>
                                {shopMessage ?
                                    <div style={{
                                        marginTop: 50,
                                        width: "100%",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <p style={{
                                            color: '#af4b85'
                                        }} className='login-again-401'>{shopMessage}</p></div> : (<>
                                        {filteredProductsSort.map((order, i) => (
                                            <Fragment key={i}>
                                                <Product
                                                    goSingle={() => handleGoSingle(order.id)}
                                                    name={order[`title_${lang}`]}
                                                    price={`${order.price}`}
                                                    pending={order.pending}
                                                    onBtnClick={() => order.price == '0' ? handleShowForm(order.id) : (isAuthenticated ? addToCart({...order}) : addToCartLogOut(order))}
                                                    heartit={() => handleHeart(order.id, {...order})}
                                                    btnText={order.price == 0 ? t('To Order') : t("add-to-cart")}
                                                    img={`${STORAGE_URL}/${order.main_image}`}
                                                    id={order.id}
                                                />
                                            </Fragment>
                                        ))}
                                        {+page < +shopTotalPages ? (<Button
                                            className={`seeMoreBtn ${theme === 'ligth' ? 'ligth' : ''}`}
                                            // link={false}
                                            onClick={handleIncrement}
                                            style={{
                                                background: theme === 'ligth' ? '#fff' : "",
                                                marginTop: "48px",
                                                width: "100%",
                                                minHeight: '50px',
                                                padding: '16px 32px',
                                                border: theme === 'ligth' ? '1px solid #000' : '',
                                            }}
                                        >
                                            {t('See More')}
                                        </Button>) : null}
                                    </>)}
                            </div>
                        </div>
                    )}
                </div>
                <Footer/>
                {width < 900 && (
                    <Modal
                        isFilter={true}
                        title={trans.length > 0 ? trans.filter((e)=>e.key ==='Filters')[0][`value_${lang}`]: t("filters")}
                        open={openFilters}
                        onClose={() => {
                            setOpenFilters(false)
                            setShowTooltip(false)
                        }}>
                        <div className='filteringSection'>
                            <div style={{
                                border: theme === 'ligth' ? '1px solid black' : ''
                            }} className='filterDropHolder' onClick={handleDropdownClick}>
                                <div className='filterDropdown'>
                                    <p
                                        style={{
                                            color: theme === 'ligth' ? 'black' : ''
                                        }}
                                    >{selectedItem}</p>
                                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.75 0.75L6 5.25L11.25 0.75"
                                              stroke={theme === 'ligth' ? "black" : "white"} stroke-width="1.5"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <ul style={{
                                    background: theme === 'ligth' ? 'white' : "",
                                    border: theme === 'ligth' ? '1px solid black' : ""
                                }} className={`${showMenu && "showMenu"} filterMenu`}>
                                    {filterStatus.map((st, index) => (
                                        <li
                                            style={{
                                                color: theme === 'ligth' ? 'black' : '',
                                                borderBottom: theme === 'ligth' && index !== 2 ? '1px solid black' : '',
                                            }}
                                            key={st.id}
                                            onClick={() => handleMenuItemClick(st.name)}>
                                            {st.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <p className='filteringSectionTitle'>{trans.length > 0 ? trans.filter((e)=>e.key ==='Filters')[0][`value_${lang}`]: t("filters")}</p>
                            <Filter title={trans.length > 0 ? trans.filter((e)=>e.key ==='Categories')[0][`value_${lang}`]:t("categories")}>
                                {categories.map((ctg, i) => (
                                    <Checkbox
                                        key={ctg.id}
                                        uniqueId={ctg.title_en}
                                        checked={body ? body[`categories[${i}]`] : false}
                                        onChange={() => updateFilter("categories", i, ctg.id)}
                                        text={ctg[`title_${lang}`]}
                                    />
                                ))}
                            </Filter>
                            <div style={{
                                background: theme === 'ligth' ? '#000' : ''
                            }} className='filteringSectionDividor'></div>
                            <Filter title={trans.length > 0 ? trans.filter((e)=>e.key ==='Type')[0][`value_${lang}`]: t("type")}>
                                {types.map((tp, i) => (
                                    // <Link className='filteringOption' key={i}>
                                    <Checkbox
                                        key={tp.id}
                                        uniqueId={tp.title_en}
                                        checked={body ? body[`type[${i}]`] : false}
                                        onChange={() => updateFilter("type", i, tp.id)}
                                        text={tp[`title_${lang}`]}
                                    />
                                    // </Link>
                                ))}
                            </Filter>
                            <div style={{
                                background: theme === 'ligth' ? '#000' : ''
                            }} className='filteringSectionDividor'></div>
                            <Filter title={trans.length > 0 ? trans.filter((e)=>e.key ==='Material')[0][`value_${lang}`]: t("material")}>
                                {materials.map((mat, i) => (
                                    <Checkbox
                                        key={mat.id}
                                        uniqueId={mat.title_en}
                                        checked={body ? body[`material[${i}]`] : false}
                                        onChange={() => updateFilter("material", i, mat.id)}
                                        text={mat[`title_${lang}`]}
                                    />
                                ))}
                            </Filter>
                            <div style={{
                                background: theme === 'ligth' ? '#000' : ''
                            }} className='filteringSectionDividor'></div>
                            <Filter isSlider={true} showTooltip={showTooltip} setShowTooltip={setShowTooltip}
                                    title={trans.length > 0 ? trans.filter((e)=>e.key ==='Price')[0][`value_${lang}`]: t("price")}>
                                {/* <RangeSlider /> */}
                                <Slider
                                    className='slid-slider'
                                    range
                                    tooltip={{
                                        placement: 'bottom',
                                        open: showTooltip,
                                        formatter: (value) => `${value} ${currency}`,
                                        destroyTooltipOnHide: true
                                    }}
                                    step={3}
                                    min={0}
                                    onAfterChange={(value) => handleChangePrice(value)}
                                    max={+localMaxPrice || 350000}
                                    defaultValue={
                                        body &&
                                        body['price_range[max]'] &&
                                        body['price_range[min]']
                                            ? [body['price_range[min]'], body['price_range[max]'],] : [0, +localMaxPrice || 350000]}
                                    styles={{
                                        handle: {
                                            background: '#AF4B85',
                                        },
                                        track: {
                                            background: '#AF4B85',
                                        },
                                        tracks: {
                                            backgroundColor: '#AF4B85',
                                        }
                                    }}
                                />
                            </Filter>
                            <div style={{
                                background: theme === 'ligth' ? '#000' : ''
                            }} className='filteringSectionDividor'></div>
                            <Filter title={trans.length > 0 ? trans.filter((e)=>e.key ==='Colors')[0][`value_${lang}`]:t("color")}>
                                {colors.map(({hex_code, id}, i) => (
                                    <div className='filteringOption' key={i}>
                                        <div
                                            className='filteringOptionColor'
                                            onClick={() => updateFilter('colors', i, id)}
                                            style={{
                                                background: hex_code,
                                                cursor: "pointer",
                                                border: selectedColorsIds.includes(id) ? '1.5px solid #000' : '',
                                                boxShadow: selectedColorsIds.includes(id) ? '0 0 0 3px white' : '',
                                            }}>
                                        </div>
                                    </div>
                                ))}
                            </Filter>
                            <div style={{
                                background: theme === 'ligth' ? '#000' : ''
                            }} className='filteringSectionDividor'></div>
                            <Button
                                className='seeMoreBtn'
                                // link={false}
                                onClick={() => {
                                    setSelectedItem(trans.length > 0 ? trans.filter((e)=>e.key === 'Sort_By')[0][`value_${lang}`]: t('sort_by'))
                                    setBody({})
                                    setSearch('')
                                    setSearchParams({})
                                }}
                                style={{
                                    color: theme === 'ligth' ? "#fff !important" : "white",
                                    marginTop: "48px",
                                    width: "100%",
                                    padding: '18px 0px',
                                    background: '#af4b85',
                                }}
                            >
                                {trans.length > 0 ? trans.filter((e)=>e.key === 'Clear_filter')[0][`value_${lang}`]:t('clear-filter')}
                            </Button>
                        </div>
                    </Modal>
                )}
                <Modal
                    isFilter={true}
                    title={trans.length > 0 ? trans.filter((e)=>e.key === 'Create an application')[0][`value_${lang}`]: t("Create_Requirement")}
                    open={showForm}
                    onClose={() => setShowForm(false)}>
                    <Formik
                        initialValues={{
                            name: '',
                            surName: '',
                            phone: '',
                            email: ''
                        }}
                        onSubmit={async (values, actions) => {
                            setLoading(true)
                            const {payload} = await dispatch(postStoreRequirements({
                                ...values,
                                product_id: selectedProductId,
                                token
                            }))
                            if (payload.status === "success") {
                                toast.success(payload)
                            }
                            setLoading(false)
                            setShowForm(false)

                        }}
                        validationSchema={storeRequirements}>
                        {() => (
                            <Form>
                                <div className='newAddresFormGroup'>
                                    <label style={{
                                        color: theme === 'ligth' ? '#000' : ''
                                    }} htmlFor='name'>
                                        {trans.length > 0 ? trans.filter((e)=>e.key === 'Name')[0][`value_${lang}`]: t("name")}
                                    </label>
                                    <div className='newAddresFormGroupInput'>
                                        <Field
                                            style={{
                                                color: theme === 'ligth' ? '#000' : '',
                                                background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                            }}
                                            type='text'
                                            name='name'
                                            placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_name')[0][`value_${lang}`]:t("placeholder.enter_your_name")}
                                        />
                                    </div>
                                    <ErrorMessage
                                        name='name'
                                        component='p'
                                        className='newAddressError'
                                    />
                                </div>
                                <div className='newAddresFormGroup'>
                                    <label
                                        style={{
                                            color: theme === 'ligth' ? '#000' : ''
                                        }} htmlFor='surname'>
                                        {trans.length > 0 ? trans.filter((e)=>e.key === 'Surname')[0][`value_${lang}`]:t("surname")}
                                    </label>
                                    <div className='newAddresFormGroupInput'>
                                        <Field
                                            style={{
                                                color: theme === 'ligth' ? '#000' : '',
                                                background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                            }}
                                            type='text'
                                            name='surname'
                                            placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_surname')[0][`value_${lang}`]:t("placeholder.enter_your_surname")}
                                        />
                                    </div>
                                    <ErrorMessage
                                        name='surname'
                                        component='p'
                                        className='newAddressError'
                                    />
                                </div>
                                <div className='newAddresFormGroup'>
                                    <label
                                        style={{
                                            color: theme === 'ligth' ? '#000' : ''
                                        }} htmlFor='email'>
                                        {trans.length > 0 ? trans.filter((e)=>e.key === 'Email')[0][`value_${lang}`]: t("email")}
                                    </label>
                                    <div className='newAddresFormGroupInput'>
                                        <Field
                                            style={{
                                                color: theme === 'ligth' ? '#000' : '',
                                                background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                            }}
                                            type='text'
                                            name='email'
                                            placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_email_address')[0][`value_${lang}`]:t("placeholder.enter_your_email")}
                                        />
                                    </div>
                                    <ErrorMessage
                                        name='email'
                                        component='p'
                                        className='newAddressError'
                                    />
                                </div>
                                <div className='newAddresFormGroup'>
                                    <label
                                        style={{
                                            color: theme === 'ligth' ? '#000' : ''
                                        }} htmlFor='phone'>
                                        {trans.length > 0 ? trans.filter((e)=>e.key === 'Phone_Number')[0][`value_${lang}`]: t("phone_number")}
                                    </label>
                                    <div className='newAddresFormGroupInput'>
                                        <Field
                                            style={{
                                                color: theme === 'ligth' ? '#000' : '',
                                                background:theme === 'ligth' ? 'rgb(248, 248, 248)' : ''
                                            }}
                                            type='text'
                                            name='phone'
                                            placeholder={trans.length > 0 ? trans.filter((e)=>e.key === 'Enter_your_phone_number')[0][`value_${lang}`]: t("placeholder.enter_your_phone_number")}
                                        />
                                    </div>
                                    <ErrorMessage
                                        name='phone'
                                        component='p'
                                        className='newAddressError'
                                    />
                                </div>
                                <button
                                    // text={
                                    //     loading ? (
                                    //         <Spinner color='#fff' size={18}/>
                                    //     ) : (
                                    //     )
                                    // }
                                    // btnType='submit'
                                    type='submit'
                                    className='addAddressBtn'
                                    style={{
                                        background: "var(--main-color-pink)",
                                        color: "var(--secondary-color-white)",
                                        border: "none",
                                        fontFamily: "Poppins-600, sans-serif",
                                        fontWeight: "600",
                                    }}
                                >
                                    {trans.length > 0 ? trans.filter((e)=>e.key === 'Create an application')[0][`value_${lang}`]:t("Create_Requirement")}

                                </button>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </>
        </motion.div>

    );
}

export default Shop;
