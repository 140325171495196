import {Fragment, useEffect, useState} from "react";
import AccountLayout from "../AccountLayout";
import "./style.css";
import Product from "../../../components/Product/Product";
import Modal from "../../../components/Modal/Modal";
import IMG from "../../../assets/ui-fake-images/portfolio-page-1.jpg";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getOrders} from "../../../redux/actions/newActions/newActions";
import Carousel from "nuka-carousel";
import {STORAGE_URL} from "../../../services/apiService";
import currency from "../../../Helpers/currency";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import {useWindowSize} from "../../../hooks/useWindowSize";

// export const orders = [
//     {
//         id: 1,
//         title: "Bronze faucet",
//         description: "description",
//         price: "$99.00",
//         pending: "in_progress",
//         img: IMG,
//     },
//     {
//         id: 2,
//         title: "Bronze faucet",
//         description: "description",
//         price: "$99.00",
//         pending: "ordered",
//         img: IMG,
//     },
//     {
//         id: 3,
//         title: "Bronze faucet",
//         description: "description",
//         price: "$99.00",
//         pending: "in_progress",
//         img: IMG,
//     }, {
//         id: 4,
//         title: "Bronze faucet",
//         description: "description",
//         price: "$99.00",
//         pending: "in_progress",
//         img: IMG,
//     },
// ];

const Orders = () => {
    const trans = useSelector(state => state.newReducer.trans)
    const theme = useSelector(state => state.newReducer.themeChange)
    const {t} = useTranslation();
    const lang = Cookies.get("i18next") || "en";
    const [viewOrder, setViewOrder] = useState(false);
    const dispatch = useDispatch()
    const userOrders = useSelector(state => state.newReducer.userOrders)
    const [order, setOrder] = useState({})
    const [loading, setLoading] = useState(true)
    const {width} = useWindowSize()

    const handleOpenModal = (item) => {
        setOrder({...item})
        setViewOrder(true)
    }
    const navigate = useNavigate()
    useEffect(() => {
        const x = async () => {
            await dispatch(getOrders())
            setLoading(false)
        }
        x()
    }, [])
    if (loading) {
        return (
            <div className='spinnerContainer'>
                <Spinner size={36}/>
            </div>
        )
    }
    return (
        <AccountLayout>
            <div className='ordersContainer'>
                <div className='accountPageTitleContainer'>
                    <p style={{
                        color: theme === 'ligth' ? '#000' : ''
                    }} className='accountPageTitle'>{trans.length > 0 ? trans.filter((e)=>e.key==='Orders')[0][`value_${lang}`] : t("orders")}</p>
                </div>
                <div className='orders'>
                    {userOrders.map((e) => (
                        <Fragment key={e.id}>
                            {/*<Product*/}
                            {/*  name={order.title}*/}
                            {/*  price={order.price}*/}
                            {/*  pending={order.pending}*/}
                            {/*  onBtnClick={() => setViewOrder(true)}*/}
                            {/*  btnText={t("manage")}*/}
                            {/*  img={IMG}*/}
                            {/*/>*/}
                            <div style={{
                                background: theme === 'ligth' ? 'rgb(239, 239, 239)' : ''
                            }} className='single-order'>
                                <div className='single-order-images'>
                                    <Carousel
                                        renderCenterLeftControls={() => null}
                                        renderCenterRightControls={() => null}
                                    >
                                        {e.products.map((e) => (
                                            <div onClick={() => navigate(`/${lang}/shop/${e.id}`)} key={e.id}
                                                 style={{width: '100%', height: 200, cursor: "pointer"}}>
                                                <img style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: "cover",
                                                    borderTopRightRadius: 16,
                                                    borderTopLeftRadius: 16
                                                }} src={`${STORAGE_URL}${e.main_image}`} alt=""/>
                                            </div>
                                        ))}
                                    </Carousel>
                                    <div
                                        style={{backgroundColor: e.status === 'paid' ? 'rgba(39, 174, 96, 0.20)' : e.status === 'new' ? 'rgba(255, 216, 78, 0.20)' : 'rgba(255,0,0,0.2)'}}
                                        className='single-order-status-block'>
                                        <p style={{color: e.status === 'paid' ? '#27AE60' : e.status === 'new' ? '#FFD84E' : 'red'}}
                                           className='single-order-status'>{e.status}</p>
                                    </div>
                                    <div>
                                        <div className='single-order-id-block'>
                                            <p style={{color: theme === 'ligth' ? '#000' : ''}}>{t('order_id')}</p>
                                            <p style={{color: theme === 'ligth' ? '#000' : ''}}
                                               className='single-order-id-block-id'>{e.order_id}</p>
                                        </div>
                                        <div style={{marginTop: 20, marginBottom: 15}}
                                             className='single-order-id-block'>
                                            <p style={{color: theme === 'ligth' ? '#000' : ''}}>{t('Total Amount')}</p>
                                            <p style={{color: theme === 'ligth' ? '#000' : ''}}
                                               className='single-order-id-block-id'>{currency} {e.total_amount}</p>
                                        </div>
                                        <button onClick={() => handleOpenModal(e)} style={{marginLeft: 10}}
                                                className="my_custom_button">
                                            {t('See_all')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>
            <Modal
                open={viewOrder}
                onClose={() => setViewOrder(false)}>
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{padding: width > 510 ? '0 40px 0 0' : '',color: theme === 'ligth' ? '#000' : ''}}
                           className="table-price">{t('order_id')}</p>
                        <p style={{color: theme === 'ligth' ? '#000' : ''}} className="table-price">{order?.order_id}</p>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{padding: width > 510 ? '0 40px 0 0' : '',color: theme === 'ligth' ? '#000' : ''}}
                           className="table-price">{t('Total Amount')}</p>
                        <p style={{color: theme === 'ligth' ? '#000' : ''}} className="table-price">{order?.total_amount} {currency}</p>
                    </div>
                    <div style={{marginTop: 30}}>
                        {order.products?.map((e) => (
                            <div onClick={() => navigate(`/${lang}/shop/${e.id}`)} style={{
                                marginTop: 20,
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: "center",
                                borderBottom: '1px solid #ccc',
                                paddingBottom: 4
                            }}>
                                <img style={{
                                    width: 80,
                                    height: 80
                                }} src={`${STORAGE_URL}${e.main_image}`} alt={e[`title_${lang}`]}/>
                                <p style={{color: theme === 'ligth' ? '#000' : ''}} className="table-price">{e[`title_${lang}`]}</p>
                                <p style={{color: theme === 'ligth' ? '#000' : ''}} className="table-price">{e.price} {currency} </p>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
        </AccountLayout>
    );
};

export default Orders;
