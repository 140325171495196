import React, {useEffect} from 'react';

function Logout(props) {
    useEffect(() => {
        localStorage.clear()
        window.location.replace('/')
    }, []);
    return (
        <div></div>
    );
}

export default Logout;
